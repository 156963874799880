import React, {useCallback, useState} from 'react';
import * as XLSX from 'xlsx';

import {excelColumns} from './excelColumns';

function flattenObject(obj, parentKey = '', res = {}) {
  if (obj === null || typeof obj !== 'object') {
    res[parentKey] = obj;
    return res;
  }

  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      const newKey = parentKey ? `${parentKey}[${index}]` : `[${index}]`;
      flattenObject(item, newKey, res);
    });
    return res;
  }

  for (const key in obj) {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    const value = obj[key];
    if (value && typeof value === 'object') {
      flattenObject(value, newKey, res);
    } else {
      res[newKey] = value;
    }
  }
  return res;
}

const ExcelExport = ({fetchData, fileName = 'report', sheetName = 'Sheet1', order = [], disabled = false}) => {
  const [loading, setLoading] = useState(false);

  const handleExport = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchData();
      if (!Array.isArray(data) || data.length === 0) {
        return;
      }

      // 1. «Выравниваем» объекты
      const flattenedData = data.map((item) => flattenObject(item));

      // 2. Собираем все возможные ключи (названия колонок)
      const allKeysSet = new Set();
      flattenedData.forEach((item) => {
        Object.keys(item).forEach((key) => allKeysSet.add(key));
      });
      const unorderedKeys = Array.from(allKeysSet);
      const allKeys = [...order, ...unorderedKeys.filter((key) => !order.includes(key))];

      // 3. Фильтруем: удаляем те ключи, где все значения пустые
      //    - "Пустыми" считаем null, undefined, пустую строку.
      const filteredKeys = allKeys.filter((key) => {
        if (key === 'avatar') {
          return false;
        }

        return flattenedData.some((item) => {
          const value = item[key];
          return value !== undefined && value !== null && value !== '';
        });
      });

      // 4. Формируем структуру (AOA – Array of Arrays) для записи
      //    Добавляем заголовки (строка с именами ключей)
      const aoa = [];
      aoa.push(filteredKeys);

      // 5. Заполняем каждую строку
      flattenedData.forEach((item) => {
        const row = filteredKeys.map((key) => {
          // Если значение "пустое", подставляем '-'
          const value = item[key];
          return value === undefined || value === null || value === '' ? '-' : value;
        });
        aoa.push(row);
      });

      const params = new URLSearchParams(window.location.search);
      aoa[0] = aoa[0].map((item) => {
        if (item === 'name') {
          return excelColumns?.[params?.get('group')?.split(',')?.at(-1)]?.label || item;
        }
        if (item === 'id') {
          const colKey = [params?.get('group')?.split(',')?.at(-1)] 
          return excelColumns?.[`${colKey}_id`]?.label || item;
        }

        return excelColumns?.[item.split('.').at(-1)]?.label || item;
      });
      // 6. Создаём лист и книгу Excel
      const ws = XLSX.utils.aoa_to_sheet(aoa);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      // 7. Сохраняем файл
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } catch (error) {
      console.error('Ошибка при экспорте в Excel:', error);
    } finally {
      setLoading(false);
    }
  }, [fetchData, fileName, sheetName]);

  return (
    <button
      className='btn btn-sm shadow-xs d-flex align-items-center me-4 btn-active-light-success'
      onClick={handleExport}
      disabled={disabled || loading}
    >
      {!loading && (
        <i className='ki-duotone ki-file-down fs-1 pe-0'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      )}
      {!!loading && <span className='exelLoader'></span>}
    </button>
  );
};

export default ExcelExport;
