export const custom = {
  clicks: {label: 'Клики', marker: '#007AFF', type: 'link'},
  leads: {label: 'Лиды', marker: '#5856D6', type: 'link', rout: '/leads/'},
  chats: {label: 'Чаты', marker: '#FF2DF8', type: 'link'},
  registrations: {label: 'Регистрации', marker: '#AF52DE', type: 'link'},
  first_deposits: {label: 'Первые Депозиты', marker: '#00C7BE', type: 'link'},
  qualifications: {label: 'Квалификации', marker: '#9BC734', type: 'link'},
  count_clicks: {
    label: 'Кол-во',
    color: '#007AFF',
    subColor: 'rgba(0, 122, 255, 5%)',
    fontWeight: 'bold',
    type: 'count',
  },
  click2lead: {label: 'Click2Lead', type: 'conversion'},
  click2appeal: {label: 'Click2Chat', type: 'conversion', clue: 'Конверсия из клика в чат'},
  click2reg: {label: 'Click2Reg', type: 'conversion', clue: 'Конверсия из клика в регистрацию'},
  click2fd: {label: 'Click2Fd', type: 'conversion', clue: 'Конверсия из клика в первый депозит'},
  click2cpa: {label: 'Click2Cpa', type: 'conversion', clue: 'Конверсия из клика в квалификацию'},
  cpc: {label: 'CPC', fontWeight: 'bold', type: 'cash', clue: 'Стоимость за клик'},
  count_leads: {
    label: 'Кол-во',
    color: '#5856D6',
    subColor: 'rgba(88, 86, 214, 5%)',
    fontWeight: 'bold',
    type: 'count',
  },
  lead2appeal: {label: 'Lead2Chat', type: 'conversion', clue: 'Конверсия лидов в чат'},
  lead2reg: {label: 'Lead2Reg', type: 'conversion', clue: 'Конверсия лидов в регистрацию'},
  lead2fd: {label: 'Lead2Fd', type: 'conversion', clue: 'Конверсия лидов в первый депозит'},
  lead2cpa: {label: 'Lead2Cpa', type: 'conversion', clue: ' Конверсия лидов в квалификацию'},
  cpl: {label: 'CPL', fontWeight: 'bold', type: 'cash', clue: 'Стоимость за лида'},
  count_appeals: {
    label: 'Кол-во',
    color: '#FF2DF8',
    subColor: 'rgba(255, 45, 248, 5%)',
    fontWeight: 'bold',
    type: 'count',
  },
  chat2reg: {label: 'Chat2Reg', type: 'conversion'},
  chat2fd: {label: 'Chat2Fd', type: 'conversion'},
  chat2cpa: {label: 'Chat2Cpa', type: 'conversion'},
  cpm: {label: 'CPM', fontWeight: 'bold', type: 'cash'},
  count_registrations: {
    label: 'Кол-во',
    color: '#AF52DE',
    subColor: 'rgba(175, 82, 222, 5%)',
    fontWeight: 'bold',
    type: 'count',
  },
  reg2fd: {label: 'Reg2Fd', type: 'conversion', clue: 'Конверсия регистраций в первый депозит'},
  reg2cpa: {label: 'Reg2Cpa', type: 'conversion', clue: 'Конверсия регистраций в квалификации'},
  cpr: {label: 'CPR', fontWeight: 'bold', type: 'cash', clue: 'Стоимость за регистрацию'},
  count_first_deposits: {
    label: 'Кол-во',
    color: '#00C7BE',
    subColor: 'rgba(0, 199, 190, 5%)',
    fontWeight: 'bold',
    type: 'count',
  },
  fd2cpa: {label: 'Fd2Cpa', type: 'conversion', clue: 'Конверсия первых депозитов в квалификации'},
  cpf: {label: 'CPFD', fontWeight: 'bold', type: 'cash', clue: 'Стоимость за первый депозит'},
  count_qualifications: {
    label: 'Кол-во',
    color: '#9BC734',
    subColor: 'rgba(155, 190, 52, 5%)',
    fontWeight: 'bold',
    type: 'count',
  },
  cpa: {label: 'CPA', fontWeight: 'bold', type: 'cash', clue: 'Стоимость за квалификацию'},
  revenue: {label: 'Доход', type: 'cash'},
  cost: {label: 'Расходы', type: 'cash'},
  profit: {label: 'Прибыль', type: 'cash', fontWeight: 'bold'},
  romi: {label: 'ROMI', type: 'conversion', colorForMore: '#34C759', colorForLess: '#FF3B30'},
  offer: {label: 'Оффер', type: 'link', rout: '/advertisers/offers/'},
  day: {label: 'День', type: 'link'},
  month: {label: 'Месяц', type: 'link'},
  year: {label: 'Год', type: 'link'},
  advertiser: {label: 'Рекламодатель', type: 'link', rout: '/advertisers/list/'},
  country: {label: 'Страна', type: 'link'},
  pwa: {label: 'PWA', type: 'link', rout: '/resources/pwa/'},
  traffic: {label: 'Кампания', type: 'link', rout: '/traffics/facebook/companies/'},
  bayer: {label: 'Байер', type: 'link', rout: '/traffics/facebook/bayers/'},
  source: {label: 'Сорс', type: 'link', rout: '/traffics/facebook/source/'},
  finance: {label: 'Финансы', marker: '#34C77E', type: 'link', rout: '/traffics/facebook/source/'},
  pixel: {label: 'Пиксели', type: 'link'},
  landing: {label: 'Лендинг', type: 'link'},

  ///
  traffics_id: {label: 'Кампания', type: 'link', rout: '/traffics/facebook/companies/'},
  campaign: {label: 'FB Campaigns', type: 'link'},
  adset: {label: 'FB Adsets', type: 'link'},
  ad: {label: 'FB Ads', type: 'link'},
};

export const emptyDataColumns = {
  id: 1,
  name: '1win',
  clicks: {
    count_clicks: 0,
    click2lead: 0,
    click2appeal: 0,
    click2reg: 0,
    click2fd: 0,
    click2cpa: 0,
    cpc: 0,
  },
  leads: {
    count_leads: 3291,
    lead2appeal: 17.9885,
    lead2reg: 49.7721,
    lead2fd: 14.7068,
    lead2cpa: 11.577,
    cpl: 1.2649316924947864,
  },
  chats: {
    count_appeals: 592,
    chat2reg: 276.6892,
    chat2fd: 81.7568,
    chat2cpa: 64.3581,
    cpm: 7.031909121622199,
  },
  registrations: {
    count_registrations: 1638,
    reg2fd: 29.5482,
    reg2cpa: 23.2601,
    cpr: 2.5414470085472174,
  },
  first_deposits: {
    count_first_deposits: 484,
    fd2cpa: 78.719,
    cpf: 8.601012809918062,
  },
  qualifications: {
    count_qualifications: 381,
    cpa: 10.926220997376225,
  },
  finance: {
    revenue: 11959,
    cost: 4162.890200000342,
    profit: 7796.109799999658,
    romi: 187.2763735156651,
  },
};

export const customizationColumns = (item) => {
  if ('column' in item) {
    return {...item, column: {...item.column, ...custom[item.column.name]}};
  } else {
    return {...item, ...custom[item.name]};
  }
};
