import React, {useEffect, useState} from 'react';
import FilterWithSelectedOptions from '../shared/filters/FilterWithSelectedOptions';

const Filter = ({
  filters,
  item,
  index,
  mode,
  createUrl,
  setSelectedOptions,
  selectedOptions,
  selectedGroup,
  deleteOption,
  isSearch = false,
}) => {
  const [currentFilters, setCurrentFilters] = useState([]);

  useEffect(() => {
    if (isSearch) return;

    setCurrentFilters(
      filters[item.value]?.map((op) => ({
        value: op.id,
        label: op?.name
          ? typeof op.id === 'number'
            ? `#${op?.id} ${op?.name}`
            : op?.name
          : `#${op?.id} ${op?.first_name} ${op?.last_name}`,
      }))
    );
  }, []);

  const onChange = (e) => {
    const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    newSelectedOptions[index].options = e;
    setSelectedOptions(newSelectedOptions);
  };

  const onChangeValue = (value) => {
    if (!isSearch) return;

    if (value.trim()) {
      setCurrentFilters([{value: value, label: `Добавить ${value}`}]);
    } else {
      setCurrentFilters([]);
    }
  };

  const apply = () => {
    createUrl(selectedOptions, selectedGroup);
  };

  return (
    <>
      <FilterWithSelectedOptions
        item={item}
        index={index}
        options={currentFilters}
        selectedOptions={selectedOptions[index].options || []}
        onChange={onChange}
        apply={apply}
        onChangeValue={onChangeValue}
        del={deleteOption}
      />
    </>
  );
};

export default Filter;
