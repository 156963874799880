export const excelColumns = {
    clicks: {label: 'Клики', marker: '#007AFF', type: 'link'},
    leads: {label: 'Лиды', marker: '#5856D6', type: 'link', rout: '/leads/'},
    chats: {label: 'Чаты', marker: '#FF2DF8', type: 'link'},
    registrations: {label: 'Регистрации', marker: '#AF52DE', type: 'link'},
    first_deposits: {label: 'Первые Депозиты', marker: '#00C7BE', type: 'link'},
    qualifications: {label: 'Квалификации', marker: '#9BC734', type: 'link'},
    count_clicks: {
      label: 'Клики Кол-во',
      color: '#007AFF',
      subColor: 'rgba(0, 122, 255, 5%)',
      fontWeight: 'bold',
      type: 'count',
    },
    click2lead: {label: 'Click2Lead', type: 'conversion'},
    click2appeal: {label: 'Click2Chat', type: 'conversion', clue: 'Конверсия из клика в чат'},
    click2reg: {label: 'Click2Reg', type: 'conversion', clue: 'Конверсия из клика в регистрацию'},
    click2fd: {label: 'Click2Fd', type: 'conversion', clue: 'Конверсия из клика в первый депозит'},
    click2cpa: {label: 'Click2Cpa', type: 'conversion', clue: 'Конверсия из клика в квалификацию'},
    cpc: {label: 'CPC', fontWeight: 'bold', type: 'cash', clue: 'Стоимость за клик'},
    count_leads: {
      label: 'Лиды Кол-во',
      color: '#5856D6',
      subColor: 'rgba(88, 86, 214, 5%)',
      fontWeight: 'bold',
      type: 'count',
    },
    lead2appeal: {label: 'Lead2Chat', type: 'conversion', clue: 'Конверсия лидов в чат'},
    lead2reg: {label: 'Lead2Reg', type: 'conversion', clue: 'Конверсия лидов в регистрацию'},
    lead2fd: {label: 'Lead2Fd', type: 'conversion', clue: 'Конверсия лидов в первый депозит'},
    lead2cpa: {label: 'Lead2Cpa', type: 'conversion', clue: ' Конверсия лидов в квалификацию'},
    cpl: {label: 'CPL', fontWeight: 'bold', type: 'cash', clue: 'Стоимость за лида'},
    count_appeals: {
      label: 'Чаты Кол-во',
      color: '#FF2DF8',
      subColor: 'rgba(255, 45, 248, 5%)',
      fontWeight: 'bold',
      type: 'count',
    },
    chat2reg: {label: 'Chat2Reg', type: 'conversion'},
    chat2fd: {label: 'Chat2Fd', type: 'conversion'},
    chat2cpa: {label: 'Chat2Cpa', type: 'conversion'},
    cpm: {label: 'CPM', fontWeight: 'bold', type: 'cash'},
    count_registrations: {
      label: 'Регистрации Кол-во',
      color: '#AF52DE',
      subColor: 'rgba(175, 82, 222, 5%)',
      fontWeight: 'bold',
      type: 'count',
    },
    reg2fd: {label: 'Reg2Fd', type: 'conversion', clue: 'Конверсия регистраций в первый депозит'},
    reg2cpa: {label: 'Reg2Cpa', type: 'conversion', clue: 'Конверсия регистраций в квалификации'},
    cpr: {label: 'CPR', fontWeight: 'bold', type: 'cash', clue: 'Стоимость за регистрацию'},
    count_first_deposits: {
      label: 'Первые Депозиты Кол-во',
      color: '#00C7BE',
      subColor: 'rgba(0, 199, 190, 5%)',
      fontWeight: 'bold',
      type: 'count',
    },
    fd2cpa: {label: 'Fd2Cpa', type: 'conversion', clue: 'Конверсия первых депозитов в квалификации'},
    cpf: {label: 'CPFD', fontWeight: 'bold', type: 'cash', clue: 'Стоимость за первый депозит'},
    count_qualifications: {
      label: 'Квалификации Кол-во',
      color: '#9BC734',
      subColor: 'rgba(155, 190, 52, 5%)',
      fontWeight: 'bold',
      type: 'count',
    },
    cpa: {label: 'CPA', fontWeight: 'bold', type: 'cash', clue: 'Стоимость за квалификацию'},
    revenue: {label: 'Доход', type: 'cash'},
    cost: {label: 'Расходы', type: 'cash'},
    profit: {label: 'Прибыль', type: 'cash', fontWeight: 'bold'},
    romi: {label: 'ROMI', type: 'conversion', colorForMore: '#34C759', colorForLess: '#FF3B30'},
    offer: {label: 'Оффер', type: 'link', rout: '/advertisers/offers/'},
    offer_name: {label: 'Оффер', type: 'link', rout: '/advertisers/offers/'},
    offer_id: {label: 'Оффер ID', type: 'link', rout: '/advertisers/offers/'},
    day: {label: 'День', type: 'link'},
    month: {label: 'Месяц', type: 'link'},
    year: {label: 'Год', type: 'link'},
    advertiser: {label: 'Рекламодатель', type: 'link', rout: '/advertisers/list/'},
    advertiser_name: {label: 'Рекламодатель', type: 'link', rout: '/advertisers/list/'},
    advertiser_id: {label: 'Рекламодатель ID', type: 'link', rout: '/advertisers/list/'},
    country: {label: 'Страна', type: 'link'},
    country_name: {label: 'Страна', type: 'link'},
    country_id: {label: 'Страна ID', type: 'link'},
    pwa: {label: 'PWA', type: 'link', rout: '/resources/pwa/'},
    pwa_name: {label: 'PWA', type: 'link', rout: '/resources/pwa/'},
    pwa_id: {label: 'PWA ID', type: 'link', rout: '/resources/pwa/'},
    traffic: {label: 'Кампания', type: 'link', rout: '/traffics/facebook/companies/'},
    traffic_name: {label: 'Кампания', type: 'link', rout: '/traffics/facebook/companies/'},
    traffic_id: {label: 'Кампания ID', type: 'link', rout: '/traffics/facebook/companies/'},
    bayer: {label: 'Байер', type: 'link', rout: '/traffics/facebook/bayers/'},
    bayer_name: {label: 'Байер', type: 'link', rout: '/traffics/facebook/bayers/'},
    bayer_id: {label: 'Байер ID', type: 'link', rout: '/traffics/facebook/bayers/'},
    source: {label: 'Сорс', type: 'link', rout: '/traffics/facebook/source/'},
    source_name: {label: 'Сорс', type: 'link', rout: '/traffics/facebook/source/'},
    source_id: {label: 'Сорс ID', type: 'link', rout: '/traffics/facebook/source/'},
    finance: {label: 'Финансы', marker: '#34C77E', type: 'link', rout: '/traffics/facebook/source/'},
    finance_name: {label: 'Финансы', marker: '#34C77E', type: 'link', rout: '/traffics/facebook/source/'},
    finance_id: {label: 'Финансы ID', marker: '#34C77E', type: 'link', rout: '/traffics/facebook/source/'},
    pixel: {label: 'Пиксели', type: 'link'},
    pixel_name: {label: 'Пиксели', type: 'link'},
    pixel_id: {label: 'Пиксели ID', type: 'link'},
    landing: {label: 'Лендинг', type: 'link'},
    landing_name: {label: 'Лендинг', type: 'link'},
    landing_id: {label: 'Лендинг ID', type: 'link'},
  
    ///
    traffics_id: {label: 'Кампания', type: 'link', rout: '/traffics/facebook/companies/'},
    campaign: {label: 'FB Campaigns', type: 'link'},
    adset: {label: 'FB Adsets', type: 'link'},
    ad: {label: 'FB Ads', type: 'link'},
  };