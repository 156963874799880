import React, {useEffect, useState} from 'react';
import css from '../../../../../../../styles/Treker.module.scss';
import {useThemeMode} from '../../../../../../../_metronic/partials';
import clsx from 'clsx';

export const TrekerButton = ({
  children,
  onClick = () => {},
  svg = (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.4'
        d='M10.125 3.075V14.925C10.125 16.05 10.605 16.5 11.7975 16.5H14.8275C16.02 16.5 16.5 16.05 16.5 14.925V3.075C16.5 1.95 16.02 1.5 14.8275 1.5H11.7975C10.605 1.5 10.125 1.95 10.125 3.075Z'
      />
      <path d='M1.5 3.075V14.925C1.5 16.05 1.98 16.5 3.1725 16.5H6.2025C7.395 16.5 7.875 16.05 7.875 14.925V3.075C7.875 1.95 7.395 1.5 6.2025 1.5H3.1725C1.98 1.5 1.5 1.95 1.5 3.075Z' />
    </svg>
  ),
  style = {},
}) => {
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = useState('');

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  return (
    <div
      className={css.actions}
      style={{
        '--bg': themeMode === 'dark' ? '#15171C' : 'rgba(255, 255, 255, 1)',
        '--tabActive': themeMode === 'dark' ? '#15171C' : 'rgba(255, 255, 255, 1)',
        '--tab': themeMode === 'dark' ? '#15171C' : 'rgba(245, 246, 247, 1)',
        '--svgFillActive': themeMode === 'dark' ? '#1B84FF' : '#1B84FF',
        '--svgFill': themeMode === 'dark' ? 'rgba(255, 255, 255, 1)' : '#292D32',
        '--text': themeMode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(7, 20, 55, 1)',
        '--text2': themeMode === 'dark' ? 'rgba(113, 113, 121, 1)' : 'rgba(120, 130, 157, 1)',
        '--textActive': themeMode === 'dark' ? '#1B84FF' : '#1B84FF',
        '--bgButtons': themeMode === 'dark' ? 'rgba(15, 16, 21, 1)' : 'rgba(245, 246, 247, 1)',
        '--buttonHover': themeMode === 'dark' ? 'rgba(258, 258, 258, 0.05)' : 'rgba(0, 0, 0, 0.05)',
        '--borderTable': themeMode === 'dark' ? 'rgba(30, 31, 36, 1)' : 'rgba(211, 211, 211, 1)',
        '--bgRowTable': themeMode === 'dark' ? 'rgba(27, 29, 35, 1)' : 'rgba(245, 246, 247, 1)',
        '--bgRowTableHover':
          themeMode === 'dark' ? 'rgba(38, 41, 48, 1)' : 'rgba(229, 229, 229, 1)',
        '--bgRowTableActive':
          themeMode === 'dark' ? 'rgba(44, 49, 73, 1)' : 'rgba(212, 230, 253, 1)',
        padding: 0,
        borderRadius: 12,
      }}
    >
      <button className={clsx(css.button, css.button_withHowever)} onClick={onClick} style={{...style}}>
        {svg}
        <span>{children}</span>
        <span className={css.hoverEffect}></span>
      </button>
    </div>
  );
};
