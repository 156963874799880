/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react';
import css from '../../../../../styles/FacebookStatistics.module.scss';
import classNames from 'classnames';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';
import {useThemeMode} from '../../../../../_metronic/partials';
import Navigation from '../../../../modules/NavigationBloggers';
import Filter from '../../../../table/Filter';
import FilterOfFilters from '../../../../table/FilterOfFilters';
import {TrekerButton} from '../../influence/bloggers/source/Treker/TrekerButton';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';

const filtersGroup = [
  {
    label: 'Популярные',
    options: [
      {value: 'advertiser_id', label: 'Рекламодатель'},
      {value: 'offer_id', label: 'Оффер'},
      {value: 'app_id', label: 'PWA'},
      {value: 'bayer_id', label: 'Байер'},
      {value: 'state', label: 'Состояние'},
    ],
  },
  {
    label: 'Рекламодатель',
    options: [
      {value: 'advertiser_id', label: 'Рекламодатель'},
      {value: 'offer_id', label: 'Оффер'},
      {value: 'landing_id', label: 'Лендинг'},
    ],
  },
  {
    label: 'Фейсбук',
    options: [
      {value: 'app_id', label: 'PWA'},
      {value: 'bayer_id', label: 'Байер'},
      {value: 'traffic_id', label: 'Блогер'},
      {value: 'link_id', label: 'Сорсы'},
    ],
  },
  {
    label: 'Состояние',
    options: [{value: 'state', label: 'Состояние'}],
  },
];
const groupsObj = [
  {
    label: 'Популярные',
    options: [
      {value: 'advertiser', label: 'Рекламодатель'},
      {value: 'offer', label: 'Оффер'},
      {value: 'pwa', label: 'PWA'},
      {value: 'bayer', label: 'Байер'},
    ],
  },
  {
    label: 'Рекламодатель',
    options: [
      {value: 'advertiser', label: 'Рекламодатель'},
      {value: 'offer', label: 'Оффер'},
      {value: 'landing', label: 'Лендинг'},
    ],
  },
  {
    label: 'Фейсбук',
    options: [
      {value: 'pwa', label: 'PWA'},
      {value: 'bayer', label: 'Байер'},
      {value: 'traffic', label: 'Блогер'},
      {value: 'source', label: 'Сорс'},
    ],
  },
  {
    label: 'Дата',
    options: [
      {value: 'day', label: 'День'},
      {value: 'month', label: 'Месяц'},
      {value: 'year', label: 'Год'},
    ],
  },
];

const Filters = observer(({setThemeMode, actions, isVerify, getTable, search, resultsCount}) => {
  const store = useStore();
  const theme = useThemeMode();
  const navigate = useNavigate();

  const [mode, setMode] = useState('');

  const [group, setGroup] = useState('');
  const [filters, setFilters] = useState({});

  const [filtersGroupOptions, setFiltersGroupOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [searchableParams, setSearchableParams] = useState([]);

  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);

  const [firstRender, setFirstRender] = useState(false);

  const [timeInfo, setTimeInfo] = useState('');

  useEffect(() => {
    if (!firstRender && !!search && !!isVerify) {
      getFilters();
      setFirstRender(true);
    }
  }, [isVerify, search]);

  const uniqueOptions = (obj) => {
    const res = Array.from(
      obj
        .flatMap((group) => group.options)
        .reduce((map, option) => map.set(option.value, option), new Map())
        .values()
    );
    return res;
  };

  const getFilters = async () => {
    const [
      getAdvertisers,
      getOffers,
      getLandings,
      getPwa,
      getBayers,
      getCompanies,
      getSource,
    ] = await Promise.all([
      store.getAdvertisersList(),
      store.offersGetOffersList(),
      store.offersGetLandingsList(),
      store.resourcePWAgetApps({
        time_config: 'current_month',
        time_start: '',
        time_end: '',
      }),
      store.trafficsFacebookGetBayersList(),
      store.trafficsFacebookGetCompaniesList(),
      store.trafficsInfluenceGetLinksList(),
    ]);

    const filtersObj = {
      advertiser_id: getAdvertisers,
      offer_id: getOffers,
      landing_id: getLandings,
      app_id: getPwa,
      bayer_id: getBayers,
      traffic_id: getCompanies,
      link_id: getSource,
      state: [
        {name: 'С Лидами', id: 'leads'},
        {name: 'Без Лидов', id: 'without_leads'},
        {name: 'С Регистрациями', id: 'reg'},
        {name: 'Без Регистраций', id: 'without_reg'},
        {name: 'С Квалификациями', id: 'cpa'},
        {name: 'Без Квалификаций', id: 'without_cpa'},
        {name: 'Активно', id: 'active'},
        {name: 'Не активно', id: 'inactive'},
      ],
    };

    setFilters(filtersObj);
    parseUrlWithFilters(search, filtersGroup, groupsObj, filtersObj);
  };

  const updateQueryString = (str, key, value) => {
    const regex = new RegExp(`[?&]${key}=([^&]*)`);
    const match = str.match(regex);

    if (match) {
      if (match[1] === value) {
        return str;
      }
      return str.replace(regex, `${match[0][0]}${key}=${value}`);
    }
    return `${str}${str.includes('?') ? '&' : '?'}${key}=${value}`;
  };

  const createUrlWithoutReq = (options, groups = []) => {
    setSearchableParams(options);

    const params = new URLSearchParams(search);

    params.set('group', groups.map((item) => item.value).join(','));

    options?.forEach(({value, options: opts}) => {
      params.set(value, !!opts?.length ? opts.map((item) => item.value).join(',') : []);
    });

    Array.from(params.keys()).forEach((key) => {
      const existsInOptions = options.some(({value}) => value === key);
      if (!existsInOptions && key !== 'group') {
        params.delete(key);
      }
    });

    const url = decodeURIComponent(params.toString());
    const parsedData = {};

    const pairs = url.split('&');

    pairs.forEach((pair) => {
      const [key, value] = pair.split('=');

      parsedData[key] = value || '';
    });

    let newString = localStorage.getItem('facebookInfluenceQuery');

    for (const property in parsedData) {
      newString = updateQueryString(newString, property, parsedData[property]);
    }
    localStorage.setItem('facebookInfluenceQuery', newString);
    navigate(newString);
  };

  const addSelectedOption = (e) => {
    const newSelectedOptions = [...selectedOptions, e];
    setSelectedOptions(newSelectedOptions);
    createUrlWithoutReq(newSelectedOptions, selectedGroup);
    const obj = filtersGroupOptions.map((item) => {
      const newItem = {...item};
      const newOptions = item.options.filter((op) => op.value !== e.value);
      newItem.options = newOptions;
      return newItem;
    });
    setFiltersGroupOptions(obj);
  };

  const delFilter = (item, index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions.splice(index, 1);
    setSelectedOptions(newSelectedOptions);
    setSearchableParams(newSelectedOptions);
    createUrl(newSelectedOptions, selectedGroup);

    if (newSelectedOptions.length === 0) return setFiltersGroupOptions(filtersGroup);
    const newFiltersGroup = [...filtersGroup].map((it) => {
      const newOptions = it.options.filter(
        (op) => !newSelectedOptions.some((el) => el.value === op.value)
      );
      return {...it, options: newOptions};
    });

    setFiltersGroupOptions(newFiltersGroup);
  };

  const deleteOption = (indexS, indexO) => {
    const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    newSelectedOptions[indexS].options.splice(indexO, 1);
    setSelectedOptions(newSelectedOptions);
    // setSearchableParams(newSelectedOptions);
  };

  const deleteSavedOption = (indexS, indexO) => {
    const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    newSelectedOptions[indexS].options.splice(indexO, 1);
    setSelectedOptions(newSelectedOptions);
    setSearchableParams(newSelectedOptions);
    createUrl(newSelectedOptions, selectedGroup);
  };

  const deleteOptions = (indexS) => {
    const newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
    newSelectedOptions[indexS].options = [];
    setSelectedOptions(newSelectedOptions);
    setSearchableParams(newSelectedOptions);
    createUrl(newSelectedOptions, selectedGroup);
  };

  const addSelectedGroup = (e) => {
    const newSelectedGroup = [...selectedGroup, e];
    setSelectedGroup(newSelectedGroup);

    const obj = groupOptions.map((item) => {
      const newItem = {...item};
      const newOptions = item.options.filter((op) => op.value !== e.value);
      newItem.options = newOptions;
      return newItem;
    });
    createUrl(selectedOptions, newSelectedGroup);
    setGroupOptions(obj);
    setGroup(newSelectedGroup[0]?.value);
  };

  const addGroup = (e) => {
    if (!e.link) {
      setSelectedGroup([]);
      createUrl(selectedOptions);
      return setGroupOptions(groupsObj);
    }
    setSelectedGroup([{value: e.link, label: e.title}]);
    createUrl(selectedOptions, [{value: e.link, label: e.title}]);

    const newGroup = [...groupsObj].map((it) => {
      const newOptions = it.options.filter((op) => op.value !== e.link);
      return {...it, options: newOptions};
    });

    setGroupOptions(newGroup);
  };

  const deleteGroup = (index) => {
    const newSelectedGroup = [...selectedGroup];
    newSelectedGroup.splice(index, 1);
    setSelectedGroup(newSelectedGroup);
    createUrl(selectedOptions, newSelectedGroup);

    if (newSelectedGroup.length === 0) {
      setGroup('');
      return setGroupOptions(groupsObj);
    }
    const newGroup = [...groupsObj].map((it) => {
      const newOptions = it.options.filter(
        (op) => !newSelectedGroup.some((el) => el.value === op.value)
      );
      return {...it, options: newOptions};
    });

    setGroupOptions(newGroup);
  };

  const createUrl = (options, groups = []) => {
    setSearchableParams(options);

    const params = new URLSearchParams(search);

    params.set('group', groups.map((item) => item.value).join(','));

    options?.forEach(({value, options: opts}) => {
      params.set(value, !!opts?.length ? opts.map((item) => item.value).join(',') : []);
    });

    Array.from(params.keys()).forEach((key) => {
      const existsInOptions = options.some(({value}) => value === key);
      if (!existsInOptions && key !== 'group') {
        params.delete(key);
      }
    });

    const url = decodeURIComponent(params.toString());
    getTable(`?${url}`, 'changeFilters');
    getNewTime();
  };

  const parseUrlWithFilters = (string, obj1, obj2, filtersObj) => {
    const params = new URLSearchParams(string);

    const groupParam = params.get('group');
    const groupValues = groupParam ? groupParam.split(',') : [];

    const newObj2 = obj2.map((item) => {
      const newOptions = item?.options?.filter(
        (opt) => !groupValues.some((el) => el === opt.value)
      );
      return {...item, options: newOptions};
    });

    const selected = uniqueOptions(obj2)
      .filter((item) => groupValues.includes(item.value))
      .sort((a, b) => groupValues.indexOf(a.value) - groupValues.indexOf(b.value));

    setGroupOptions(newObj2);
    setSelectedGroup(selected);
    setGroup(groupValues[0] || '');

    //Filters/////////////////////////////////////
    const allParams = {};
    params.forEach((value, key) => {
      if (
        key !== 'group' &&
        key !== 'time_config' &&
        key !== 'time_start' &&
        key !== 'time_end' &&
        key !== 'sort' &&
        key !== 'page' &&
        key !== 'per_page'
      ) {
        const values = value.split(',').map((v) => v.trim());

        if (!allParams[key]) {
          allParams[key] = [];
        }
        allParams[key] = allParams[key].concat(values);
      }
    });

    const allParamsEntries = Object.entries(allParams);
    const newSelectedOptions = uniqueOptions(obj1)
      .filter((item) => allParamsEntries.some((el) => el[0] === item.value))
      .map((item) => {
        const find = allParamsEntries.find((el) => el[0] === item.value);
        const options = filtersObj[item.value]
          .filter((opt) => find[1].some((el) => String(el) === String(opt.id)))
          .map((el) => {
            return {
              value: el.id,
              label: el?.name
                ? typeof el.id === 'number'
                  ? `#${el?.id} ${el?.name}`
                  : el?.name
                : `#${el?.id} ${el?.first_name} ${el?.last_name}`,
            };
          });
        return {...item, options};
      });

    const newObj1 = obj1.map((item) => {
      const newOptions = item?.options?.filter(
        (opt) => !allParamsEntries.map((el) => el[0]).some((el) => el === opt.value)
      );
      return {...item, options: newOptions};
    });

    if (newSelectedOptions.length === 0 && selected.length === 0) {
      newSelectedOptions.push({
        value: 'state',
        label: 'Состояние',
        options: [{label: 'Активно', value: 'active'}],
      });
      createUrlWithoutReq(newSelectedOptions, selected);
    }

    setFiltersGroupOptions(newObj1);
    setSelectedOptions(newSelectedOptions);
    setSearchableParams(newSelectedOptions);
    getNewTime();
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
        setThemeMode('dark');
      } else {
        setMode('light');
        setThemeMode('light');
      }
    } else {
      setMode(theme.mode);
      setThemeMode(theme.mode);
    }
  }, [theme]);

  const formatTimeAgo = (milliseconds) => {
    if (new Date().getTime() - milliseconds * 1000 < 3600000) {
      return moment(milliseconds * 1000)
        .startOf('minute')
        .fromNow();
    }

    return moment(milliseconds * 1000)
      .startOf('hour')
      .fromNow();
  };

  const time = useRef(null);
  const intervalId = useRef(null);

  const getNewTime = () => {
    const newTime = new Date().getTime();
    time.current = newTime;

    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    intervalId.current = setInterval(() => {
      setTimeInfo(formatTimeAgo(time.current / 1000));
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, []);

  return (
    <div
      className={css.filtersContainer}
      style={{
        '--bgColor': mode === 'dark' ? '#1B1C22' : '#F9F9F9',
        '--borderColor': mode === 'dark' ? 'hsl(0, 0%, 20%)' : 'hsl(0, 0%, 80%)',
        '--closeColor': mode === 'dark' ? 'hsl(0, 0%, 80%)' : 'hsl(0, 0%, 20%)',
      }}
    >
      <section
        className={css.filtersListContainer}
        style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <Navigation
          page={group}
          setPage={setGroup}
          action={addGroup}
          links={[
            {link: '', title: 'Общая'},
            {link: 'day', title: 'День'},
            {link: 'month', title: 'Месяц'},
            {link: 'advertiser', title: 'Рекламодатель'},
            {link: 'offer', title: 'Оффер'},
            {link: 'pwa', title: 'PWA'},
            {link: 'traffic', title: 'Блогер'},
            {link: 'bayer', title: 'Байер'},
            {link: 'source', title: 'Сорс'},
          ]}
        />
        <div className={css.actions} style={{flexDirection: 'row', flexWrap: 'nowrap'}}>
          <span style={{display: 'block', whiteSpace: 'nowrap'}}>Обновлено: {timeInfo}</span>
          <TrekerButton
            onClick={() => {
              createUrl(selectedOptions, selectedGroup);
            }}
            style={{gap: '0px'}}
            svg={
              <svg
                viewBox='0 0 30.50039 30.5'
                xmlns='http://www.w3.org/2000/svg'
                width='15'
                height='16'
              >
                <g data-name='Layer 2' id='Layer_2'>
                  <g id='Interface-Solid'>
                    <g id='interface-solid-multimedia-refresh-button-1'>
                      <path d='M3.2312,10.5H9.25a1.25,1.25,0,0,0,0-2.5H5.36743A13.20678,13.20678,0,0,1,16.415,2.5a12.75654,12.75654,0,0,1,11.68945,7.65039,1.25027,1.25027,0,0,0,2.291-1.00195A15.25661,15.25661,0,0,0,16.415,0,15.47292,15.47292,0,0,0,2.5,7.93555V1.25a1.25,1.25,0,0,0-2.5,0v8A1.25085,1.25085,0,0,0,1.25,10.5Z' />
                      <path d='M29.25,20h-8a1.25,1.25,0,0,0,0,2.5h3.94965A11.00927,11.00927,0,0,1,15.25,28,12.75654,12.75654,0,0,1,3.56055,20.34961a1.25027,1.25027,0,0,0-2.291,1.002A15.25661,15.25661,0,0,0,15.25,30.5,13.625,13.625,0,0,0,28,22.58423V29.25a1.25,1.25,0,0,0,2.5,0v-8A1.25022,1.25022,0,0,0,29.25,20Z' />
                    </g>
                  </g>
                </g>
              </svg>
            }
          ></TrekerButton>
        </div>
      </section>
      <section className={css.filters}>
        {selectedOptions.map((item, index) => {
          return (
            <div className={css.selectContainer} key={`${index}`}>
              <Filter
                filters={filters}
                item={item}
                index={index}
                mode={mode}
                createUrl={createUrl}
                setSelectedOptions={setSelectedOptions}
                selectedOptions={selectedOptions}
                selectedGroup={selectedGroup}
                deleteOption={deleteOption}
              />
              <div className={css.selectClose} onClick={() => delFilter(item, index)}></div>
            </div>
          );
        })}
        <div className={css.filtersSelector}>
          <FilterOfFilters
            tabIndex={1}
            filtersGroupOptions={filtersGroupOptions}
            addSelectedOption={addSelectedOption}
            placeholder={'Добавить фильтр'}
            classButton={'addFilter'}
            mode={mode}
          />
        </div>
      </section>

      {searchableParams.some((item) => item?.options?.length > 0) && (
        <section className={css.filtersListContainer}>
          {searchableParams.map((item, index) => {
            return (
              <React.Fragment key={`${item.label}-${index}`}>
                {!!item?.options && item?.options?.length > 0 && (
                  <section className={css.list}>
                    <span className={css.name}>{item.label}:</span>
                    {item.options.map((el, i) => {
                      return (
                        <React.Fragment key={el.label}>
                          {i <= 2 && (
                            <div className={classNames(css.element, css._filter)}>
                              <span className={css.elementName}>{el.label}</span>
                              <span
                                className={css.elementRemove}
                                onClick={() => deleteSavedOption(index, i)}
                              ></span>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                    {item.options.length > 3 && (
                      <div className={classNames(css.element, css.otherElements, css.lastElements)}>
                        <span className={classNames(css.elementName)}>
                          +{item.options.length - 3}
                        </span>
                        <div className={css.elements}>
                          {item.options.map((el, i) => {
                            return (
                              <React.Fragment key={el.label}>
                                {i > 2 && (
                                  <span className={classNames(css.element, css.lastElements)}>
                                    {el.label}
                                  </span>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <i
                      className='ki-outline ki-basket fs-2 text-hover-danger cursor-pointer'
                      onClick={() => deleteOptions(index)}
                    ></i>
                  </section>
                )}
              </React.Fragment>
            );
          })}
        </section>
      )}

      <section
        className={css.filtersListContainer}
        style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}
      >
        <div className={css.groups}>
          {selectedGroup.length > 0 && (
            <div className={css.list}>
              {selectedGroup.map((el, i) => {
                return (
                  <React.Fragment key={el.label}>
                    <div className={css.element}>
                      <span className={css.elementName}>{el.label}</span>
                      <span className={css.elementRemove} onClick={() => deleteGroup(i)}></span>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
          <div className={css.filtersSelector}>
            <FilterOfFilters
              tabIndex={2}
              placeholder={'Добавить группу'}
              filtersGroupOptions={groupOptions}
              addSelectedOption={addSelectedGroup}
              classButton={'addGroup'}
              mode={mode}
            />
          </div>
        </div>
        <div className={css.actions}>
          <div>
            <span className='text-gray-600'>{resultsCount} результатов</span>
          </div>
          <div>
            <TrekerButton
              onClick={() => {
                const newSelectedGroup = [...selectedGroup];
                newSelectedGroup.splice(1);
                setSelectedGroup(newSelectedGroup);
                setGroup(newSelectedGroup.length > 0 ? newSelectedGroup[0].value : '');
                const newGroup = [...groupsObj].map((it) => {
                  const newOptions = it.options.filter(
                    (op) => !newSelectedGroup.some((el) => el.value === op.value)
                  );
                  return {...it, options: newOptions};
                });
                setGroupOptions(newGroup);

                setFiltersGroupOptions(filtersGroup);
                setSelectedOptions([]);
                setSearchableParams([]);
                createUrl([], []);
              }}
              svg={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='15'
                  height='16'
                  fill='none'
                  viewBox='0 0 15 16'
                >
                  <g fill='#292D32'>
                    <path d='M14.667 3a1 1 0 0 0-1-1h-2.518a2.996 2.996 0 0 0-2.816-2h-2a2.996 2.996 0 0 0-2.816 2H1a1 1 0 0 0 0 2h.333v8.333A3.667 3.667 0 0 0 5 16h4.667a3.667 3.667 0 0 0 3.666-3.667V4h.334a1 1 0 0 0 1-1Zm-3.334 9.333c0 .92-.746 1.667-1.666 1.667H5c-.92 0-1.667-.746-1.667-1.667V4h8v8.333Z' />
                    <path d='M5.667 12a1 1 0 0 0 1-1V7a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1ZM9 12a1 1 0 0 0 1-1V7a1 1 0 0 0-2 0v4a1 1 0 0 0 1 1Z' />
                  </g>
                </svg>
              }
            >
              Сброс
            </TrekerButton>
          </div>
          {actions}
        </div>
      </section>
    </div>
  );
});

export {Filters};
