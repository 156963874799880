/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';
import {customizationColumns} from './customizationTable';
import {Button} from '../shared/Buttons';
import {CreateAppModal} from '../../_metronic/partials';
import {TrekerButton} from '../pages/traffic/influence/bloggers/source/Treker/TrekerButton';

export const ColumnsFilter = ({linkStore, data, activeColumns, setActiveColumns}) => {
  const [showColumnsFilter, setShowColumnsFilter] = useState(false);

  const columnFilterList = Object.entries(data).map(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      return {
        column: {name: key},
        subColumns: Object.keys(value).map((subKey) => ({
          name: subKey,
        })),
      };
    } else {
      return {
        column: {name: key},
        subColumns: [],
      };
    }
  });

  const handelChange = (label) => {
    const newActiveColumns = [...activeColumns];
    const findIndex = newActiveColumns.findIndex((item) => item === label);
    if (findIndex === -1) {
      newActiveColumns.push(label);
    } else {
      newActiveColumns.splice(findIndex, 1);
    }

    setActiveColumns(newActiveColumns);
    localStorage.setItem(linkStore, JSON.stringify(newActiveColumns));
  };

  const handelChangeForLabels = (items) => {
    const newActiveColumns = [...activeColumns];
    const isAllActive = items.every(({name}) => activeColumns.includes(name));

    !isAllActive
      ? items.forEach(({name}) => {
          if (!newActiveColumns.includes(name)) {
            newActiveColumns.push(name);
          }
        })
      : items.forEach(({name}) => {
          const index = newActiveColumns.indexOf(name);
          if (index !== -1) {
            newActiveColumns.splice(index, 1);
          }
        });

    setActiveColumns(newActiveColumns);
    localStorage.setItem(linkStore, JSON.stringify(newActiveColumns));
  };

  return (
    <>
      <TrekerButton onClick={() => setShowColumnsFilter(true)}>Колонки</TrekerButton>
      <CreateAppModal
        customWidth='mw-700px'
        show={showColumnsFilter}
        handleClose={() => setShowColumnsFilter(false)}
        showHeader={true}
        title='Колонки'
        isMessage={false}
      >
        <div>
          <div className='row row-cols-3 g-5'>
            {columnFilterList.map((item) => {
              if (item.subColumns?.length > 0) {
                const checkedGroup = item.subColumns.some(
                  ({name}) => !activeColumns?.includes(name)
                );

                return (
                  <div key={item.column.name} className='d-flex flex-column col'>
                    <label
                      key={item.name}
                      className='checkbox d-flex align-items-center mb-4 gap-5'
                      style={{cursor: 'pointer'}}
                    >
                      <input
                        type='checkbox'
                        checked={checkedGroup}
                        onChange={() => handelChangeForLabels(item.subColumns)}
                      />
                      <strong className='text-uppercase'>
                        {customizationColumns(item.column).label}
                      </strong>
                    </label>
                    {item.subColumns.map((subItem) => {
                      const c = customizationColumns(subItem);
                      return (
                        <label
                          key={subItem.name}
                          className='checkbox d-flex align-items-center gap-5'
                          style={{cursor: 'pointer'}}
                        >
                          <input
                            type='checkbox'
                            checked={!activeColumns?.includes(c?.name)}
                            // onChange={(e) => filterColumns(subItem.name, !e.target.checked)}
                            onChange={(e) => handelChange(c.name)}
                          />
                          <span>{c.label}</span>
                        </label>
                      );
                    })}
                  </div>
                );
              }

              return null;
            })}
            {columnFilterList.map((item) => {
              if (item.subColumns?.length > 0) return null;
              const c = customizationColumns(item.column);
              if (c.name === 'avatar' || c.name === 'is_active') return null;

              return (
                <label
                  key={item.column.name}
                  className='checkbox d-flex align-items-center gap-3'
                  style={{cursor: 'pointer'}}
                >
                  <input
                    type='checkbox'
                    checked={!activeColumns?.includes(c.name)}
                    onChange={(e) => handelChange(c.name)}
                  />
                  <span>{c.label}</span>
                </label>
              );
            })}
          </div>
          <div className='d-flex mt-20 justify-content-end'>
            <Button
              color='btn-primary me-2'
              action={() => {
                setShowColumnsFilter(false);
              }}
            >
              Готово
            </Button>
            <Button
              color='btn-secondary'
              action={() => {
                localStorage.removeItem(linkStore);
                setActiveColumns([]);
              }}
            >
              Восстановить по умолчанию
            </Button>
          </div>
        </div>
      </CreateAppModal>
    </>
  );
};
