/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react';
// import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub';
import {SidebarMenuItem, SidebarMenuLink} from './SidebarMenuItem';
import {useStore} from '../../../../../store/store';
import {observer} from 'mobx-react';

const SidebarMenuMain = () => {
  const store = useStore();

  return (
    <>
      {store.roles.includes(9) && <ManagerLTC />}
      {store.roles.includes(33) && <ManagerDeputyLTC />}
      {store.roles.includes(16) && <TeamLeaderLTC />}
      {store.roles.includes(15) && <ManagerRD />}
      {store.roles.includes(34) && <ManagerDeputyRD />}
      {store.roles.includes(17) && <TeamLeaderRD />}
      {store.roles.includes(8) && <HeadOfSales />}
      {store.roles.includes(11) && <HeadOfInfluence />}
      {store.roles.includes(10) && <HeadOfFacebook />}
      {store.roles.includes(6) && <FacebookBayerProfit />}
      {store.roles.includes(19) && <InfluenceBayerCost />}
      {store.roles.includes(13) && <HeadOfFinance />}
      {store.roles.includes(32) && <AssistantOfFinance />}
      {store.roles.includes(26) && <Hr />}
      {store.roles.includes(28) && <ManagerSupport />}
      {store.roles.includes(29) && <ManagerCold />}
      {store.roles.includes(30) && <HeadAssistantOfSales />}
      {store.roles.includes(31) && <CRMBackend />}
      {store.roles.includes(36) && <InfluenceTeamLeadBayerCost />}
      {store.roles.includes(38) && <CBDO />}

      {/* {console.log(roles)} */}

      {store.roles.includes(4) && (
        <>
          <SidebarMenuItemWithSub
            to='/dashboard'
            title={'Панели'}
            icon='element-11'
            hasBullet={false}
          >
            <SidebarMenuItem to='/dashboard/main' title='Основная панель' hasBullet={true} />

            <SidebarMenuItem to='/dashboard/sales' title='Отдел продаж' hasBullet={true} />

            {/* <SidebarMenuItem to='/dashboard/teamleader/ltc' title='Тимлидер LTC' hasBullet={true} /> */}
            {/* <SidebarMenuItem to='/dashboard/teamleader/rd' title='Тимлидер RD' hasBullet={true} /> */}
            {/* <SidebarMenuItem to='/dashboard/manager/ltc' title='Менеджер LTC' hasBullet={true} /> */}
            {/* <SidebarMenuItem to='/dashboard/manager/rd' title='Менеджер RD' hasBullet={true} /> */}

            <SidebarMenuItem to='/dashboard/facebook' title='Facebook' hasBullet={true} />

            <SidebarMenuItem to='/dashboard/influence' title='Influence' hasBullet={true} />
            {/* <SidebarMenuItem to='/dashboard/facebook/bayer' title='Байер Facebook' hasBullet={true} /> */}

            {/* <SidebarMenuItem to='/dashboard/managerPanel' title='Панель Менеджера' hasBullet={true} /> */}
            {/* <SidebarMenuItem to='/' title='Панель Ропа' hasBullet={true} /> */}
            <SidebarMenuItem to='/dashboard/hr' title='Панель HR' hasBullet={true} />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/traffics'
            title={'Трафик'}
            icon='abstract-12'
            hasBullet={false}
          >
            <SidebarMenuItemWithSub to='/traffics/influence' title={'Influence'} hasBullet={true}>
              <SidebarMenuItem to='/traffics/influence/bloggers' title='Блогеры' hasBullet={true} />
              <SidebarMenuItem to='/traffics/influence/bayers' title='Байера' hasBullet={true} />
              <SidebarMenuItem
                to='/traffics/influence/statistics'
                title='Статистика'
                hasBullet={true}
              />
            </SidebarMenuItemWithSub>

            {/* <SidebarMenuItem to='/traffic/facebook' title='Facebook' hasBullet={true} /> */}
            <SidebarMenuItemWithSub to='/traffics/facebook' title={'Facebook'} hasBullet={true}>
              <SidebarMenuItem
                to='/traffics/facebook/companies'
                title='Кампании'
                hasBullet={true}
              />
              <SidebarMenuItem to='/traffics/facebook/bayers' title='Байера' hasBullet={true} />
              <SidebarMenuItem to='/traffics/facebook/sources' title='Сорсы' hasBullet={true} />
              <SidebarMenuItem to='/traffics/facebook/accounts' title='Аккаунты' hasBullet={true} />
              <SidebarMenuItem
                to='/traffics/facebook/statistics'
                title='Статистика'
                hasBullet={true}
              />
            </SidebarMenuItemWithSub>
            <SidebarMenuItem to='/traffics/conversions' title={'Конверсии'} hasBullet={true} />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/sales/'
            title={'Отдел продаж'}
            componentIcon={
              <i className='ki-duotone ki-tag fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            }
            hasBullet={false}
          >
            <SidebarMenuItemWithSub to='/sales/ltc' title={'Отдел LTC'} hasBullet={true}>
              <SidebarMenuItem to='/sales/ltc/top/teams' title='ТОП Команд' hasBullet={true} />
              <SidebarMenuItem
                to='/sales/ltc/top/managers'
                title='ТОП Менеджеров'
                hasBullet={true}
              />
              <SidebarMenuItem to='/sales/ltc/teams' title='Команды' hasBullet={true} />
              <SidebarMenuItem to='/sales/ltc/managers' title='Менеджера' hasBullet={true} />
              <SidebarMenuItem to='/sales/ltc/kpi' title='KPI' hasBullet={true} />
              <SidebarMenuItem to='/sales/ltc/analysis' title='Анализ' hasBullet={true} />
            </SidebarMenuItemWithSub>
            <SidebarMenuItemWithSub to='/sales/rd' title={'Отдел RD'} hasBullet={true}>
              <SidebarMenuItem
                to='/sales/rd/top/managers'
                title='ТОП Менеджеров'
                hasBullet={true}
              />
              <SidebarMenuItem to='/sales/rd/teams' title='Команды' hasBullet={true} />
              <SidebarMenuItem to='/sales/rd/managers' title='Менеджера' hasBullet={true} />
              <SidebarMenuItem to='/sales/rd/kpi' title='KPI' hasBullet={true} />
              <SidebarMenuItem to='/sales/rd/analysis' title='Анализ' hasBullet={true} />
              <SidebarMenuItem to='/sales/rd/soft' title='Анализ Soft' hasBullet={true} />
              <SidebarMenuItem to='/sales/rd/leads' title='Лиды' hasBullet={true} />
            </SidebarMenuItemWithSub>
            <SidebarMenuItem to='/crm' title='CRM' hasBullet={true} />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/advertisers'
            title={'Рекламодатели'}
            componentIcon={
              <i className='ki-duotone ki-briefcase fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            }
            hasBullet={false}
          >
            <SidebarMenuItem to='/advertisers/list' title='Рекламодатели' hasBullet={true} />
            <SidebarMenuItem to='/advertisers/offers' title='Офферы' hasBullet={true} />
            <SidebarMenuItem to='/advertisers/reports' title='Отчеты' hasBullet={true} />
          </SidebarMenuItemWithSub>

          {/* {(store.roles.includes(4) ||
        store.roles.includes('Project Manager') ||
        store.roles.includes('ROP')) && <SidebarMenuItem to='/links' icon='arrow-mix' title={'Ссылки'} />} */}

          <SidebarMenuItemWithSub
            to='/resources'
            title={'Ресурсы'}
            componentIcon={
              <i className='ki-duotone ki-switch fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            }
            hasBullet={false}
          >
            <SidebarMenuItem to='/resources/channels' title='Каналы' hasBullet={true} />
            <SidebarMenuItem to='/resources/pixels' title='Пиксели' hasBullet={true} />
            <SidebarMenuItem to='/resources/bots' title='Боты' hasBullet={true} />
            <SidebarMenuItem to='/resources/landings' title='Лендинги' hasBullet={true} />
            <SidebarMenuItem to='/resources/pwa' title='PWA' hasBullet={true} />
            <SidebarMenuItemWithSub to='/resources/telegram' title={'Телеграм'} hasBullet={true}>
              <SidebarMenuItem
                to='/resources/telegram/accounts'
                title='Аккаунты'
                hasBullet={true}
              />
            </SidebarMenuItemWithSub>
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/tech'
            title={'Тех отдел'}
            componentIcon={
              <i className='ki-duotone ki-technology-4 fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
                <span className='path4'></span>
                <span className='path5'></span>
                <span className='path6'></span>
                <span className='path7'></span>
              </i>
            }
            hasBullet={false}
          >
            <SidebarMenuItem to='/tech/servers' title='Сервера' hasBullet={true} />
            <SidebarMenuItem to='/tech/domains' title='Домены' hasBullet={true} />
            <SidebarMenuLink
              to={`${process.env.REACT_APP_DOCUMENTATION}api/documentation/`}
              title='API'
              hasBullet={true}
            />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/users'
            title={'Сотрудники'}
            componentIcon={
              <i className='ki-duotone ki-badge fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
                <span className='path4'></span>
                <span className='path5'></span>
              </i>
            }
            hasBullet={false}
          >
            <SidebarMenuItem to='/users/list' title='Список сотрудников' hasBullet={true} />

            <SidebarMenuItem to='/users/roles' title={'Роли'} hasBullet={true} />
            {/* <SidebarMenuItem to='/permissions' title='Доступы' hasBullet={true} /> */}

            <SidebarMenuItem to='/users/departments' title='Департаменты' hasBullet={true} />
          </SidebarMenuItemWithSub>
          <SidebarMenuItem
            to='/pwa/'
            title={'PWA'}
            componentIcon={
              <i
                className='ki-duotone ki-monitor-mobile fs-1'
              >
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            }
          />
          <SidebarMenuItem
            to='/leads/'
            title={'Лиды'}
            componentIcon={
              <i className='ki-duotone ki-fingerprint-scanning fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
                <span className='path4'></span>
                <span className='path5'></span>
              </i>
            }
          />

          <SidebarMenuItemWithSub
            to='/finances'
            title={'Финансы'}
            componentIcon={
              <i className='ki-duotone ki-bill fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
                <span className='path4'></span>
                <span className='path5'></span>
                <span className='path6'></span>
              </i>
            }
            hasBullet={false}
          >
            <SidebarMenuItem to='/finances/statistics' title='Статистика' hasBullet={true} />
            <SidebarMenuItem to='/finances/accounts' title='Счета' hasBullet={true} />
            <SidebarMenuItem to='/finances/cashflow' title='CashFlow' hasBullet={true} />
            <SidebarMenuItem to='/finances/costs' title='Расходы' hasBullet={true} />
            <SidebarMenuItem to='/finances/payments' title='Выплаты' hasBullet={true} />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/account/'
            title='Мой Аккаунт'
            icon='profile-circle'
            hasBullet={false}
          >
            <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
            <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
            <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
            <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
          </SidebarMenuItemWithSub>

          <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
        </>
      )}
    </>
  );
};

const CBDO = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/main' title='Основная панель' hasBullet={true} />
        <SidebarMenuItem to='/dashboard/facebook' title='Facebook' hasBullet={true} />
        <SidebarMenuItem to='/dashboard/influence' title='Influence' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/traffics' title={'Трафик'} icon='abstract-12' hasBullet={false}>
        <SidebarMenuItemWithSub to='/traffics/influence' title={'Influence'} hasBullet={true}>
          <SidebarMenuItem to='/traffics/influence/bloggers' title='Блогеры' hasBullet={true} />
          <SidebarMenuItem to='/traffics/influence/bayers' title='Байера' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub to='/traffics/facebook' title={'Facebook'} hasBullet={true}>
          <SidebarMenuItem to='/traffics/facebook/companies' title='Кампании' hasBullet={true} />
          <SidebarMenuItem to='/traffics/facebook/bayers' title='Байера' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/traffics/conversions' title={'Конверсии'} hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/advertisers'
        title={'Рекламодатели'}
        componentIcon={
          <i className='ki-duotone ki-briefcase fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/advertisers/list' title='Рекламодатели' hasBullet={true} />
        <SidebarMenuItem to='/advertisers/offers' title='Офферы' hasBullet={true} />
        {/* <SidebarMenuItem to='/advertisers/reports' title='Отчеты' hasBullet={true} /> */}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/account/'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const Hr = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/hr' title='Панель HR' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/users'
        title={'Сотрудники'}
        componentIcon={
          <i className='ki-duotone ki-badge fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/users/list' title='Список сотрудников' hasBullet={true} />

        <SidebarMenuItem to='/users/roles' title={'Роли'} hasBullet={true} />
        {/* <SidebarMenuItem to='/permissions' title='Доступы' hasBullet={true} /> */}

        <SidebarMenuItem to='/users/departments' title='Департаменты' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/account/'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const HeadOfFacebook = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/facebook' title='Facebook' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/traffics' title={'Трафик'} icon='abstract-12' hasBullet={false}>
        <SidebarMenuItemWithSub to='/traffics/facebook' title={'Facebook'} hasBullet={true}>
          <SidebarMenuItem to='/traffics/facebook/companies' title='Кампании' hasBullet={true} />
          <SidebarMenuItem to='/traffics/facebook/bayers' title='Байера' hasBullet={true} />
          <SidebarMenuItem to='/traffics/facebook/accounts' title='Аккаунты' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/resources'
        title={'Ресурсы'}
        componentIcon={
          <i className='ki-duotone ki-switch fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/resources/pixels' title='Пиксели' hasBullet={true} />
        <SidebarMenuItem to='/resources/landings' title='Лендинги' hasBullet={true} />
        <SidebarMenuItem to='/resources/pwa' title='PWA' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/users'
        title={'Сотрудники'}
        componentIcon={
          <i className='ki-duotone ki-badge fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/users/list' title='Список сотрудников' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const HeadOfInfluence = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/influence' title='Influence' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to='/traffics' title={'Трафик'} icon='abstract-12' hasBullet={false}>
        <SidebarMenuItemWithSub to='/traffics/influence' title={'Influence'} hasBullet={true}>
          <SidebarMenuItem to='/traffics/influence/bloggers' title='Блогеры' hasBullet={true} />
          <SidebarMenuItem to='/traffics/influence/bayers' title='Байера' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/users'
        title={'Сотрудники'}
        componentIcon={
          <i className='ki-duotone ki-badge fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/users/list' title='Список сотрудников' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const HeadOfSales = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/sales' title='Отдел продаж' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/sales/ltc' title={'Отдел LTC'} hasBullet={true}>
          <SidebarMenuItem to='/sales/ltc/top/teams' title='ТОП Команд' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/teams' title='Команды' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/managers' title='Менеджера' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/kpi' title='KPI' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/analysis' title='Анализ' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub to='/sales/rd' title={'Отдел RD'} hasBullet={true}>
          <SidebarMenuItem to='/sales/rd/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/teams' title='Команды' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/managers' title='Менеджера' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/kpi' title='KPI' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/analysis' title='Анализ' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/soft' title='Анализ Soft' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/leads' title='Лиды' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/crm' title='CRM' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/resources'
        title={'Ресурсы'}
        componentIcon={
          <i className='ki-duotone ki-switch fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/resources/telegram' title={'Телеграм'} hasBullet={true}>
          <SidebarMenuItem to='/resources/telegram/accounts' title='Аккаунты' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/users'
        title={'Сотрудники'}
        componentIcon={
          <i className='ki-duotone ki-badge fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/users/list' title='Список сотрудников' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/leads/'
        title={'Лиды'}
        componentIcon={
          <i className='ki-duotone ki-fingerprint-scanning fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
      />
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const TeamLeaderRD = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/teamleader/rd' title='Тимлидер RD' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/sales/rd' title={'Отдел RD'} hasBullet={true}>
          <SidebarMenuItem to='/sales/rd/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/managers' title='Менеджера' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/kpi' title='KPI' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/leads' title='Лиды' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/soft' title='Анализ Soft' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/crm' title='CRM' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/leads/'
        title={'Лиды'}
        componentIcon={
          <i className='ki-duotone ki-fingerprint-scanning fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
      />
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const ManagerRD = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/manager/rd' title='Менеджер RD' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/sales/rd' title={'Отдел RD'} hasBullet={true}>
          <SidebarMenuItem to='/sales/rd/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/kpi' title='KPI' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/leads' title='Лиды' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/crm' title='CRM' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/leads/'
        title={'Лиды'}
        componentIcon={
          <i className='ki-duotone ki-fingerprint-scanning fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
      />
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const ManagerDeputyRD = () => {
  return (
    <>
      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/sales/rd' title={'Отдел RD'} hasBullet={true}>
          <SidebarMenuItem to='/sales/rd/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          {/* <SidebarMenuItem to='/sales/rd/kpi' title='KPI' hasBullet={true} /> */}
          <SidebarMenuItem to='/sales/rd/leads' title='Лиды' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/crm' title='CRM' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/leads/'
        title={'Лиды'}
        componentIcon={
          <i className='ki-duotone ki-fingerprint-scanning fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
      />
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const TeamLeaderLTC = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/teamleader/ltc' title='Тимлидер LTC' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/sales/ltc' title={'Отдел LTC'} hasBullet={true}>
          <SidebarMenuItem to='/sales/ltc/top/teams' title='ТОП Команд' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/managers' title='Менеджера' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/kpi' title='KPI' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/crm' title='CRM' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/leads/'
        title={'Лиды'}
        componentIcon={
          <i className='ki-duotone ki-fingerprint-scanning fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
      />
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const ManagerLTC = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/manager/ltc' title='Менеджер LTC' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/sales/ltc' title={'Отдел LTC'} hasBullet={true}>
          <SidebarMenuItem to='/sales/ltc/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/kpi' title='KPI' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/crm' title='CRM' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/leads/'
        title={'Лиды'}
        componentIcon={
          <i className='ki-duotone ki-fingerprint-scanning fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
      />
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const ManagerDeputyLTC = () => {
  return (
    <>
      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/sales/ltc' title={'Отдел LTC'} hasBullet={true}>
          <SidebarMenuItem to='/sales/ltc/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          {/* <SidebarMenuItem to='/sales/ltc/kpi' title='KPI' hasBullet={true} /> */}
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/crm' title='CRM' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/leads/'
        title={'Лиды'}
        componentIcon={
          <i className='ki-duotone ki-fingerprint-scanning fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
      />
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const FacebookBayerProfit = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/facebook/bayer' title='Байер Facebook' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/traffics' title={'Трафик'} icon='abstract-12' hasBullet={false}>
        <SidebarMenuItemWithSub to='/traffics/facebook' title={'Facebook'} hasBullet={true}>
          <SidebarMenuItem to='/traffics/facebook/companies' title='Кампании' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};
const InfluenceBayerCost = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/influence/bayer' title='Байер Influence' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/traffics' title={'Трафик'} icon='abstract-12' hasBullet={false}>
        <SidebarMenuItemWithSub to='/traffics/influence' title={'Influence'} hasBullet={true}>
          <SidebarMenuItem to='/traffics/influence/bloggers' title='Блогеры' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const InfluenceTeamLeadBayerCost = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/influence' title='Influence' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/traffics' title={'Трафик'} icon='abstract-12' hasBullet={false}>
        <SidebarMenuItemWithSub to='/traffics/influence' title={'Influence'} hasBullet={true}>
          <SidebarMenuItem to='/traffics/influence/bloggers' title='Блогеры' hasBullet={true} />
          <SidebarMenuItem to='/traffics/influence/bayers' title='Байера' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const HeadOfFinance = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/main' title='Основная панель' hasBullet={true} />
        <SidebarMenuItem to='/dashboard/sales' title='Отдел продаж' hasBullet={true} />
        <SidebarMenuItem to='/dashboard/facebook' title='Facebook' hasBullet={true} />
        <SidebarMenuItem to='/dashboard/influence' title='Influence' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/traffics' title={'Трафик'} icon='abstract-12' hasBullet={false}>
        <SidebarMenuItemWithSub to='/traffics/influence' title={'Influence'} hasBullet={true}>
          <SidebarMenuItem to='/traffics/influence/bloggers' title='Блогеры' hasBullet={true} />
          <SidebarMenuItem to='/traffics/influence/bayers' title='Байера' hasBullet={true} />
        </SidebarMenuItemWithSub>

        {/* <SidebarMenuItem to='/traffic/facebook' title='Facebook' hasBullet={true} /> */}
        <SidebarMenuItemWithSub to='/traffics/facebook' title={'Facebook'} hasBullet={true}>
          <SidebarMenuItem to='/traffics/facebook/companies' title='Кампании' hasBullet={true} />
          <SidebarMenuItem to='/traffics/facebook/bayers' title='Байера' hasBullet={true} />
          <SidebarMenuItem to='/traffics/facebook/accounts' title='Аккаунты' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/sales/ltc' title={'Отдел LTC'} hasBullet={true}>
          <SidebarMenuItem to='/sales/ltc/top/teams' title='ТОП Команд' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/teams' title='Команды' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/managers' title='Менеджера' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/kpi' title='KPI' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/analysis' title='Анализ' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub to='/sales/rd' title={'Отдел RD'} hasBullet={true}>
          <SidebarMenuItem to='/sales/rd/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/teams' title='Команды' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/managers' title='Менеджера' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/kpi' title='KPI' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/analysis' title='Анализ' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/advertisers'
        title={'Рекламодатели'}
        componentIcon={
          <i className='ki-duotone ki-briefcase fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/advertisers/list' title='Рекламодатели' hasBullet={true} />
        <SidebarMenuItem to='/advertisers/offers' title='Офферы' hasBullet={true} />
        <SidebarMenuItem to='/advertisers/reports' title='Отчеты' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/users'
        title={'Сотрудники'}
        componentIcon={
          <i className='ki-duotone ki-badge fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/users/list' title='Список сотрудников' hasBullet={true} />

        <SidebarMenuItem to='/users/roles' title={'Роли'} hasBullet={true} />
        {/* <SidebarMenuItem to='/permissions' title='Доступы' hasBullet={true} /> */}

        <SidebarMenuItem to='/users/departments' title='Департаменты' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const AssistantOfFinance = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/main' title='Основная панель' hasBullet={true} />
        <SidebarMenuItem to='/dashboard/sales' title='Отдел продаж' hasBullet={true} />
        <SidebarMenuItem to='/dashboard/facebook' title='Facebook' hasBullet={true} />
        <SidebarMenuItem to='/dashboard/influence' title='Influence' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/traffics' title={'Трафик'} icon='abstract-12' hasBullet={false}>
        <SidebarMenuItemWithSub to='/traffics/influence' title={'Influence'} hasBullet={true}>
          <SidebarMenuItem to='/traffics/influence/bloggers' title='Блогеры' hasBullet={true} />
          <SidebarMenuItem to='/traffics/influence/bayers' title='Байера' hasBullet={true} />
        </SidebarMenuItemWithSub>

        {/* <SidebarMenuItem to='/traffic/facebook' title='Facebook' hasBullet={true} /> */}
        <SidebarMenuItemWithSub to='/traffics/facebook' title={'Facebook'} hasBullet={true}>
          <SidebarMenuItem to='/traffics/facebook/companies' title='Кампании' hasBullet={true} />
          <SidebarMenuItem to='/traffics/facebook/bayers' title='Байера' hasBullet={true} />
          <SidebarMenuItem to='/traffics/facebook/accounts' title='Аккаунты' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/sales/ltc' title={'Отдел LTC'} hasBullet={true}>
          <SidebarMenuItem to='/sales/ltc/top/teams' title='ТОП Команд' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/teams' title='Команды' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/managers' title='Менеджера' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/kpi' title='KPI' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/analysis' title='Анализ' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub to='/sales/rd' title={'Отдел RD'} hasBullet={true}>
          <SidebarMenuItem to='/sales/rd/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/teams' title='Команды' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/managers' title='Менеджера' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/kpi' title='KPI' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/analysis' title='Анализ' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/advertisers'
        title={'Рекламодатели'}
        componentIcon={
          <i className='ki-duotone ki-briefcase fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/advertisers/list' title='Рекламодатели' hasBullet={true} />
        <SidebarMenuItem to='/advertisers/offers' title='Офферы' hasBullet={true} />
        <SidebarMenuItem to='/advertisers/reports' title='Отчеты' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/users'
        title={'Сотрудники'}
        componentIcon={
          <i className='ki-duotone ki-badge fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/users/list' title='Список сотрудников' hasBullet={true} />

        <SidebarMenuItem to='/users/roles' title={'Роли'} hasBullet={true} />
        {/* <SidebarMenuItem to='/permissions' title='Доступы' hasBullet={true} /> */}

        <SidebarMenuItem to='/users/departments' title='Департаменты' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const ManagerSupport = () => {
  return (
    <>
      <SidebarMenuItem
        to='/leads/'
        title={'Лиды'}
        componentIcon={
          <i className='ki-duotone ki-fingerprint-scanning fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
      />
      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/crm' title='CRM' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const ManagerCold = () => {
  return (
    <>
      <SidebarMenuItem
        to='/leads/'
        title={'Лиды'}
        componentIcon={
          <i className='ki-duotone ki-fingerprint-scanning fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
      />
      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/crm' title='CRM' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const HeadAssistantOfSales = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/sales' title='Отдел продаж' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/sales/ltc' title={'Отдел LTC'} hasBullet={true}>
          <SidebarMenuItem to='/sales/ltc/top/teams' title='ТОП Команд' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/teams' title='Команды' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/managers' title='Менеджера' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/kpi' title='KPI' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/analysis' title='Анализ' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub to='/sales/rd' title={'Отдел RD'} hasBullet={true}>
          <SidebarMenuItem to='/sales/rd/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/teams' title='Команды' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/managers' title='Менеджера' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/kpi' title='KPI' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/analysis' title='Анализ' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/soft' title='Анализ Soft' hasBullet={true} />

          <SidebarMenuItem to='/sales/rd/leads' title='Лиды' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/crm' title='CRM' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/resources'
        title={'Ресурсы'}
        componentIcon={
          <i className='ki-duotone ki-switch fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/resources/telegram' title={'Телеграм'} hasBullet={true}>
          <SidebarMenuItem to='/resources/telegram/accounts' title='Аккаунты' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/users'
        title={'Сотрудники'}
        componentIcon={
          <i className='ki-duotone ki-badge fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/users/list' title='Список сотрудников' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/leads/'
        title={'Лиды'}
        componentIcon={
          <i className='ki-duotone ki-fingerprint-scanning fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
      />
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

const CRMBackend = () => {
  return (
    <>
      <SidebarMenuItemWithSub to='/dashboard' title={'Панели'} icon='element-11' hasBullet={false}>
        <SidebarMenuItem to='/dashboard/sales' title='Отдел продаж' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/sales/'
        title={'Отдел продаж'}
        componentIcon={
          <i className='ki-duotone ki-tag fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItemWithSub to='/sales/ltc' title={'Отдел LTC'} hasBullet={true}>
          <SidebarMenuItem to='/sales/ltc/top/teams' title='ТОП Команд' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/teams' title='Команды' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/managers' title='Менеджера' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/kpi' title='KPI' hasBullet={true} />
          <SidebarMenuItem to='/sales/ltc/analysis' title='Анализ' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub to='/sales/rd' title={'Отдел RD'} hasBullet={true}>
          <SidebarMenuItem to='/sales/rd/top/managers' title='ТОП Менеджеров' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/teams' title='Команды' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/managers' title='Менеджера' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/kpi' title='KPI' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/analysis' title='Анализ' hasBullet={true} />
          <SidebarMenuItem to='/sales/rd/leads' title='Лиды' hasBullet={true} />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem to='/crm' title='CRM' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/users'
        title={'Сотрудники'}
        componentIcon={
          <i className='ki-duotone ki-badge fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
        hasBullet={false}
      >
        <SidebarMenuItem to='/users/list' title='Список сотрудников' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/leads/'
        title={'Лиды'}
        componentIcon={
          <i className='ki-duotone ki-fingerprint-scanning fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
            <span className='path5'></span>
          </i>
        }
      />
      <SidebarMenuItemWithSub
        to='/account'
        title='Мой Аккаунт'
        icon='profile-circle'
        hasBullet={false}
      >
        <SidebarMenuItem to='/account/info' title='Данные' hasBullet={true} />
        <SidebarMenuItem to='/account/settings' title='Настройки' hasBullet={true} />
        <SidebarMenuItem to='/account/billing' title='Платежная информация' hasBullet={true} />
        <SidebarMenuItem to='/account/security' title='Безопасность' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem to='/wallet' icon='wallet' title={'Кошелек'} />
    </>
  );
};

export default observer(SidebarMenuMain);
