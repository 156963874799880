import React from 'react';
import GoogleplayApp from './templates/googleplay-app/googleplay-app';
import GoogleplaySite from './templates/googleplay_site/googleplay-site';
import Lucky from "./templates/1win-lucky/lucky";
import WinBloggers from "./templates/1win-bloggers/WinBloggers";

const Previews = ({data, currentLang, typeApp, id}) => {
  return (
    <>
      {typeApp === 'Google-Play-Store' && <GoogleplayApp data={data} currentLang={currentLang} id={id}/>}
      {typeApp === 'Google-Play-Site' && <GoogleplaySite data={data} currentLang={currentLang} id={id}/>}
      {typeApp === 'LuckyJet Web-to-Web' && <Lucky data={data} currentLang={currentLang} id={id}/>}
      {typeApp === '1win Blogger Landing' && <WinBloggers data={data} currentLang={currentLang} id={id}/>}
    </>
  );
};

export default Previews;
