import {api} from './api';

const advertisersAPI = {
  async salesTeamsGetList(token) {
    try {
      const response = await api.get(`Advertisers/getList?token=${token}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async advertisersGetAdvertiser(token, id) {
    try {
      const response = await api.get(`Advertisers/getAdvertiser?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async advertisersGetAdvertisersListPage(token, page, perPage) {
    try {
      const response = await api.get(`Advertisers/getAdvertisersListPage?token=${token}&page=${page}&per_page=${perPage}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async advertisersAddAdvertiser(data) {
    try {
      const response = await api.post(`Advertisers/addAdvertiser`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async advertisersUpdateAdvertiser(data) {
    try {
      const response = await api.post(`Advertisers/updateAdvertiser`, data);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async advertisersGetOffers(token, id) {
    try {
      const response = await api.get(`Advertisers/getOffers?token=${token}&id=${id}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },

  async advertisersAnalysisGetMainStats(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (!value) continue;
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`Advertisers/Analysis/getMainStats${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
  async advertisersAnalysisGetSoft(data) {
    let queryStr = '';

    for (let [key, value] of Object.entries(data)) {
      if (key === 'token') {
        queryStr += `?${key}=${value}`;
        continue;
      }
      if (key === 'timeConfig') {
        if (!value) continue;
        queryStr += `&time_config=${value.time_config}&time_start=${value.time_start}&time_end=${value.time_end}`;
        continue;
      }

      queryStr += `&${key}=${value}`;
    }

    try {
      const response = await api.get(`Advertisers/Analysis/getSoft${queryStr}`);

      return response?.data;
    } catch (error) {
      console.error('Основной сервер не отвечает', error);
      return {success: error.message};
    }
  },
};

export default advertisersAPI;
