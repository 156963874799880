/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../store/store';
import {observer} from 'mobx-react';
import css from '../../../styles/PwaConstructor.module.scss';
import classNames from 'classnames';
import BlockTitles from './templates/BlockTitles';
import BlockLine from './templates/BlockLine';
import {SelectorWithSearch} from '../../shared/Selectors';

const CopyForm = ({
  setStep,
  templatesNames,
  setDataNameForm,
  setPwaToCopy,
  setDataOffersFormBc,
  setDataOffersFormFt,
}) => {
  const store = useStore();
  const [change, setChange] = useState(false);
  const [pwas, setPwas] = useState([]);
  const [pwa, setPwa] = useState(null);
  const [validation, setValidation] = useState(false);

  const [addOffers, setAddOffers] = useState(false);
  const [addPushes, setAddPushes] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const getPWAs = await store.resourcePWAgetApps({
      time_config: 'current_month',
      time_start: '',
      time_end: '',
    });
    setPwas(
      getPWAs.map((item) => {
        return {value: item.id, label: `#${item?.id} ${item?.name}`};
      })
    );
  };

  const nextStep = async () => {
    if (!pwa && !!change) return setValidation(true);

    if (!change) {
      setStep(1);
    } else {
      const getPwaData = await store.resourcePWAgetAppConstructor(+pwa);
      const template = templatesNames.find((item) => item.value === getPwaData?.template_id);

      if (!!addOffers) {
        setDataNameForm({
          name: '',
          template: {value: template?.value, label: template?.label},
        });

        setDataOffersFormBc({
          country_offers: getPwaData?.country_offers,
          offer_landing_id: getPwaData?.offer_landing_id,
          split_enabled: getPwaData?.split_enabled,
          apps_split: getPwaData?.apps_split,
        });
        
        setDataOffersFormFt({
          country_offers: getPwaData?.country_offers,
          offer_landing_id: getPwaData?.offer_landing_id,
          split_enabled: getPwaData?.split_enabled,
          apps_split: getPwaData?.apps_split,
        });
      } else {
        setDataNameForm({
          template: {value: template?.value, label: template?.label},
        });
      }

      setPwaToCopy({...getPwaData, addOffers, addPushes});
      setStep(1);
    }
  };

  return (
    <>
      <div className={css.copyForm}>
        <BlockTitles
          title={'Создание / Дублирование'}
          description={
            'На этом этапе выберите, хотите ли вы дублировать уже существующий PWA или создать новый PWA.'
          }
        />
        <BlockLine />
        <div
          className={classNames(css.slideSelector, change && css._change)}
          onClick={() => setChange(!change)}
        >
          <span>Создать PWA</span>
          <span>Дублировать PWA</span>
        </div>
        {!!change && (
          <>
            <label htmlFor='' style={{width: '100%', margin: '16px 0px'}}>
              <span style={{display: 'inline-block', marginBottom: '8px'}}>
                Выберите PWA для дублирования
              </span>
              <SelectorWithSearch
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '45px',
                    border: !!validation && '2px solid #F8285A !important',
                    boxShadow: !!validation && 'none',
                  }),
                }}
                placeholder=''
                options={pwas}
                value={pwas.find((item) => item.value === pwa)}
                setValue={(e) => {
                  setPwa(e.value);
                  setValidation(false);
                }}
                actions={() => {}}
              />
            </label>
            <div className={css.checkerContainer}>
              <div className={css.checkboxContainer}>
                <span style={{display: 'inline-block', marginBottom: '8px'}}>
                  Копировать Push уведомления
                </span>
                <label htmlFor='checkbox1' className={css.switch}>
                  <input
                    type='checkbox'
                    id='checkbox1'
                    checked={addPushes}
                    onChange={(e) => setAddPushes(e.target.checked)}
                  />
                </label>
              </div>
              <div className={css.checkboxContainer}>
                <span style={{display: 'inline-block', marginBottom: '8px'}}>Копировать оферы</span>
                <label htmlFor='checkbox2' className={css.switch}>
                  <input
                    type='checkbox'
                    id='checkbox2'
                    checked={addOffers}
                    onChange={(e) => setAddOffers(e.target.checked)}
                  />
                </label>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={css.next}>
        <button className={css.blueButton} style={{minWidth: '130px'}} onClick={nextStep}>
          Продолжить
        </button>
      </div>
    </>
  );
};

export default observer(CopyForm);
