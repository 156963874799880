import React, {useState, useEffect} from 'react';
import AddBlogger from '../../../influence/bloggers/list/AddBlogger';
import {Button} from '../../../../../shared/Buttons';
import {CreateAppModal} from '../../../../../../_metronic/partials';
import {TrekerButton} from '../../../influence/bloggers/source/Treker/TrekerButton';

const AddTraffic = ({getBloggersList, type}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
      <Button
        action={() => {
          setShowCreateAppModal(true);
          setSave(false);
        }}
      >
        <>
          <i className='ki-duotone ki-plus fs-2'></i>Добавить
        </>
      </Button>
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={type === 'facebook' ? 'Добавление Кампании' : 'Добавление блогера'}
      >
        <AddBlogger
          setWarning={setWarning}
          warning={warning}
          setSave={setSave}
          save={save}
          show={setShowCreateAppModal}
          getBloggersList={getBloggersList}
          isUpdate={false}
          type={type}
        />
      </CreateAppModal>
    </>
  );
};

export default AddTraffic;
