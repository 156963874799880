/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState} from 'react';
import root from 'react-shadow';
import classNames from 'classnames';
import numeral from 'numeral';
import moment from 'moment';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import info from './other/info.svg';
import age18 from './other/age18.webp';
import done from './other/done.svg';
import arrow_forward from './other/arrow_forward.svg';
import icon_share from './other/icon-share.png';
import icon_cloud from './other/icon-cloud.png';
import star_blue_full from './other/star-blue-full.png';
import star_blue_half from './other/star-blue-half.png';
import dots from './other/dots.png';
import arrow_down from './other/arrow_down.svg';
import arrow_up from './other/arrow_up.svg';
import public_image from './other/public.svg';
import arrow_left from './other/arrow_left.svg';
import arrow_right from './other/arrow_right.svg';
import mail from './other/mail.svg';
import location from './other/location.svg';
import shield from './other/shield.svg';
import arrow_back from './other/arrow_back.svg';

const GoogleplayApp = ({data, currentLang, id}) => {
  useEffect(() => {
    const blockRtl = document.querySelector('.blockRtl');

    if (data[currentLang].rtl) {
      blockRtl.setAttribute('dir', 'rtl');
    } else {
      blockRtl.removeAttribute('dir', 'rtl');
    }
  }, [data]);

  return (
    <>
      <div className={'blockRtl'}>
        <root.div>
          <div className='body'>
            <div className='layout'>
              <div className='container'>
                <div className='header'>
                  <div className='header_top'>
                    <div
                      className='head_img'
                      id='app_logo_large'
                      style={
                        !!data[currentLang]?.appIcon
                          ? {
                              backgroundImage: `url(https://api.raulnk.com/${id}/images/icons/${currentLang}/${data[currentLang]?.appIcon})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                            }
                          : {
                              backgroundImage: `url(${imagePlug})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                            }
                      }
                    />

                    <div>
                      <h1 id='appName'>{data[currentLang]?.appName || 'App name'}</h1>

                      <div id='noinstall_subtitle' className='header_links'>
                        <span>Google</span>
                        <br />
                        <p id='headerContains'>
                          {data[currentLang]?.inscriptions?.contains_1} &middot;{' '}
                          {data[currentLang]?.inscriptions?.contains_2}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id='statistics_view' className='mob_stats'>
                    <div className='inner'>
                      <div className='mob_stat'>
                        <span className='app_comp__mobile_info_rating'>
                          {data[currentLang]?.ratingsCount || 0}
                          <svg
                            enableBackground='new 0 0 24 24'
                            version='1.1'
                            viewBox='0 0 24 24'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <g fill='none'>
                              <path d='M0,0h24v24H0V0z'></path>
                              <path d='M0,0h24v24H0V0z'></path>
                            </g>
                            <path d='M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z'></path>
                          </svg>
                        </span>
                        <p>
                          <span id='headerReviews'>
                            <span style={{textTransform: 'uppercase'}}>
                              {numeral(data[currentLang]?.reviews || 0).format('0a')}{' '}
                            </span>
                            {data[currentLang]?.inscriptions?.reviews}
                          </span>
                          <img src={info} alt='#' style={{width: '12px', marginLeft: '3px'}} />
                        </p>
                      </div>
                      <div className='mob_stat'>
                        <span className='app_comp__mobile_info_icon'>
                          <svg viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' width='20'>
                            <g transform='translate(21.552 22.5) rotate(180)'>
                              <path
                                transform='translate(7.552 7.652)'
                                d='M.625,0h8.75A.68.68,0,0,1,10,.723a.68.68,0,0,1-.625.723H.625A.68.68,0,0,1,0,.723.68.68,0,0,1,.625,0Z'
                                data-name='Path 288'
                              ></path>
                              <g transform='translate(17.552 20.797) rotate(180)'>
                                <path
                                  d='M0,0H9.666V9.666H0Z'
                                  fill='none'
                                  data-name='Path 289'
                                ></path>
                                <path
                                  transform='translate(-4.408 -3.203)'
                                  d='M8.752,4.642V11.81L5.536,8.678a.677.677,0,0,0-.936,0,.627.627,0,0,0,0,.9l4.343,4.229a.669.669,0,0,0,.929,0l4.343-4.229a.627.627,0,0,0,0-.9.669.669,0,0,0-.929,0L10.07,11.81V4.642a.659.659,0,0,0-1.318,0Z'
                                  data-name='Path 290'
                                ></path>
                              </g>
                              <rect
                                transform='translate(4.552 5.5)'
                                width='16'
                                height='16'
                                rx='2'
                                fill='none'
                                stroke='#000'
                                strokeWidth='2'
                                data-name='Rectangle 123'
                              ></rect>
                            </g>
                          </svg>
                        </span>
                        <p>{data[currentLang]?.appSize} MB</p>
                      </div>
                      <div className='mob_stat'>
                        <img src={age18} alt='' style={{width: '23px', marginBottom: '3px'}} />
                        <p>
                          <span id='headerRated'>{data[currentLang]?.inscriptions?.ageRating}</span>
                          <img src={info} alt='#' style={{width: '12px', marginLeft: '3px'}} />
                        </p>
                      </div>
                      <div className='mob_stat'>
                        <span
                          className='app_comp__mobile_info_rating'
                          style={{textTransform: 'uppercase'}}
                        >
                          {numeral(data[currentLang]?.inscriptions?.countDownloads).format('0a')}+
                        </span>
                        <p id='headerDownloads'>{data[currentLang]?.inscriptions?.downloads}</p>
                      </div>
                    </div>
                  </div>

                  <button
                    style={{
                      fontFamily: 'Google Sans, Roboto, Arial, sans-serif',
                      fontWeight: '500',
                      backgroundColor: !!data[currentLang]?.buttonColor
                        ? data[currentLang]?.buttonColor
                        : '',
                    }}
                    type='button'
                    id='install_button'
                    className='main_btn'
                  >
                    {data[currentLang]?.buttons?.install}
                  </button>
                </div>
                <div className='line_sections'></div>
                <div className='current_platform'>
                  <div className='current_platform_platform'>
                    <img src={done} alt='#' />
                    <span id='btnDevice'>{data[currentLang]?.inscriptions?.phone || 'Device'}</span>
                  </div>
                </div>
                <div className='info'>
                  <div className='Gallery_gallery__W86p0'>
                    <div className='Gallery_galleryContainer__p_ic' id='gallery'>
                      {!!data[currentLang]?.slides?.length && (
                        <>
                          {data[currentLang]?.slides?.map((item) => {
                            return (
                              <div
                                key={item}
                                style={{
                                  minWidth: '166px',
                                  backgroundImage: `url(https://api.raulnk.com/${id}/images/slides/${currentLang}/${item})`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                  backgroundSize: 'cover',
                                }}
                                className='Gallery_galleryContainer__image__QTM3U image'
                              />
                            );
                          })}
                        </>
                      )}
                      {!data[currentLang]?.slides?.length && (
                        <>
                          <div
                            style={{
                              minWidth: '166px',
                              backgroundImage: `url(${imagePlug})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              backgroundSize: 'contain',
                            }}
                            className='Gallery_galleryContainer__image__QTM3U image'
                          />
                          <div
                            style={{
                              minWidth: '166px',
                              backgroundImage: `url(${imagePlug})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              backgroundSize: 'contain',
                            }}
                            className='Gallery_galleryContainer__image__QTM3U image'
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className='discrip'>
                    <div
                      className='discrip_head'
                      style={data[currentLang]?.rtl ? {justifyContent: 'space-between'} : {}}
                    >
                      <h3 id='aboutAppTitle' style={data[currentLang]?.rtl ? {margin: '0'} : {}}>
                        {data[currentLang]?.titles?.aboutThisApp || 'About this app'}
                      </h3>
                      <img
                        src={arrow_forward}
                        alt='#'
                        style={
                          data[currentLang]?.rtl
                            ? {
                                width: '24px',
                                margin: '0',
                                height: '24px',
                                transform: 'rotateY(180deg)',
                              }
                            : {
                                width: '24px',
                                height: '24px',
                              }
                        }
                      />
                    </div>
                    <div className='text'>
                      <p
                        id='aboutAppText'
                        style={data[currentLang].rtl ? {textAlign: 'right'} : {}}
                        dangerouslySetInnerHTML={{
                          __html: data[currentLang]?.description?.replace(/\n/g, '<br>'),
                        }}
                      ></p>
                    </div>
                  </div>
                  <div className='discrip'>
                    <div
                      className='discrip_head'
                      style={data[currentLang]?.rtl ? {justifyContent: 'space-between'} : {}}
                    >
                      <h3 id='dataSfetyTitle' style={data[currentLang]?.rtl ? {margin: '0'} : {}}>
                        {data[currentLang]?.titles?.dataSafety || 'Data safety'}
                      </h3>
                      <img
                        src={arrow_forward}
                        alt='#'
                        style={
                          data[currentLang]?.rtl
                            ? {
                                width: '24px',
                                margin: '0',
                                height: '24px',
                                transform: 'rotateY(180deg)',
                              }
                            : {
                                width: '24px',
                                height: '24px',
                              }
                        }
                      />
                    </div>
                    <div className='text'>
                      <p
                        id='dataSfetyText'
                        style={data[currentLang].rtl ? {textAlign: 'right'} : {}}
                      >
                        {data[currentLang]?.texts?.dataSafety ||
                          'Lorem ipsum dolor sit amet consectetur adipisicing elit. '}
                      </p>
                    </div>
                    <div className='frame'>
                      <div className='frame_row'>
                        <img
                          src={icon_share}
                          style={
                            data[currentLang].rtl ? {marginLeft: '40px', marginRight: '0'} : {}
                          }
                        />
                        <div className='frame_text'>
                          <h4 id='frameTitle1'>
                            {data[currentLang]?.inscriptions?.dataSafety_title_1 ||
                              'Lorem ipsum dolor '}
                          </h4>
                          <p
                            id='frameText1'
                            style={data[currentLang].rtl ? {textAlign: 'right'} : {}}
                          >
                            <span>
                              {data[currentLang]?.inscriptions?.dataSafety_text_1 ||
                                'Lorem ipsum dolor sit amet consectetur adipisicing elit. '}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className='frame_row'>
                        <img
                          src={icon_cloud}
                          style={
                            data[currentLang].rtl ? {marginLeft: '40px', marginRight: '0'} : {}
                          }
                        />
                        <div className='frame_text'>
                          <h4 id='frameTitle2'>
                            {data[currentLang]?.inscriptions?.dataSafety_title_2 ||
                              'Lorem ipsum dolor '}
                          </h4>
                          <p
                            id='frameText2'
                            style={data[currentLang].rtl ? {textAlign: 'right'} : {}}
                          >
                            <span>
                              {data[currentLang]?.inscriptions?.dataSafety_text_2 ||
                                'Lorem ipsum dolor sit amet consectetur adipisicing elit. '}
                            </span>
                          </p>
                        </div>
                      </div>
                      <a href='#' className='btn_link' id='seeDetails'>
                        {data[currentLang]?.inscriptions?.seeDetails}
                      </a>
                    </div>
                  </div>
                  <div className='discrip rating' style={{display: 'block'}}>
                    <div
                      className='discrip_head'
                      style={data[currentLang]?.rtl ? {justifyContent: 'space-between'} : {}}
                    >
                      <h3 id='ratingTitle' style={data[currentLang]?.rtl ? {margin: '0'} : {}}>
                        {data[currentLang]?.titles?.ratings || 'Rating & Reviews'}
                      </h3>
                      <img
                        src={arrow_forward}
                        alt='#'
                        style={
                          data[currentLang]?.rtl
                            ? {
                                width: '24px',
                                margin: '0',
                                height: '24px',
                                transform: 'rotateY(180deg)',
                              }
                            : {
                                width: '24px',
                                height: '24px',
                              }
                        }
                      />
                    </div>
                    <div className='text'>
                      <p id='ratingText' style={data[currentLang].rtl ? {textAlign: 'right'} : {}}>
                        {data[currentLang]?.texts?.ratings}
                      </p>
                    </div>
                    <div className='inner'>
                      <div className='left'>
                        <div className='total_rating'>{data[currentLang]?.ratingsCount}</div>
                        <div className='stars'>
                          {data[currentLang]?.ratingsCount >= 1 && <img src={star_blue_full} />}
                          {data[currentLang]?.ratingsCount >= 2 && <img src={star_blue_full} />}
                          {data[currentLang]?.ratingsCount >= 3 && <img src={star_blue_full} />}
                          {data[currentLang]?.ratingsCount >= 4 && <img src={star_blue_full} />}
                          {data[currentLang]?.ratingsCount >= 4.5 && <img src={star_blue_full} />}
                        </div>
                        <div className='user'>{data[currentLang]?.comments?.reviews}</div>
                      </div>
                      <div className='right'>
                        <div className='chart'>
                          <div
                            className='chart_bar bar1'
                            style={{'--bar1': `${data[currentLang]?.ratings?.a1}%`}}
                          >
                            <p>5</p>
                            <span>
                              <div style={data[currentLang].rtl ? {right: '0'} : {}} />
                            </span>
                          </div>
                          <div
                            className='chart_bar bar2'
                            style={{'--bar2': `${data[currentLang]?.ratings?.a2}%`}}
                          >
                            <p>4</p>
                            <span>
                              <div style={data[currentLang].rtl ? {right: '0'} : {}} />
                            </span>
                          </div>
                          <div
                            className='chart_bar bar3'
                            style={{'--bar3': `${data[currentLang]?.ratings?.a3}%`}}
                          >
                            <p>3</p>
                            <span>
                              <div style={data[currentLang].rtl ? {right: '0'} : {}} />
                            </span>
                          </div>
                          <div
                            className='chart_bar bar4'
                            style={{'--bar4': `${data[currentLang]?.ratings?.a4}%`}}
                          >
                            <p>2</p>
                            <span>
                              <div style={data[currentLang].rtl ? {right: '0'} : {}} />
                            </span>
                          </div>
                          <div
                            className='chart_bar bar5'
                            style={{'--bar5': `${data[currentLang]?.ratings?.a5}%`}}
                          >
                            <p>1</p>
                            <span>
                              <div style={data[currentLang].rtl ? {right: '0'} : {}} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='comments'>
                    {data[currentLang]?.comments?.map((item, index) => {
                      return (
                        <div className='comment' key={index}>
                          <div className='comment_info'>
                            <div className='top'>
                              <div className='user_info'>
                                <div
                                  className={classNames(
                                    'avatar',
                                    data[currentLang].rtl && 'avatarRtl'
                                  )}
                                  style={
                                    !!item.avatar
                                      ? {
                                          backgroundImage: `url(https://api.raulnk.com/${id}/images/users/${currentLang}/${item.avatar})`,
                                          backgroundRepeat: 'no-repeat',
                                          backgroundPosition: 'center',
                                          backgroundSize: 'cover',
                                        }
                                      : {
                                          backgroundImage: `url(${imagePlug})`,
                                          backgroundRepeat: 'no-repeat',
                                          backgroundPosition: 'center',
                                          backgroundSize: 'cover',
                                        }
                                  }
                                ></div>
                                <h4>{item.author}</h4>
                                <img
                                  className='dots'
                                  src={dots}
                                  style={
                                    data[currentLang].rtl
                                      ? {marginRight: 'auto', marginLeft: '0px'}
                                      : {}
                                  }
                                />
                              </div>
                              <div className='user_rating'>
                                <div className='stars'>
                                  {item.rating >= 1 && <img src={star_blue_full} />}
                                  {item.rating >= 2 && <img src={star_blue_full} />}
                                  {item.rating >= 3 && <img src={star_blue_full} />}
                                  {item.rating >= 4 && <img src={star_blue_full} />}
                                  {item.rating === 5 && <img src={star_blue_full} />}
                                </div>
                                <p>{moment(item.date).format('DD/MM/YYYY')}</p>
                              </div>
                            </div>
                            <div
                              className='comment_text'
                              style={data[currentLang].rtl ? {textAlign: 'right'} : {}}
                            >
                              {item.comment}
                            </div>
                            <div
                              className='helpful'
                              style={data[currentLang].rtl ? {textAlign: 'right'} : {}}
                            >
                              {item.likes} {data[currentLang]?.inscriptions?.peopleHelpFull}
                            </div>
                            <div className='helpful_que_container'>
                              <span>{data[currentLang]?.inscriptions?.helpFull}</span>
                              <div className='helpful_que_container_buttons'>
                                <div>{data[currentLang]?.inscriptions?.yes}</div>
                                <div>{data[currentLang]?.inscriptions?.no}</div>
                              </div>
                            </div>
                            <div className='responce'>
                              <div className='responce_header'>
                                <span>{data[currentLang]?.developer}</span>
                                <span>
                                  {moment(new Date()?.setHours(0, 0, 0, 0)).format('DD/MM/YYYY')}
                                </span>
                              </div>
                              <div
                                className='responce_text'
                                style={data[currentLang].rtl ? {textAlign: 'right'} : {}}
                              >
                                {item.response}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className='all_reviews'>
                    <a href='#' className='btn_link' id='allReviews'>
                      {data[currentLang]?.inscriptions?.seeAllReviews}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='Layout_imagePopup__CCOgX'>
              <header className='Layout_imagePopup__header__n7kd7'>
                <button>
                  <img src={arrow_back} alt='#' style={{width: '24px', height: '24px'}} />
                </button>
              </header>
              <section className='Layout_imagePopup___nwj57'>
                <button className='Gallery_buttonPrev__GjkHI Layout_buttonPrev__IU3Mn'>
                  <img src={arrow_left} alt='#' style={{width: '30px', height: '36px'}} />
                </button>
                <img src='#' className='Layout_imagePopup__image' />
                <button className='Gallery_buttonNext__+NwwV Layout_buttonNext__p4gU9'>
                  <img src={arrow_right} alt='#' style={{width: '30px', height: '36px'}} />
                </button>
              </section>
            </div>
          </div>
          <style type='text/css'>{`@font-face {
  font-family: 'Jost';
  src: url('./fonts/GoogleSans-Regular.eot');
  src: local('Google Sans Regular'), local('GoogleSans-Regular'),
    url('./fonts/GoogleSans-Regular.eot') format('embedded-opentype'),
    url('./fonts/GoogleSans-Regular.woff2') format('woff2'),
    url('./fonts/GoogleSans-Regular.woff') format('woff'),
    url('./fonts/GoogleSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.body,
div,
h3,
li,
ol,
p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h3 {
  font-weight: 400;
  font-style: normal;
  font-family: 'Jost', sans-serif;
  color: #071437 !important;
}

.body {
  background-color: #fff;
}

main {
  padding: 20px 0;
  font-size: 18px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.body,
html {
  background-color: #fff;
  color: #1f1f1f;
  height: 100vh;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-family: 'Google Sans', Roboto, Arial, sans-serif;
}

.avatarRtl {
	margin-right: 0;
  margin-left: 10px;
}

.layout {
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: max-content;
  overflow-x: hidden;
}

.container {
  width: 720px;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
  width: 100%;
  max-width: 720px;
}

.header {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
}

.header .head_img {
  border-radius: 22px;
  width: 72px;
  height: 72px;
  margin: 26px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  margin-bottom: 0;
}

.header h1 {
  width: 100%;
  font-size: 28px;
  color: #212121;
  line-height: 35px;
  text-align: left;
  word-break: break-word;
  font-weight: 400;
  margin-top: 17px;
  margin-bottom: 8px;
  font-family: 'Jost', sans-serif;
}

.header_links {
}

.header_top {
  position: relative;
  display: flex;
  width: calc(100vw - 48px);
  margin: auto;
  margin-bottom: 30px;
  margin-left: 20px;
}

.header_links span {
  display: inline-block;
  white-space: nowrap;
  font-size: 15px;
  color: #0b57cf;
  font-weight: 500;
  margin-right: 15px;
  font-family: 'Jost', sans-serif;
}

.header_links span:hover {
  text-decoration: underline;
}

.header_links p {
  font-size: 12px;
  opacity: 0.6;
}

.main_btn {
  padding-top: 2px;
  height: 42px;
  background-color: #0b57cf;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  color: #fff;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 60px;
  cursor: pointer;
  outline: none;
  margin: auto;
  width: calc(100% - 40px);
}

.main_btn:focus,
.main_btn:hover {
}

.header .age {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 13px;
  background-color: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.685);
  font-family: Impact;
  color: rgba(0, 0, 0, 0.685);
  font-size: 8px;
  font-weight: 400;
  line-height: 1;
  margin-top: 9px;
}

.btn-outline {
}

.info {
  padding: 0 15px;
  margin-top: 50px;
}

.gallery {
  display: flex;
  overflow: auto;
  margin-bottom: 10px;
  width: calc(100% - 24px);
  margin-right: 0;
  margin-left: auto;
  overflow-x: auto;
}

.gallery img {
  padding: 5px 10px 5px 0;
  border-radius: 20px;
  max-height: 210px;
}

.discrip {
  position: relative;
  padding: 10px;
  width: calc(100% - 40px);
  margin: 20px auto 0;
}

.discrip_head {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}

[dir="rtl"] > .discrip_head {
	justify-content: space-between;
}

.discrip_head img {
  margin-right: 0;
  margin-left: auto;
  height: 14px;
}

[dir="rtl"] .discrip_head img {
	margin: 0;
	height: 14px;
  transform: rotateY(180deg);
}

.discrip_head h3 {
  margin-left: 0;
  margin-right: auto;
  font-family: 'Jost', sans-serif;
  font-size: 21px;
}

[dir="rtl"] .discrip_head h3 {
	margin: 0;
}

.discrip p {
  text-align: left;
  font-size: 14px;
  line-height: 1.4;
  opacity: 0.7;
}

[dir="rtl"] .discrip p {
	text-align: right;
}

.discrip .text {
  position: relative;
}

.btn-outline {
  background: none;
  border: none;
  color: #3a3a3a;
  opacity: 1;
  font-size: 14px;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  text-align: center;
  width: auto;
  z-index: 999;
  text-decoration: none;
  padding: 8px 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: inline-block;
  margin-top: 20px;
  line-height: 18px;
  letter-spacing: 0.02rem;
}

.rating {
  padding: 0 25px 25px;
  text-align: left;
}

.inner {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 22px 0px 0 0;
}

.rating h3 {
}

.total_rating {
  color: #333;
  font-size: 64px;
  font-weight: 100;
  line-height: 64px;
}

.user {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 13px;
  line-height: 27px;
  opacity: 0.4;
}

.user span {
}

.left {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-right: 20px; */
  min-width: 100px;
}

.right {
  max-width: 480px;
  width: 100%;
}

.chart {
}

.chart_bar {
}

.chart_bar > p {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  margin-right: 5px;
  height: 20px;
  color: #737373;
  font-size: 12px;
}

.chart_bar > span {
  height: 20px;
  min-width: 10px;
  display: inline-block;
  position: relative;
}

.chart .chart_bar {
  position: relative;
  display: flex;
  height: 10px;
  margin-bottom: 9px;
  font-size: 12px;
}

.chart .chart_bar > span {
  width: 100%;
  height: 100%;
  background: rgb(232, 234, 237);
  border-radius: 10px;
  margin: 3px 0;
}

.chart .chart_bar > span > div {
  content: '';
  background: #0b57cf;
  height: 100%;
  position: absolute;
  right: var(--positionLine);
  transform: translateX(var(--translateLine));
  border-radius: 100px;
}

[dir="rtl"] .chart .chart_bar > span::after {
	right: 0;
}

.chart_bar.bar1 > span > div {
  content: '';
  width: var(--bar1);
}

.chart_bar.bar2 > span > div {
  content: '';
  width: var(--bar2);
}

.chart_bar.bar3 > span > div {
  content: '';
  width: var(--bar3);
}

.chart_bar.bar4 > span > div {
  content: '';
  width: var(--bar4);
}

.chart_bar.bar5 > span > div {
  content: '';
  width: var(--bar5);
}

.comments {
  text-align: center;
  width: calc(100% - 40px);
  margin: 40px auto 0;
}

.comment {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0;
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 25px 25px;
  text-align: left;
}

.comment .avatar {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background-size: contain;
  flex-shrink: 0;
  margin-right: 10px;
}

[dir="rtl"] .comment .avatar {
	margin-right: 0;
  margin-left: 10px;
}

.comment .avatar img {
  width: 32px;
  height: 32px;
}

.comment_info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 0;
  justify-content: space-between;
}

.user_info {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.user_info h4 {
  color: #4c4c4c;
  font-size: 15px;
  font-weight: 400;
}

.user_info p {
  color: #aaa;
  font-size: 12px;
  font-weight: 400;
  margin-left: 0px;
}

.user_rating {
  display: flex;
}

.user_rating .stars {
}

.user_rating p {
  stop-color: rgb(95, 99, 104);
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 1rem;
  margin-left: 0;
  opacity: 0.7;
}

.comment-info .top {
  position: relative;
  display: flex;
  flex-direction: column;
}

.comment-info .stars {
  padding: 0;
  min-width: 80px;
}

.comment-info .stars img {
  width: 12px;
  height: 12px;
  margin: 0 1px;
}

.comment_text {
  position: relative;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
  margin-top: 10px;
  opacity: 0.7;
}

.responce {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px 15px;
  margin-top: 10px;
}

.responce_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.responce_header > span:first-child {
  font-size: 14px;
  opacity: 0.7;
}
.responce_header > span:last-child {
  font-size: 14px;
  opacity: 0.6;
}

.responce_text {
  font-size: 14px;
  opacity: 0.7;
}

.helpful {
  font-size: 12px;
  opacity: 0.7;
  margin-top: 10px;
}

.helpful_que_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.helpful_que_container span {
  font-size: 12px;
  opacity: 0.7;
}

.helpful_que_container_buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.helpful_que_container_buttons div {
  padding: 5px 15px;
  border: 1px solid black;
  border-radius: 7px;
  font-size: 14px;
  opacity: 0.7;
}

.comment-userlikes {
  font-size: 12px;
  opacity: 0.7;
  margin-top: 20px;
}

.comment-bottom {
  font-size: 12px;
  opacity: 0.7;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comment-bottom p {
}

.like-btns {
  display: f;
}

.like-btns span {
  background: none;
  border: none;
  color: #3a3a3a;
  opacity: 1;
  font-size: 14px;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  text-align: center;
  width: auto;
  z-index: 999;
  text-decoration: none;
  padding: 9px 19px;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: inline-block;
  margin-left: 10px;
  line-height: 16px;
  letter-spacing: 0.02rem;
}

.mob_stats {
  display: flex;
  justify-content: flex-start;
  min-width: 100%;
  width: 100%;
  height: 60px;
  overflow-x: auto;
  margin-bottom: 10px;
}

.app_comp__mobile_info_rating svg {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  fill: #030303;
}

.mob_stat {
  align-items: center;
  width: 37%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 120px;
  height: 35px;
  border-right: 1px solid #ddd;
  padding-top: 1px;
  text-align: center;
  height: 40px;
}

.mob_stat:last-of-type {
  border: 0;
  width: 28%;
}

.mob_stat > span {
  display: flex;
  align-items: center;
  height: 20px;
  font-weight: 500;
  font-size: 17px;
  color: #030303;
}

.mob_stat p {
  display: flex;
  align-items: center;
  height: 20px;
  font-weight: 400;
  font-size: 12px;
  /* color: #898989; */
  opacity: 0.8;
  padding-top: 5px;
}

.age-res {
  font-size: 10px !important;
  width: 17px !important;
  height: 17px !important;
  border: 1px solid #57bb8a;
  border-radius: 100px;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #57bb8a !important;
}

.rating {
  padding: 0 0 20px;
}

.comment {
  padding: 0 0 20px;
}

.line {
  width: 100%;
}

.total_rating {
  color: #333;
  font-size: 54px;
  font-weight: 400;
  line-height: 1.2;
  font-family: 'Jost', sans-serif;
}

.mob_stats > .inner {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  position: absolute;
}

.frame {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 8px;
  border: 1px solid rgb(211 211 211);
  margin-top: 30px;
  padding: 20px 20px 20px;
}

.frame_row {
  display: flex;
  margin-bottom: 13px;
}

.frame_row img {
  width: 20px;
  height: 20px;
  margin-right: 40px;
}

[dir="rtl"] .frame_row img {
	margin-right: 0;
	margin-left: 20px;
}

.frame_text {
}

.btn_link {
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  color: #0b57cf;
  font-weight: 500;
  margin-right: 15px;
  text-decoration: none;
  margin-top: 10px;
}

.frame_text h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 4px;
  opacity: 0.7;
}

.frame_text p {
  font-size: 12px;
  line-height: 16px;
}

.frame_text span {
  text-decoration: underline;
}

.stars {
  display: flex;
}

.stars img {
  width: 13px;
  margin: auto 2px;
}

.chart_bar {
}

.dots {
  width: 30px;
  margin-right: 0;
  margin-left: auto;
}

[dir="rtl"] .dots {
  margin-right: auto;
	margin-left: 0px;
}

@media (max-width: 840px) {
  .recommended {
    display: none;
  }

  .container {
    width: 100%;
    margin-left: 0;
    padding: 40px 0 30px;
    box-shadow: none;
  }

  .header .head_img {
    overflow: hidden;
    border-radius: 20%;
    width: 72px;
    height: 72px;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .info {
    padding: 0;
    margin: auto;
  }

  .header h1 {
    max-width: 240px;
    font-size: 25px;
    line-height: 32px;
    padding: 0;
    margin: 0;
    font-weight: 400;
  }

  .layout {
    padding-top: 0;
  }

  .main_btn {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: block;
  }

  .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .discrip {
    padding: 0;
  }

  .header_top {
  }

  .bottom {
    padding: 0 0 20px;
  }

  .footer {
    padding: 0;
  }

  .comments {
  }
}

.btns {
  display: flex;
  padding: 0 24px;
  justify-content: space-between;
}

.btn-secondary {
  height: 42px;
  background-color: #ffffff;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  color: #5985ca;
  border: none;
  border: 1px solid #cdcdcd;
  border-radius: 60px;
  cursor: pointer;
  outline: none;
  margin: auto;
  width: 100%;
}

.btns .btn-secondary {
  width: 48%;
  margin: 0 auto 0 0;
}

.btn-secondary.cancel {
  /* color: #acacac; */
}

.btns .main_btn {
  width: 48%;
  margin: 0 0 0 auto;
}

.main_btn.disabled {
  background: #e6e6e6;
  color: #b7b7b7;
}

.header .img-download {
  width: 72px;
  height: 72px;
  display: flex;
  margin-right: 20px;
  margin-bottom: -0;
}

.header .img-download img {
  width: 32px;
  height: 32px;
  margin: auto;
  padding: 0;
}

.img-loading-circle-svg {
  position: absolute;
  width: 72px;
  height: 72px;
  transform: rotate(-90deg);
  stroke-dasharray: 220;
  stroke-dashoffset: 220;
  transition: stroke-dashoffset 0.2s linear;
}

.header .img-loading {
  width: 72px;
  height: 72px;
  display: flex;
  margin-right: 20px;
  margin-bottom: -0;
}

.header .img-loading img {
  width: 32px;
  height: 32px;
  margin: auto;
  padding: 0;
}

.img-loading-circle {
  position: absolute;
  width: 72px;
  height: 72px;
}

.img-loading::after {
  content: '';
  position: absolute;
  width: 72px;
  height: 72px;
  border-radius: 100px;
  border-left: 0;
  border-bottom: 0;
  animation: spinner-border 1.75s linear infinite;
  opacity: 0.75;
  /* background-image: url(../../../../../../public/imagesForTemplatePwas/googleplay-app/loader.png); */
  background-size: 100%;
  background-repeat: no-repeat;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.header-loding {
}

.header-loding h3 {
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
}

.header-loding p {
  line-height: 1;
}

.header-loding p img {
  width: 10px;
  margin-right: 5px;
}

.header-loding p span {
  font-size: 12px;
  opacity: 0.7;
}

.Gallery_gallery__W86p0 {
  position: relative;
  /* padding: 24px; */
  width: calc(100% - 40px);
  margin: 20px auto 0px;
}

.Gallery_buttonPrev__GjkHI,
.Gallery_buttonNext__\+NwwV {
  position: absolute;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  color: rgb(95, 99, 104);
  font-size: 24px;
  fill: currentColor;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  overflow: visible;
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms,
    transform 0.27s 0ms cubic-bezier(0, 0, 0.2, 1);
}
.Gallery_buttonPrev__GjkHI:hover,
.Gallery_buttonNext__\+NwwV:hover {
  background-color: rgb(242, 242, 242);
}
@media (max-width: 600px) {
  .Gallery_buttonPrev__GjkHI,
  .Gallery_buttonNext__\+NwwV {
    display: none;
  }
}
.Gallery_buttonPrev__GjkHI > span,
.Gallery_buttonNext__\+NwwV > span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 200;
  width: 36px;
  height: 36px;
}

.Gallery_buttonPrev__GjkHI {
  left: 0px;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.Gallery_buttonNext__\+NwwV {
  right: 0px;
  top: 50%;
  transform: translateX(50%) translateY(-50%);
}

.Gallery_galleryContainer__p_ic {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}
@media screen and (min-width: 600px) {
  .Gallery_galleryContainer__p_ic {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1280px) {
  .Gallery_galleryContainer__p_ic {
    margin-bottom: 24px;
  }
}
.Gallery_galleryContainer__image__QTM3U {
  display: block;
  border-width: 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  border-radius: 8px;
  cursor: pointer;
  height: 204px;
  margin-right: 12px;
  scroll-snap-align: start;
}
.Gallery_galleryContainer__image__QTM3U:last-child {
  margin-right: 0px;
}
@media screen and (min-width: 1280px) {
  .Gallery_galleryContainer__image__QTM3U {
    margin-right: 16px;
    height: 296px;
  }
}

.Layout_imagePopup__CCOgX {
  display: none;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0.15s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5000;
  transition: opacity 0.05s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.openImage {
  display: flex;
}

.Layout_imagePopup__header__n7kd7 {
  align-items: center;
  display: flex;
  height: 64px;
  background-color: #000;
  width: 100%;
}

.Layout_imagePopup__header__n7kd7 button {
  background-color: transparent;
  border: none;
  outline: none;
  width: 48px;
  height: 48px;
  margin-left: 16px;
  color: #e8eaed;
  cursor: pointer;
}

.Layout_imagePopup__body__nwj57 {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Gallery_buttonNext__\+NwwV {
  right: 0px;
  top: 50%;
  transform: translateX(50%) translateY(-50%);
}

.Gallery_buttonPrev__GjkHI,
.Gallery_buttonNext__\+NwwV {
  position: absolute;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  color: rgb(95, 99, 104);
  font-size: 24px;
  fill: currentColor;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  overflow: visible;
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms,
    transform 0.27s 0ms cubic-bezier(0, 0, 0.2, 1);
}

@media (max-width: 600px) {
  .Gallery_buttonPrev__GjkHI,
  .Gallery_buttonNext__\+NwwV {
    display: none;
  }
}

.Layout_buttonNext__p4gU9 {
  position: absolute !important;
  right: 32px !important;
  width: 48px !important;
  height: 48px !important;
  transform: translateX(0%) translateY(-50%) !important;
  display: flex;
  z-index: 20 !important;
}

.Layout_buttonPrev__IU3Mn {
  position: absolute !important;
  left: 32px !important;
  width: 48px !important;
  height: 48px !important;
  transform: translateX(0%) translateY(-50%) !important;
  display: flex;
  z-index: 20 !important;
}

.Gallery_buttonNext__\+NwwV > span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 200;
  width: 36px;
  height: 36px;
}

.Layout_imagePopup___nwj57 {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Layout_imagePopup__image {
  max-height: 90%;
  max-width: 80%;
  object-fit: contain;
  z-index: 10;
  background-size: contain;
  width: 100%;
}

.line_sections {
  width: 100%;
  height: 1px;
  background-color: #c7c7c7;
}

@media (min-width: 840px) {
  .line_sections {
    display: none;
  }
}

.current_platform {
  width: calc(100% - 40px);
  margin: 20px auto 0;
  display: flex;
  justify-content: flex-start;
}

.current_platform_platform {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #c4e2f6;
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 7px;
}

.all_reviews {
  width: calc(100% - 40px);
  margin: 10px auto 0;
}

.support {
  container-type: inline-size;
  container-name: section-wrapper;
  padding-top: 20px;
  width: 100%;
  width: calc(100% - 40px);
  margin: 10px auto 40px;
}
@media screen and (min-width: 600px) {
  .support {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1280px) {
  .support {
    margin-bottom: 24px;
  }
}

.support .blockHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.support .blockHeader div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.support .blockHeader h3 {
  margin-left: 0;
  margin-right: auto;
  font-family: 'Jost', sans-serif;
  font-size: 21px;
}

#openSupport {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.support__list {
  display: none;
  gap: 24px;
  color: rgb(95, 99, 104);
  fill: rgb(95, 99, 104);
  stop-color: rgb(95, 99, 104);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0142857143em;
  line-height: 1.25rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin-top: 20px;
}

#openSupport:checked ~ .support__list {
  display: grid;
}

#openSupport:checked ~ .blockHeader #labelSupport img:first-child {
  display: none;
}

.blockHeader #labelSupport img:last-child {
  display: none;
}

#openSupport:checked ~ .blockHeader #labelSupport img:last-child {
  display: block;
}

.support__item {
  border-radius: 8px;
  display: flex;
  padding: 12px;
  margin: -12px;
  cursor: pointer;
}
.support__item:hover {
  background-color: rgb(242, 242, 242);
}
.support__item span {
  color: rgb(95, 99, 104);
  font-size: 18px;
  margin-right: 16px;
}
.support__item__titleWrapper {
  display: flex;
  flex-direction: column;
}
.support__item__title {
  font-family: 'GSMedium', sans-serif;
  font-size: 14px;
  color: rgb(32, 33, 36);
}
.support__item__subTitle {
  color: rgb(95, 99, 104);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
}
.games {
  container-type: inline-size;
  container-name: section-wrapper;
  padding-top: 20px;
  width: 100%;
}
@media screen and (min-width: 600px) {
  .games {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1280px) {
  .games {
    margin-bottom: 24px;
  }
}
.games__list {
  display: flex;
  grid-gap: 10px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

.games__itemWrapper {
  scroll-snap-align: start;
}
.games__item {
}
@media screen and (min-width: 1280px) {
  .games__item {
    margin: 16px 12px;
  }
}
.games__item img {
  border-radius: 12px;
  border-width: 0;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  height: 98px;
  width: 98px;
  background-size: contain;
}

.games__item__info {
  display: grid;
  grid-template-rows: max-content;
  height: -moz-fit-content;
  height: fit-content;
  row-gap: 2px;
}
@media screen and (min-width: 1280px) {
  .games__item__info {
    row-gap: 0;
  }
}
.games__item__textContainer {
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  color: rgb(32, 33, 36);
}
.games__item__subTitle {
  font-size: 14px;
}
.games__item__raiting {
  display: flex;
  align-items: center;
  color: rgb(95, 99, 104);
  font-size: 14px;
}

.similarGames {
  width: calc(100% - 40px);
  margin: 10px auto 0;
}

.similarGames_container {
  width: 100%;
}

/* comments popup */

.commentsPopup {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
}

.loaderContainer {
  position: relative;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #0b57cf;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* pwa */

.loaderContainer {
  position: relative;
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #0b57cf;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Play_banner__3uFUZ {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: black;
  /* background-image: url(../../public/images/pwa/1920x1080.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 50px 15px;
}
@media (max-width: 1024px) {
  .Play_banner__3uFUZ {
    /* background-image: url(../../public/images/pwa/1024x1366.png); */
    padding: 90px 15px 50px 15px;
  }
}
@media (max-width: 830px) {
  .Play_banner__3uFUZ {
    padding: 150px 15px 50px 15px;
  }
}
@media (max-width: 600px) {
  .Play_banner__3uFUZ {
    padding: 100px 15px 110px 15px;
    /* background-image: url(../../public/images/pwa/370x812.png); */
  }
}`}</style>
        </root.div>
      </div>
    </>
  );
};

export default GoogleplayApp;
