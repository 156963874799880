/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import FilterWithGroupWithoutSelectedOptions from '../shared/filters/FilterWithGroupWithoutSelectedOptions';

const FilterOfFilters = ({
  placeholder,
  classButton,
  tabIndex,
  filtersGroupOptions,
  addSelectedOption,
  mode,
}) => {
  return (
    <>
      <FilterWithGroupWithoutSelectedOptions
        index={tabIndex}
        classButton={classButton}
        title={placeholder}
        options={filtersGroupOptions}
        apply={addSelectedOption}
      />
    </>
  );
};

export default FilterOfFilters;
