/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import {CreateAppModal} from '../../../../../../_metronic/partials';
import Warning from '../../../../../modules/Warning';
import {Selector} from '../../../../../shared/Selectors';
import {Button} from '../../../../../shared/Buttons';

const AddSourceWithoutTrafficId = ({getInfluence = () => {}, romiList, timeConfig}) => {
  const store = useStore();
  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
    reset,
    watch,
  } = useForm();

  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  const [optionsBayers, setOptionsBayers] = useState([]);
  const [optionsBots, setOptionsBots] = useState([]);
  const [optionsChannels, setOptionsChannels] = useState([]);
  const [optionsPwas, setOptionsPwas] = useState([]);
  const [optionsTraffics, setOptionsTraffics] = useState([]);

  const watchType = watch('type');

  useEffect(() => {
    if (showCreateAppModal) {
      (async () => {
        try {
          // Получаем список трафиков
          const traffics = await store.trafficsFacebookGetCompaniesList();
          const trafficOptions = traffics.map((t) => ({
            label: t.name ? `#${t.id} ${t.name}` : `#${t.id}`,
            value: t.id,
          }));
          setOptionsTraffics(trafficOptions);

          // Получаем остальных
          const getBots = await store.botsGetListBots();
          const getBayers = await store.trafficsFacebookGetBayersList();
          const getPwas = await store.resourcePWAgetApps({
            time_config: 'current_month',
            time_start: '',
            time_end: '',
          });

          const newBots = getBots.map((el) => ({
            label: el.name,
            value: el.id,
          }));

          const newBayers = getBayers.map((el) => ({
            label: `${el.first_name} ${el.last_name}`,
            value: el.id,
          }));

          const newPwas = getPwas.map((el) => ({
            label: `#${el.id} ${el.name}`,
            value: el.id,
          }));

          setOptionsBots(newBots);
          setOptionsBayers(newBayers);
          setOptionsPwas(newPwas);
        } catch (error) {
          console.error('Ошибка при загрузке данных:', error);
          toast.error('Ошибка при загрузке данных');
        }
      })();
    }
  }, [showCreateAppModal]);

  // При выборе бота очищаем каналы и подгружаем заново
  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name === 'bot_id') {
        setOptionsChannels([]);
        getChannels(value.bot_id);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const getChannels = async (bot_id) => {
    if (!bot_id) return;
    const getChannels = await store.channelsGetChannelsList('main', bot_id);
    const newChannels = getChannels.map((el) => ({
      label: el.name,
      value: el.id,
    }));
    setOptionsChannels(newChannels);
  };

  const onSubmit = async (data) => {
    const newData = {...data};

    // Удаляем из объекта то, что не нужно для конкретного типа
    if (data.type === 'telegram') {
      delete newData.pwa_id;
    } else {
      delete newData.channel_id;
      delete newData.bot_id;
    }

    const result = await store.trafficsFacebookLinksAddLink(newData);

    notify(!!result, !!result ? 'Успешно сохранено!' : 'Произошла ошибка!');
    reset();
    setSave(true);
    // Обновляем данные
    if (getInfluence) {
      getInfluence(romiList, timeConfig);
    }
    setShowCreateAppModal(false);
  };

  const notify = (action, message) => {
    if (!action) {
      toast.error(`${message}`, {
        theme: 'colored',
      });
    } else {
      toast.success(`${message}`);
    }
  };

  return (
    <>
      <Button action={() => setShowCreateAppModal(true)}>
      <>
          <i className='ki-duotone ki-plus fs-2'></i>Добавить
        </>
      </Button>
      <CreateAppModal
        customWidth='mw-550px'
        padding=''
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Добавление сорса'}
      >
        <>
          <form
            style={{display: 'flex', flexDirection: 'column'}}
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* Выбор трафика */}
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='fw-semibold fs-6 mb-2'>Кампании</label>
              <Controller
                name='traffic_id'
                control={control}
                rules={{required: true}}
                render={({field: {onChange, value}}) => (
                  <Selector
                    options={optionsTraffics}
                    value={value}
                    onChange={onChange}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: '42px',
                      }),
                    }}
                  />
                )}
              />
              {errors?.traffic_id?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div>

            {/* Название */}
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='fw-semibold fs-6 mb-2'>Название</label>
              <input
                type='text'
                className='form-control form-control-solid mb-3 mb-lg-0'
                placeholder=''
                {...register('name', {required: true})}
              />
              {errors?.name?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div>

            {/* Байер */}
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='fw-semibold fs-6 mb-2'>Байер</label>
              <Controller
                name='bayer_id'
                control={control}
                rules={{required: true}}
                render={({field: {onChange, value}}) => (
                  <Selector
                    options={optionsBayers}
                    value={value}
                    onChange={onChange}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: '42px',
                      }),
                    }}
                  />
                )}
              />
              {errors?.bayer_id?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div>

            {/* Тип */}
            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='fw-semibold fs-6 mb-2'>Тип</label>
              <Controller
                name='type'
                control={control}
                rules={{required: true}}
                render={({field: {onChange, value}}) => (
                  <Selector
                    options={[
                      {value: 'telegram', label: 'telegram'},
                      {value: 'pwa', label: 'PWA'},
                    ]}
                    value={value}
                    onChange={onChange}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: '42px',
                      }),
                    }}
                  />
                )}
              />
              {errors?.type?.type === 'required' && (
                <div className='fs-6 fw-semibold form-label mb-2 text-danger'>
                  Обязательное поле
                </div>
              )}
            </div>

            {/* Если telegram */}
            {watchType === 'telegram' && (
              <>
                {/* Бот */}
                <div className='fv-row mb-7 fv-plugins-icon-container'>
                  <label className='fw-semibold fs-6 mb-2'>Бот</label>
                  <Controller
                    name='bot_id'
                    control={control}
                    render={({field: {onChange, value}}) => (
                      <Selector
                        options={optionsBots}
                        value={value}
                        onChange={onChange}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            height: '42px',
                          }),
                        }}
                      />
                    )}
                  />
                </div>
                {/* Канал */}
                {optionsChannels.length > 0 && (
                  <div className='fv-row mb-7 fv-plugins-icon-container'>
                    <label className='fw-semibold fs-6 mb-2'>Канал</label>
                    <Controller
                      name='channel_id'
                      control={control}
                      render={({field: {onChange, value}}) => (
                        <Selector
                          options={optionsChannels}
                          value={value}
                          onChange={onChange}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              height: '42px',
                            }),
                          }}
                        />
                      )}
                    />
                  </div>
                )}
              </>
            )}

            {/* Если pwa */}
            {watchType === 'pwa' && (
              <div className='fv-row mb-7 fv-plugins-icon-container'>
                <label className='fw-semibold fs-6 mb-2'>PWA</label>
                <Controller
                  name='pwa_id'
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <Selector
                      options={optionsPwas}
                      value={value}
                      onChange={onChange}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: '42px',
                        }),
                      }}
                    />
                  )}
                />
              </div>
            )}

            <div className='d-flex flex-end pt-10'>
              <input
                type='submit'
                className='btn fw-bold btn-primary cursor-pointer'
                value='Сохранить'
              />
            </div>
          </form>

          {/* Модальное окно предупреждения при закрытии без сохранения */}
          <CreateAppModal
            customWidth='mw-400px'
            padding='pb-lg-10 pt-lg-10 px-lg-10'
            show={warning}
            handleClose={() => setWarning(false)}
            showHeader={false}
            isMessage={true}
          >
            <Warning
              buttons={[
                {
                  action: () => {
                    setWarning(false);
                  },
                  title: <i className='bi bi-x p-0 fs-1'></i>,
                  class: 'btn-light',
                },
                {
                  action: () => {
                    setWarning(false);
                    setShowCreateAppModal(false);
                    reset();
                  },
                  title: <i className='bi bi-check p-0 fs-1'></i>,
                  class: 'btn-light-primary',
                },
              ]}
            />
          </CreateAppModal>
        </>
      </CreateAppModal>
    </>
  );
};

export default observer(AddSourceWithoutTrafficId);
