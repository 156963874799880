/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useThemeMode} from '../../../../../../_metronic/partials';
import css from '../../../../../../styles/TOPManagers.module.scss';
import classNames from 'classnames';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import imagePlug from '../../../../../../_metronic/assets/media/user.png';
import {Link} from 'react-router-dom';
import { formatNumberToCash, formatNumber, formatNumber2 } from '../../../../../functions/formatNumber';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';

const Item = ({data, top, bad}) => {
  const store = useStore();
  const theme = useThemeMode();

  const [mode, setMode] = useState('');
  const [openSub, setOpenSub] = useState(false);

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  return (
    <li className={css.item}>
      <div
        className={css.blockScreen}
        style={{
          backgroundColor: mode === 'light' ? 'rgb(255, 255, 255)' : '#15171C',
          border: mode === 'light' ? '1px solid rgb(241, 241, 244)' : 'none',
        }}
      >
        <div
          className={classNames(
            css.position,
            top[0] === data?.id && css.gold,
            top[1] === data?.id && css.silver,
            top[2] === data?.id && css.bronze,
            bad.includes(data?.id) ? css.bad : ''
            // !top.includes(data?.id) && !bad.includes(data?.id) ? css.borderInTable_5 : ''
          )}
        >
          <span className={classNames('text-gray-900 fw-bold fs-6 text-center')}>
            {data.place === 99999 ? '-' : data.place}
          </span>
        </div>
        <div className={css.user}>
          <div className='d-flex align-items-center'>
            <div className={classNames('symbol symbol-45px me-5')}>
              <img
                className='object-fit-cover'
                src={data?.avatar ? `${process.env.REACT_APP_API_HOST}/${data?.avatar}` : imagePlug}
                style={{borderRadius: '50%'}}
                alt=''
              />
            </div>
            <div className='d-flex justify-content-center flex-column'>
              <div className='d-flex align-items-center'>
                <Link
                  className='d-flex align-items-center'
                  to={store.roles.includes(9) || store.roles.includes(33) ? '' : `/sales/ltc/managers/${data?.id}`}
                >
                  <span className='text-gray-900 text-hover-primary fw-bold mb-1 fs-6 text-nowrap cursor-pointer'>
                    {data?.first_name} {data?.last_name}
                  </span>
                </Link>
                {!!data?.is_verified && (
                  <OverlayTrigger
                    delay={{hide: 450, show: 300}}
                    placement='right'
                    overlay={(props) => (
                      <Tooltip className='fs-8' {...props}>
                        {!!data?.verify_date
                          ? `Верифицирован ${data?.verify_date}`
                          : 'Time not set'}
                      </Tooltip>
                    )}
                  >
                    <div className='d-md-block d-none symbol symbol-30px symbol-circle'>
                      <span>
                        <i className='ki-duotone ki-verify fs-1 text-primary'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    </div>
                  </OverlayTrigger>
                )}
                {!!data?.is_ban && (
                  <OverlayTrigger
                    delay={{hide: 450, show: 300}}
                    placement='right'
                    overlay={(props) => (
                      <Tooltip className='' {...props}>
                        {!!data?.ban_date ? `Заблокирован ${data?.ban_date}` : 'Time not set'}
                      </Tooltip>
                    )}
                  >
                    <div className='d-md-block d-none symbol symbol-30px symbol-circle'>
                      <span>
                        <i className='text-danger ki-duotone ki-lock-3 fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                      </span>
                    </div>
                  </OverlayTrigger>
                )}
              </div>
              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                #{data?.id}
                <span
                  className={classNames(
                    `badge badge-light-${data?.status?.indicator} ms-2 fs-7 fw-bold`,
                    css.userLtc
                  )}
                >
                  {formatNumber2(data?.ltc)}%
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className={classNames(css.info, css.screenInfo)}>
          <div className={css.team}>
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <Link
                  to={
                    store.roles.includes(9) || store.roles.includes(16) || store.roles.includes(33)
                      ? ''
                      : `/sales/ltc/teams/${data?.team?.id}`
                  }
                >
                  <span className='text-gray-900 text-hover-primary fw-bold mb-1 fs-6 cursor-pointer'>
                    {data?.team?.teamleader?.first_name} {data?.team?.teamleader?.last_name}
                  </span>
                </Link>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  {data?.team?.id && `#${data?.team?.id}`}
                </span>
              </div>
            </div>
          </div>
          <div className={css.teamMobile}>
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <Link
                  to={
                    store.roles.includes(9) || store.roles.includes(16) || store.roles.includes(33)
                      ? ''
                      : `/sales/ltc/teams/${data?.team?.id}`
                  }
                >
                  <span className='text-gray-900 text-hover-primary fw-bold mb-1 fs-6 cursor-pointer text-center'>
                    {data?.team?.id && `#${data?.team?.id}`}
                  </span>
                </Link>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>Команда</span>
              </div>
            </div>
          </div>
          <div className={css.stats}>
            <div className={classNames(css.appeals, css.separator)}>
              <div className='d-flex align-items-center'>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='d-flex justify-content-center align-items-center'>
                    <span className='text-success fw-bold mb-1 fs-6 text-center'>
                      {data?.count_appeals}
                    </span>
                    {data?.forecast_appeals !== data?.count_appeals && (
                      <>
                        <span className='text-gray-900 fw-bold mb-1 fs-6 text-center'> / </span>
                        <span className='text-primary fw-bold mb-1 fs-6 text-center'>
                          {formatNumber(data?.forecast_appeals)}
                        </span>
                      </>
                    )}
                  </span>
                  <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                    Обращения
                  </span>
                </div>
              </div>
            </div>
            <div className={classNames(css.registrations, css.separator)}>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='text-gray-900 fw-bold   d-block mb-1 fs-6 text-center'>
                    {formatNumber(data?.count_registrations)}
                  </span>
                  <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                    Регистрации
                  </span>
                </div>
              </div>
            </div>
            <div className={css.qualifications}>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='text-gray-900 fw-bold   d-block mb-1 fs-6 text-center'>
                    {formatNumber(data?.count_qualifications)}
                  </span>
                  <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                    Квалификации
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={css.ltc}>
          <span className={`badge badge-light-${data?.status?.indicator} fs-7 fw-bold`}>
            {formatNumber2(data?.ltc)}%
          </span>
        </div>
        <div className={css.salary}>
          <span className='text-gray-900 fw-bold   d-block mb-1 fs-6 text-center'>
            {formatNumberToCash(data?.forecast_pay)}
          </span>
        </div>
        <div className={css.action}>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px'
            onClick={() => setOpenSub(!openSub)}
          >
            {!openSub && <i className='ki-duotone ki-plus fs-3 m-0'></i>}
            {!!openSub && <i className='ki-duotone ki-minus fs-3 m-0'></i>}
          </button>
        </div>
      </div>
      <div
        className={classNames(css.blockMobile, !!openSub && css._active)}
        style={{
          backgroundColor: mode === 'light' ? 'rgb(255, 255, 255)' : '#15171C',
          border: mode === 'light' ? '1px solid rgb(241, 241, 244)' : 'none',
        }}
      >
        <div className={css.info}>
          <div className={classNames(css.team, css.teamWidthMobile, css.separator)}>
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <Link
                  to={
                    store.roles.includes(9) || store.roles.includes(16) || store.roles.includes(33)
                      ? ''
                      : `/sales/ltc/teams/${data?.team?.id}`
                  }
                >
                  <span className='text-gray-900 text-hover-primary fw-bold mb-1 fs-6 cursor-pointer'>
                    {data?.team?.teamleader?.first_name} {data?.team?.teamleader?.last_name}
                  </span>
                </Link>

                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                  {data?.team?.id && `#${data?.team?.id}`}
                </span>
              </div>
            </div>
          </div>
          <div className={classNames(css.teamMobile, css.teamMedium, css.separator)}>
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <Link
                  to={
                    store.roles.includes(9) || store.roles.includes(16) || store.roles.includes(33)
                      ? ''
                      : `/sales/ltc/teams/${data?.team?.id}`
                  }
                >
                  <span className='text-gray-900 text-hover-primary fw-bold mb-1 fs-6 cursor-pointer text-center'>
                    {data?.team?.id && `#${data?.team?.id}`}
                  </span>
                </Link>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>Команда</span>
              </div>
            </div>
          </div>
          <div className={css.stats}>
            <div className={classNames(css.appeals, css.separator)}>
              <div className='d-flex align-items-center'>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='d-flex justify-content-center align-items-center'>
                    <span className='text-success fw-bold mb-1 fs-6 text-center'>
                      {data?.count_appeals}
                    </span>
                    {data?.forecast_appeals !== data?.count_appeals && (
                      <>
                        <span className='text-gray-900 fw-bold mb-1 fs-6 text-center'> / </span>
                        <span className='text-primary fw-bold mb-1 fs-6 text-center'>
                          {data?.forecast_appeals}
                        </span>
                      </>
                    )}
                  </span>
                  <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                    Обращения
                  </span>
                </div>
              </div>
            </div>
            <div className={classNames(css.registrations, css.separator)}>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='text-gray-900 fw-bold   d-block mb-1 fs-6 text-center'>
                    {data?.count_registrations}
                  </span>
                  <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                    Регистрации
                  </span>
                </div>
              </div>
            </div>
            <div className={css.qualifications}>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='d-flex justify-content-start flex-column'>
                  <span className='text-gray-900 fw-bold   d-block mb-1 fs-6 text-center'>
                    {data?.count_qualifications}
                  </span>
                  <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                    Квалификации
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link
          to={
            store.roles.includes(9) || store.roles.includes(16) || store.roles.includes(33)
              ? ''
              : `/sales/ltc/teams/${data?.team?.id}`
          }
        >
          <div
            className={css.teamSmall}
            style={{
              backgroundColor: mode === 'light' ? 'rgb(255, 255, 255)' : '#15171C',
            }}
          >
            <div className={css.name}>
              <i className='ki-duotone ki-award fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
              <span className='text-gray-500 text-hover-primary fw-bold fs-6 cursor-pointer'>
                {data?.team?.teamleader?.first_name} {data?.team?.teamleader?.last_name}
              </span>
            </div>
            <div className={css.link}>
              <i className='ki-duotone ki-right-square fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </div>
          </div>
        </Link>
      </div>
    </li>
  );
};

export default observer(Item);
