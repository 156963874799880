/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';
import {Button} from '../../../shared/Buttons';
import {CreateAppModal} from '../../../../_metronic/partials';
import {TrekerButton} from '../../../pages/traffic/influence/bloggers/source/Treker/TrekerButton';
import {defaultActiveColumns} from './columns'

export const ColumnsFilter = ({linkStore, data, activeColumns, setActiveColumns}) => {
  const [showColumnsFilter, setShowColumnsFilter] = useState(false);

  const handelChange = (label) => {
    const newActiveColumns = [...activeColumns];
    const findIndex = newActiveColumns.findIndex((item) => item === label);
    if (findIndex === -1) {
      newActiveColumns.push(label);
    } else {
      newActiveColumns.splice(findIndex, 1);
    }

    setActiveColumns(newActiveColumns);
    localStorage.setItem(linkStore, JSON.stringify(newActiveColumns));
  };

  return (
    <>
      <TrekerButton onClick={() => setShowColumnsFilter(true)}>Колонки</TrekerButton>
      <CreateAppModal
        customWidth='mw-700px'
        show={showColumnsFilter}
        handleClose={() => setShowColumnsFilter(false)}
        showHeader={true}
        title='Колонки'
        isMessage={false}
      >
        <div>
          <div className='row row-cols-3 g-5'>
            {data.map((item) => {
              return (
                <label
                  key={item}
                  className='checkbox d-flex align-items-center gap-3'
                  style={{cursor: 'pointer'}}
                >
                  <input
                    type='checkbox'
                    checked={!activeColumns?.includes(item)}
                    onChange={(e) => handelChange(item)}
                  />
                  <span>{item}</span>
                </label>
              );
            })}
          </div>
          <div className='d-flex mt-20 justify-content-end'>
            <Button
              color='btn-primary me-2'
              action={() => {
                setShowColumnsFilter(false);
              }}
            >
              Готово
            </Button>
            <Button
              color='btn-secondary'
              action={() => {
                localStorage.setItem(linkStore, JSON.stringify(defaultActiveColumns));
                setActiveColumns(defaultActiveColumns);
              }}
            >
              Восстановить по умолчанию
            </Button>
          </div>
        </div>
      </CreateAppModal>
    </>
  );
};
