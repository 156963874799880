/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useStore} from '../../store/store';
import {observer} from 'mobx-react';
import {Route, Routes, Navigate, useLocation, useParams, useNavigate} from 'react-router-dom';
import MasterLayout from '../../_metronic/layout/MasterLayout';
import {toast} from 'react-toastify';

import Deveror from '../modules/errors/components/Deverror';
import Deveror2 from '../modules/errors/components/Deverror2';

import MainPanel from '../pages/dashboards/MainPanel';
import SalesPanel from '../pages/dashboards/salesDepartment/SalesPanel';
import FacebookPanel from '../pages/dashboards/facebook/FacebookPanel';
import InfluencePanel from '../pages/dashboards/influence/InfluencePanel';
import TeamleaderLTC from '../pages/dashboards/teamleaderLTC/TeamleaderLTC';
import TeamleaderRD from '../pages/dashboards/teamleaderRD/TeamleaderRD';
import DashboardManagerLTC from '../pages/dashboards/managerLTC/ManagerLTC';
import DashboardManagerRD from '../pages/dashboards/managerRD/ManagerRD';
import BayerPanel from '../pages/dashboards/facebookBayerPanel/BayerPanel';
import InfluenceBayerPanel from '../pages/dashboards/influenceBayerPanel/BayerPanel';
import HRPanel from '../pages/dashboards/HRPanel/HRPanel';

import KPI from '../pages/traffic/KPI';
import Wallet from '../pages/wallet/Wallet';
import PwaList from "../pages/pwa/list/PwaList";

import Links from '../pages/links/Links';

import Profile from '../pages/profile/Profile';
import EmployeesWrapper from '../pages/employees/Employees';
import EmployeeInfo from '../pages/employees/user/EmployeeInfo';
import Leads from '../pages/leads/Leads';
import Lead from '../pages/leads/Lead';

import Roles from '../pages/employees/roles/Roles';
import RolesView from '../pages/employees/roles/RolesView';
import Permissions from '../pages/employees/permissions/Permissions';

import Bloggers from '../pages/traffic/influence/bloggers/list/Bloggers';
import BloggerWrapper from '../pages/traffic/influence/bloggers/Blogger';
import Bayers from '../pages/traffic/influence/bayers/Bayers';
import BayerWrapper from '../pages/traffic/influence/bayers/Bayer';
import BayersFacebook from '../pages/traffic/facebook/bayers/list/Bayers';
import BayerFacebook from '../pages/traffic/facebook/bayers/Bayer';
import Sources from '../pages/traffic/facebook/sources/list/Sources';
import Companies from '../pages/traffic/facebook/companies/list/Companies';
import CompanyWrapper from '../pages/traffic/facebook/companies/Company';
import AdvertisingWrapper from '../pages/traffic/influence/bloggers/advertising/Advertising';
import SourceWrapper from '../pages/traffic/influence/bloggers/source/Source';
import AccountsWrapper from '../pages/traffic/facebook/accounts/Accounts';

import FinancesWrapper from '../pages/finances/Finances';

import Payments from '../pages/payments/Payments';
import Servers from '../pages/dev/servers/Servers';
import Server from '../pages/dev/servers/Server';
import Domains from '../pages/dev/domains/Domains';
import Domain from '../pages/dev/domains/Domain';
import Offers from '../pages/advertisers/offers/Offers';
import OfferWrapper from '../pages/advertisers/offers/offer/Offer';
import AddOffer from '../pages/advertisers/offers/AddOffer';
import Channels from '../pages/resource/channels/Channels';
import Bots from '../pages/resource/bots/Bots';
import Landings from '../pages/resource/landings/Landings';
import Departments from '../pages/employees/departments/Departments';
import Pixels from '../pages/resource/pixels/Pixels';
import TgAccountsWrapper from '../pages/resource/tg/TgAccounts';

import TeamLTC from '../pages/sales/team/LTC/Team';
import TeamRD from '../pages/sales/team/RD/Team';
import ManagersLTC from '../pages/sales/managers/LTC/List';
import ManagersRD from '../pages/sales/managers/RD/List';
import ManagerLTC from '../pages/sales/managers/LTC/manager/Manager';
import ManagerRD from '../pages/sales/managers/RD/manager/Manager';
import TeamsLTC from '../pages/sales/team/LTC/Teams';
import TeamsRD from '../pages/sales/team/RD/Teams';
import KpiLtc from '../pages/salesDepartment/kpi_ltc/KpiLtc';
import KpiRd from '../pages/salesDepartment/kpi_rd/KpiRd';
import TOPLTC from '../pages/sales/managers/LTC/top/TOPLTC';
import TOPRD from '../pages/sales/managers/RD/top/TOPRD';
import Analysis from '../pages/salesDepartment/analysisRd/Analysis';
import AnalysisSoft from '../pages/salesDepartment/analysisRd/AnalysisSoft';
import AnalysisLtc from '../pages/salesDepartment/analysisLtc/Analysis';

import AddUser from '../pages/employees/users/AddUser';
import UpdateUser from '../pages/employees/users/UpdateUser';
import TOPLTCTeam from '../pages/sales/team/LTC/top/TOPLTCTeam';
import Advertisers from '../pages/advertisers/advertisers/Advertisers';
import Reports from '../pages/advertisers/reports/Reports';
import AdvertiserPage from '../pages/advertisers/advertisers/AdvertiserPage';
import CasFlow from '../pages/finances/cashflow/CashFlow';
import Accounts from '../pages/finances/accounts/Accounts';
import AddAccount from '../pages/finances/accounts/AddAccount';
import Pixel from '../pages/resource/pixels/Pixel';
import LeadsRD from '../pages/salesDepartment/leadsRD/LeadsRD';
import CRM from '../pages/crm/CRM';
import Pwas from '../pages/resource/pwas/Pwas';
import Pwa from '../pages/resource/pwas/Pwa';
import PagePush from '../pages/resource/pwas/PagePush';
import TeamLeadBayerPanel from '../pages/dashboards/influenceTeamLeadBayerPanel/TeamLeadBayerPanel';
import PwasConstructor from '../pages/pwas/PwasConstructor';
import Statistics from '../pages/traffic/statistics/Statistics';
import StatisticsInfluence from '../pages/traffic/statisticsInfluence/Statistics';
import AdvertisersList from '../pages/traffic/facebook/advertisers/Advertisers';
import OffersList from '../pages/traffic/facebook/offers/Offers';
import Conversions from "../pages/traffic/conversions/Conversions";

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const store = useStore();

  const [rerender, setRerender] = useState(false);

  function isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  const getUserData = async () => {
    try {
      const locale = Intl.DateTimeFormat('en-US', 'long').resolvedOptions().timeZone;
      const response = await store.getIp();
      const ip = response.ip;
      const userAgent = response.user_agent;
      return {ip, locale, userAgent};
    } catch (error) {
      console.error('Error fetching IP information:', error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };

  useEffect(() => {
    (async () => {
      const {ip, locale, userAgent} = await getUserData();
      const verify = await store.verifyAuth({ip, locale, userAgent});
      if (verify === 503) {
        navigate('/devwarning');
      } else if (isObject(verify)) {
        store.setIsVerify(true);
      } else {
        console.log(true);
        setRerender(true);
        // store.signOut()
        // notify('Ошибка: вы не прошли верификацию');
      }
    })();
  }, [store.currentLocation]);

  useEffect(() => {
    if (!rerender) return;
    notify('Ошибка: вы не прошли верификацию');
  }, [rerender]);

  useEffect(() => {
    const user = store.user;
    const rolesArr = user?.auth?.roles?.map((el) => {
      return el.id;
    });

    store.setRoles(rolesArr);
  }, [store.user]);

  const notify = (message) => {
    return toast.error(message, {onClose: () => store.signOut(), theme: 'colored'});
  };

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {store.roles.includes(9) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/manager/ltc' />} />

            <Route path='dashboard/manager/ltc' element={<DashboardManagerLTC />} />
            <Route path='sales/ltc/top/managers' element={<TOPLTC />} />
            {/* <Route path='sales/ltc/top/teams' element={<TOPLTCTeam />} /> */}
            <Route path='sales/ltc/kpi' element={<KpiLtc />} />
            <Route path='leads' element={<Leads />} />
            <Route path='leads/:id' element={<Lead />} />
            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
            <Route path='crm' element={<CRM />} />
          </>
        )}
        {store.roles.includes(33) && (
          <>
            {/* <Route path='dashboard/manager/ltc' element={<DashboardManagerLTC />} /> */}
            <Route path='sales/ltc/top/managers' element={<TOPLTC />} />
            {/* <Route path='sales/ltc/top/teams' element={<TOPLTCTeam />} /> */}
            {/* <Route path='sales/ltc/kpi' element={<KpiLtc />} /> */}
            <Route path='leads' element={<Leads />} />
            <Route path='leads/:id' element={<Lead />} />
            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
            <Route path='crm' element={<CRM />} />
          </>
        )}
        {store.roles.includes(15) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/manager/rd' />} />

            <Route path='dashboard/manager/rd' element={<DashboardManagerRD />} />
            <Route path='sales/rd/top/managers' element={<TOPRD />} />
            <Route path='sales/rd/kpi' element={<KpiRd />} />
            <Route path='sales/rd/leads' element={<LeadsRD />} />
            <Route path='leads' element={<Leads />} />
            <Route path='leads/:id' element={<Lead />} />
            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
            <Route path='crm' element={<CRM />} />
          </>
        )}
        {store.roles.includes(34) && (
          <>
            {/* <Route path='auth/*' element={<Navigate to='/dashboard/manager/rd' />} /> */}

            {/* <Route path='dashboard/manager/rd' element={<DashboardManagerRD />} /> */}
            <Route path='sales/rd/top/managers' element={<TOPRD />} />
            {/* <Route path='sales/rd/kpi' element={<KpiRd />} /> */}
            <Route path='sales/rd/leads' element={<LeadsRD />} />
            <Route path='leads' element={<Leads />} />
            <Route path='leads/:id' element={<Lead />} />
            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
            <Route path='crm' element={<CRM />} />
          </>
        )}
        {store.roles.includes(16) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/teamleader/ltc' />} />

            <Route path='dashboard/teamleader/ltc' element={<TeamleaderLTC />} />
            <Route path='sales/ltc/top/managers' element={<TOPLTC />} />
            <Route path='sales/ltc/top/teams' element={<TOPLTCTeam />} />
            <Route path='sales/ltc/managers' element={<ManagersLTC />} />
            <Route path='sales/ltc/managers/:id' element={<ManagerLTC />} />
            <Route path='sales/ltc/kpi' element={<KpiLtc />} />
            <Route path='leads' element={<Leads />} />
            <Route path='leads/:id' element={<Lead />} />
            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
            <Route path='crm' element={<CRM />} />
          </>
        )}
        {store.roles.includes(17) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/teamleader/rd' />} />

            <Route path='dashboard/teamleader/rd' element={<TeamleaderRD />} />
            <Route path='sales/rd/top/managers' element={<TOPRD />} />
            <Route path='sales/rd/managers' element={<ManagersRD />} />
            <Route path='sales/rd/managers/:id' element={<ManagerRD />} />
            <Route path='sales/rd/kpi' element={<KpiRd />} />
            <Route path='sales/rd/leads' element={<LeadsRD />} />
            <Route path='sales/rd/soft' element={<AnalysisSoft />} />
            <Route path='leads' element={<Leads />} />
            <Route path='leads/:id' element={<Lead />} />
            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
            <Route path='crm' element={<CRM />} />
          </>
        )}
        {store.roles.includes(8) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/sales' />} />

            <Route path='dashboard/sales' element={<SalesPanel />} />

            <Route path='crm' element={<CRM />} />

            <Route path='resources/telegram/accounts' element={<TgAccountsWrapper />} />

            <Route path='sales/ltc/teams/:id' element={<TeamLTC />} />
            <Route path='sales/rd/teams/:id' element={<TeamRD />} />
            <Route path='sales/ltc/teams' element={<TeamsLTC />} />
            <Route path='sales/rd/teams' element={<TeamsRD />} />
            <Route path='sales/ltc/managers' element={<ManagersLTC />} />
            <Route path='sales/rd/managers' element={<ManagersRD />} />
            <Route path='sales/ltc/managers/:id' element={<ManagerLTC />} />
            <Route path='sales/rd/managers/:id' element={<ManagerRD />} />
            <Route path='sales/ltc/top/managers' element={<TOPLTC />} />
            <Route path='sales/ltc/top/teams' element={<TOPLTCTeam />} />
            <Route path='sales/rd/top/managers' element={<TOPRD />} />
            <Route path='sales/ltc/kpi' element={<KpiLtc />} />
            <Route path='sales/rd/kpi' element={<KpiRd />} />
            <Route path='sales/rd/analysis' element={<Analysis />} />
            <Route path='sales/rd/soft' element={<AnalysisSoft />} />
            <Route path='sales/ltc/analysis' element={<AnalysisLtc />} />
            <Route path='sales/rd/leads' element={<LeadsRD />} />

            <Route path='users/list' element={<EmployeesWrapper />} />
            <Route path='users/list/user/:id/*' element={<EmployeeInfo />} />
            <Route path='users/list/addUser' element={<AddUser />} />
            <Route path='users/list/updateUser/:id' element={<UpdateUser />} />

            <Route path='leads' element={<Leads />} />
            <Route path='leads/:id' element={<Lead />} />
            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
          </>
        )}
        {store.roles.includes(11) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/influence' />} />

            <Route path='dashboard/influence' element={<InfluencePanel />} />

            <Route path='traffics/influence/bloggers' element={<Bloggers />} />
            <Route path='traffics/influence/advertising/:id' element={<AdvertisingWrapper />} />
            <Route path='traffics/influence/bloggers/:id' element={<BloggerWrapper />} />
            <Route path='traffics/influence/bayers' element={<Bayers />} />
            <Route path='traffics/influence/bayers/:id' element={<BayerWrapper />} />
            <Route path='users/list' element={<EmployeesWrapper />} />
            <Route path='users/list/addUser' element={<AddUser />} />
            <Route path='users/list/updateUser/:id' element={<UpdateUser />} />
            <Route path='users/list/user/:id/*' element={<EmployeeInfo />} />

            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
          </>
        )}
        {store.roles.includes(10) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/facebook' />} />

            <Route path='dashboard/facebook' element={<FacebookPanel />} />

            <Route path='traffics/facebook/bayers' element={<BayersFacebook />} />
            <Route path='traffics/facebook/bayers/:id' element={<BayerFacebook />} />
            <Route path='traffics/facebook/companies' element={<Companies />} />
            <Route path='traffics/facebook/companies/:id' element={<CompanyWrapper />} />
            <Route path='traffics/facebook/source/:id' element={<SourceWrapper />} />
            <Route path='traffics/facebook/accounts' element={<AccountsWrapper />} />

            <Route path='resources/pixels' element={<Pixels />} />
            <Route path='resources/pixels/:id' element={<Pixel />} />
            <Route path='resources/landings' element={<Landings />} />
            <Route path='resources/pwa' element={<Pwas />} />
            <Route path='resources/pwa/:id' element={<Pwa />} />
            <Route path='resources/pwa/:pwaId/push/:id' element={<PagePush />} />

            <Route path='users/list' element={<EmployeesWrapper />} />
            <Route path='users/list/addUser' element={<AddUser />} />
            <Route path='users/list/updateUser/:id' element={<UpdateUser />} />
            <Route path='users/list/user/:id/*' element={<EmployeeInfo />} />

            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />

            <Route path='pwa/constructor' element={<PwasConstructor />} />
            <Route path='pwa/constructor/:pwaId' element={<PwasConstructor />} />
          </>
        )}
        {store.roles.includes(6) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/facebook/bayer' />} />

            <Route path='dashboard/facebook/bayer' element={<BayerPanel />} />

            <Route path='traffics/facebook/companies' element={<Companies />} />
            <Route path='traffics/facebook/companies/:id' element={<CompanyWrapper />} />
            <Route path='traffics/facebook/source/:id' element={<SourceWrapper />} />

            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
          </>
        )}
        {store.roles.includes(19) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/influence/bayer' />} />

            <Route path='dashboard/influence/bayer' element={<InfluenceBayerPanel />} />

            <Route path='traffics/influence/bloggers' element={<Bloggers />} />
            <Route path='traffics/influence/bloggers/:id' element={<BloggerWrapper />} />
            <Route path='traffics/influence/advertising/:id' element={<AdvertisingWrapper />} />

            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
          </>
        )}
        {store.roles.includes(36) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/influence' />} />

            <Route path='dashboard/influence' element={<InfluencePanel />} />

            <Route path='traffics/influence/bloggers' element={<Bloggers />} />
            <Route path='traffics/influence/bloggers/:id' element={<BloggerWrapper />} />
            <Route path='traffics/influence/advertising/:id' element={<AdvertisingWrapper />} />
            <Route path='traffics/influence/bayers' element={<Bayers />} />
            <Route path='traffics/influence/bayers/:id' element={<BayerWrapper />} />

            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
          </>
        )}
        {store.roles.includes(13) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/main' />} />
            {/* Pages */}
            <Route path='dashboard/main' element={<MainPanel />} />
            <Route path='dashboard/sales' element={<SalesPanel />} />
            <Route path='dashboard/facebook' element={<FacebookPanel />} />
            <Route path='dashboard/influence' element={<InfluencePanel />} />

            <Route path='traffics/influence/bloggers' element={<Bloggers />} />
            <Route path='traffics/influence/advertising/:id' element={<AdvertisingWrapper />} />
            <Route path='traffics/influence/bloggers/:id' element={<BloggerWrapper />} />
            <Route path='traffics/influence/bayers' element={<Bayers />} />
            <Route path='traffics/influence/bayers/:id' element={<BayerWrapper />} />
            <Route path='traffics/facebook/bayers' element={<BayersFacebook />} />
            <Route path='traffics/facebook/bayers/:id' element={<BayerFacebook />} />
            <Route path='traffics/facebook/companies' element={<Companies />} />
            <Route path='traffics/facebook/companies/:id' element={<CompanyWrapper />} />
            <Route path='traffics/facebook/source/:id' element={<SourceWrapper />} />
            <Route path='traffics/facebook/accounts' element={<AccountsWrapper />} />

            <Route path='sales/ltc/teams/:id' element={<TeamLTC />} />
            <Route path='sales/rd/teams/:id' element={<TeamRD />} />
            <Route path='sales/ltc/teams' element={<TeamsLTC />} />
            <Route path='sales/rd/teams' element={<TeamsRD />} />
            <Route path='sales/ltc/managers' element={<ManagersLTC />} />
            <Route path='sales/rd/managers' element={<ManagersRD />} />
            <Route path='sales/ltc/managers/:id' element={<ManagerLTC />} />
            <Route path='sales/rd/managers/:id' element={<ManagerRD />} />
            <Route path='sales/ltc/top/managers' element={<TOPLTC />} />
            <Route path='sales/ltc/top/teams' element={<TOPLTCTeam />} />
            <Route path='sales/rd/top/managers' element={<TOPRD />} />
            <Route path='sales/ltc/kpi' element={<KpiLtc />} />
            <Route path='sales/rd/kpi' element={<KpiRd />} />
            <Route path='sales/rd/analysis' element={<Analysis />} />
            <Route path='sales/ltc/analysis' element={<AnalysisLtc />} />

            <Route path='advertisers/list' element={<AdvertisersList />} />
            <Route path='advertisers/list/:id' element={<AdvertiserPage />} />
            <Route path='advertisers/offers' element={<OffersList />} />
            <Route path='advertisers/offers/:id' element={<OfferWrapper />} />

            <Route path='advertisers/reports' element={<Reports />} />

            <Route path='account/*' element={<Profile />} />

            <Route path='users/list' element={<EmployeesWrapper />} />
            <Route path='users/list/user/:id/*' element={<EmployeeInfo />} />
            <Route path='users/roles' element={<Roles />} />
            <Route path='users/roles/:id' element={<RolesView />} />
            <Route path='users/departments' element={<Departments />} />

            <Route path='wallet' element={<Wallet />} />
          </>
        )}
        {store.roles.includes(32) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/main' />} />
            {/* Pages */}
            <Route path='dashboard/main' element={<MainPanel />} />
            <Route path='dashboard/sales' element={<SalesPanel />} />
            <Route path='dashboard/facebook' element={<FacebookPanel />} />
            <Route path='dashboard/influence' element={<InfluencePanel />} />

            <Route path='traffics/influence/bloggers' element={<Bloggers />} />
            <Route path='traffics/influence/advertising/:id' element={<AdvertisingWrapper />} />
            <Route path='traffics/influence/bloggers/:id' element={<BloggerWrapper />} />
            <Route path='traffics/influence/bayers' element={<Bayers />} />
            <Route path='traffics/influence/bayers/:id' element={<BayerWrapper />} />
            <Route path='traffics/facebook/bayers' element={<BayersFacebook />} />
            <Route path='traffics/facebook/bayers/:id' element={<BayerFacebook />} />
            <Route path='traffics/facebook/companies' element={<Companies />} />
            <Route path='traffics/facebook/companies/:id' element={<CompanyWrapper />} />
            <Route path='traffics/facebook/source/:id' element={<SourceWrapper />} />
            <Route path='traffics/facebook/accounts' element={<AccountsWrapper />} />

            <Route path='sales/ltc/teams/:id' element={<TeamLTC />} />
            <Route path='sales/rd/teams/:id' element={<TeamRD />} />
            <Route path='sales/ltc/teams' element={<TeamsLTC />} />
            <Route path='sales/rd/teams' element={<TeamsRD />} />
            <Route path='sales/ltc/managers' element={<ManagersLTC />} />
            <Route path='sales/rd/managers' element={<ManagersRD />} />
            <Route path='sales/ltc/managers/:id' element={<ManagerLTC />} />
            <Route path='sales/rd/managers/:id' element={<ManagerRD />} />
            <Route path='sales/ltc/top/managers' element={<TOPLTC />} />
            <Route path='sales/ltc/top/teams' element={<TOPLTCTeam />} />
            <Route path='sales/rd/top/managers' element={<TOPRD />} />
            <Route path='sales/ltc/kpi' element={<KpiLtc />} />
            <Route path='sales/rd/kpi' element={<KpiRd />} />
            <Route path='sales/rd/analysis' element={<Analysis />} />
            <Route path='sales/ltc/analysis' element={<AnalysisLtc />} />

            <Route path='advertisers/list' element={<AdvertisersList />} />
            <Route path='advertisers/list/:id' element={<AdvertiserPage />} />
            <Route path='advertisers/offers' element={<OffersList />} />
            <Route path='advertisers/offers/:id' element={<OfferWrapper />} />

            <Route path='advertisers/reports' element={<Reports />} />

            <Route path='account/*' element={<Profile />} />

            <Route path='users/list' element={<EmployeesWrapper />} />
            <Route path='users/list/user/:id/*' element={<EmployeeInfo />} />
            <Route path='users/roles' element={<Roles />} />
            <Route path='users/roles/:id' element={<RolesView />} />
            <Route path='users/departments' element={<Departments />} />

            <Route path='wallet' element={<Wallet />} />
          </>
        )}
        {store.roles.includes(26) && (
          <>
            <Route path='dashboard/hr' element={<HRPanel />} />

            <Route path='users/list' element={<EmployeesWrapper />} />
            <Route path='users/list/addUser' element={<AddUser />} />
            <Route path='users/list/updateUser/:id' element={<UpdateUser />} />
            <Route path='users/list/user/:id/*' element={<EmployeeInfo />} />
            <Route path='users/roles' element={<Roles />} />
            <Route path='users/roles/:id' element={<RolesView />} />
            <Route path='users/departments' element={<Departments />} />

            <Route path='wallet' element={<Wallet />} />

            <Route path='account/*' element={<Profile />} />
          </>
        )}
        {store.roles.includes(28) && (
          <>
            <Route path='leads' element={<Leads />} />
            <Route path='leads/:id' element={<Lead />} />

            <Route path='wallet' element={<Wallet />} />

            <Route path='account/*' element={<Profile />} />
            <Route path='crm' element={<CRM />} />
          </>
        )}
        {store.roles.includes(29) && (
          <>
            <Route path='leads' element={<Leads />} />
            <Route path='leads/:id' element={<Lead />} />

            <Route path='wallet' element={<Wallet />} />

            <Route path='account/*' element={<Profile />} />
            <Route path='crm' element={<CRM />} />
          </>
        )}
        {store.roles.includes(30) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/sales' />} />

            <Route path='dashboard/sales' element={<SalesPanel />} />

            <Route path='crm' element={<CRM />} />

            <Route path='resources/telegram/accounts' element={<TgAccountsWrapper />} />

            <Route path='sales/ltc/teams/:id' element={<TeamLTC />} />
            <Route path='sales/rd/teams/:id' element={<TeamRD />} />
            <Route path='sales/ltc/teams' element={<TeamsLTC />} />
            <Route path='sales/rd/teams' element={<TeamsRD />} />
            <Route path='sales/ltc/managers' element={<ManagersLTC />} />
            <Route path='sales/rd/managers' element={<ManagersRD />} />
            <Route path='sales/ltc/managers/:id' element={<ManagerLTC />} />
            <Route path='sales/rd/managers/:id' element={<ManagerRD />} />
            <Route path='sales/ltc/top/managers' element={<TOPLTC />} />
            <Route path='sales/ltc/top/teams' element={<TOPLTCTeam />} />
            <Route path='sales/rd/top/managers' element={<TOPRD />} />
            <Route path='sales/ltc/kpi' element={<KpiLtc />} />
            <Route path='sales/rd/kpi' element={<KpiRd />} />
            <Route path='sales/rd/analysis' element={<Analysis />} />
            <Route path='sales/rd/soft' element={<AnalysisSoft />} />
            <Route path='sales/ltc/analysis' element={<AnalysisLtc />} />
            <Route path='sales/rd/leads' element={<LeadsRD />} />

            <Route path='users/list' element={<EmployeesWrapper />} />
            <Route path='users/list/user/:id/*' element={<EmployeeInfo />} />
            <Route path='users/list/addUser' element={<AddUser />} />
            <Route path='users/list/updateUser/:id' element={<UpdateUser />} />

            <Route path='leads' element={<Leads />} />
            <Route path='leads/:id' element={<Lead />} />
            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
          </>
        )}
        {store.roles.includes(31) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/sales' />} />

            <Route path='dashboard/sales' element={<SalesPanel />} />

            <Route path='crm' element={<CRM />} />

            <Route path='sales/ltc/teams/:id' element={<TeamLTC />} />
            <Route path='sales/rd/teams/:id' element={<TeamRD />} />
            <Route path='sales/ltc/teams' element={<TeamsLTC />} />
            <Route path='sales/rd/teams' element={<TeamsRD />} />
            <Route path='sales/ltc/managers' element={<ManagersLTC />} />
            <Route path='sales/rd/managers' element={<ManagersRD />} />
            <Route path='sales/ltc/managers/:id' element={<ManagerLTC />} />
            <Route path='sales/rd/managers/:id' element={<ManagerRD />} />
            <Route path='sales/ltc/top/managers' element={<TOPLTC />} />
            <Route path='sales/ltc/top/teams' element={<TOPLTCTeam />} />
            <Route path='sales/rd/top/managers' element={<TOPRD />} />
            <Route path='sales/ltc/kpi' element={<KpiLtc />} />
            <Route path='sales/rd/kpi' element={<KpiRd />} />
            <Route path='sales/rd/analysis' element={<Analysis />} />
            <Route path='sales/ltc/analysis' element={<AnalysisLtc />} />
            <Route path='sales/rd/leads' element={<LeadsRD />} />

            <Route path='users/list' element={<EmployeesWrapper />} />
            <Route path='users/list/user/:id/*' element={<EmployeeInfo />} />
            <Route path='users/list/addUser' element={<AddUser />} />
            <Route path='users/list/updateUser/:id' element={<UpdateUser />} />

            <Route path='leads' element={<Leads />} />
            <Route path='leads/:id' element={<Lead />} />
            <Route path='account/*' element={<Profile />} />
            <Route path='wallet' element={<Wallet />} />
          </>
        )}
        {store.roles.includes(38) && (
          <>
            <Route path='dashboard/main' element={<MainPanel />} />
            <Route path='dashboard/facebook' element={<FacebookPanel />} />
            <Route path='dashboard/influence' element={<InfluencePanel />} />

            <Route path='traffics/influence/bloggers' element={<Bloggers />} />
            <Route path='traffics/influence/advertising/:id' element={<AdvertisingWrapper />} />
            <Route path='traffics/influence/bloggers/:id' element={<BloggerWrapper />} />
            <Route path='traffics/influence/bayers' element={<Bayers />} />
            <Route path='traffics/influence/bayers/:id' element={<BayerWrapper />} />
            <Route path='traffics/facebook/bayers' element={<BayersFacebook />} />
            <Route path='traffics/facebook/bayers/:id' element={<BayerFacebook />} />
            <Route path='traffics/facebook/companies' element={<Companies />} />
            <Route path='traffics/facebook/companies/:id' element={<CompanyWrapper />} />
            <Route path='traffics/facebook/source/:id' element={<SourceWrapper />} />
            <Route path='traffics/conversions' element={<Conversions />} />

            <Route path='advertisers/list' element={<AdvertisersList />} />
            <Route path='advertisers/list/:id' element={<AdvertiserPage />} />
            <Route path='advertisers/offers' element={<OffersList />} />
            <Route path='advertisers/offers/:id' element={<OfferWrapper />} />
            <Route path='advertisers/offers/add' element={<AddOffer />} />
            <Route
              path='advertisers/offers/updateOffer/:id'
              element={<AddOffer isUpdate={true} />}
            />

            <Route path='wallet' element={<Wallet />} />

            <Route path='account/*' element={<Profile />} />
          </>
        )}
        {store.roles.includes(4) && (
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard/main' />} />
            {/* Pages */}
            <Route path='crm' element={<CRM />} />
            <Route path='dashboard/main' element={<MainPanel />} />
            <Route path='dashboard/sales' element={<SalesPanel />} />
            <Route path='dashboard/facebook' element={<FacebookPanel />} />
            <Route path='dashboard/influence' element={<InfluencePanel />} />
            <Route path='dashboard/teamleader/ltc' element={<TeamleaderLTC />} />
            <Route path='dashboard/teamleader/rd' element={<TeamleaderRD />} />
            <Route path='dashboard/manager/ltc' element={<DashboardManagerLTC />} />
            <Route path='dashboard/manager/rd' element={<DashboardManagerRD />} />
            <Route path='dashboard/facebook/bayer' element={<BayerPanel />} />
            <Route path='dashboard/influence/bayer' element={<InfluenceBayerPanel />} />
            <Route path='dashboard/hr' element={<HRPanel />} />

            <Route path='traffics/influence/bloggers' element={<Bloggers />} />
            <Route path='traffics/influence/advertising/:id' element={<AdvertisingWrapper />} />
            <Route path='traffics/influence/bloggers/:id' element={<BloggerWrapper />} />
            <Route path='traffics/influence/bayers' element={<Bayers />} />
            <Route path='traffics/influence/bayers/:id' element={<BayerWrapper />} />
            <Route path='traffics/influence/statistics' element={<StatisticsInfluence />} />
            <Route path='traffics/facebook/bayers' element={<BayersFacebook />} />
            <Route path='traffics/facebook/bayers/:id' element={<BayerFacebook />} />
            <Route path='traffics/facebook/companies' element={<Companies />} />
            <Route path='traffics/facebook/companies/:id' element={<CompanyWrapper />} />
            <Route path='traffics/facebook/sources' element={<Sources />} />
            <Route path='traffics/facebook/source/:id' element={<SourceWrapper />} />
            <Route path='traffics/facebook/accounts' element={<AccountsWrapper />} />
            <Route path='traffics/facebook/statistics' element={<Statistics />} />
            <Route path='traffics/conversions' element={<Conversions />} />

            <Route path='wallet' element={<Wallet />} />

            {/* <Route path='users' element={<Users />} /> */}

            {/* <Route path='links' element={<Links />} /> */}

            <Route path='account/*' element={<Profile />} />

            <Route path='users/list' element={<EmployeesWrapper />} />
            <Route path='users/list/addUser' element={<AddUser />} />
            <Route path='users/list/updateUser/:id' element={<UpdateUser />} />
            <Route path='users/list/user/:id/*' element={<EmployeeInfo />} />
            <Route path='users/roles' element={<Roles />} />
            <Route path='users/roles/:id' element={<RolesView />} />
            {/* <Route path='permissions' element={<Permissions />} /> */}
            <Route path='users/departments' element={<Departments />} />

            <Route path='leads' element={<Leads />} />
            <Route path='leads/:id' element={<Lead />} />
            <Route path='finances/*' element={<FinancesWrapper />} />

            <Route path='finances/payments/*' element={<Payments />} />
            <Route path='finances/cashflow' element={<CasFlow />} />
            <Route path='finances/accounts' element={<Accounts />} />
            <Route path='finances/accounts/add' element={<AddAccount />} />

            <Route path='tech/servers' element={<Servers />} />
            <Route path='tech/servers/:id' element={<Server />} />
            <Route path='tech/domains' element={<Domains />} />
            <Route path='tech/domains/:id' element={<Domain />} />

            <Route path='advertisers/list' element={<AdvertisersList />} />
            <Route path='advertisers/list/:id' element={<AdvertiserPage />} />
            <Route path='advertisers/offers' element={<OffersList />} />
            <Route path='advertisers/offers/:id' element={<OfferWrapper />} />
            <Route path='advertisers/offers/add' element={<AddOffer />} />
            <Route
              path='advertisers/offers/updateOffer/:id'
              element={<AddOffer isUpdate={true} />}
            />
            <Route path='advertisers/reports' element={<Reports />} />

            <Route path='resources/channels' element={<Channels />} />
            <Route path='resources/bots' element={<Bots />} />
            <Route path='resources/pixels' element={<Pixels />} />
            <Route path='resources/pixels/:id' element={<Pixel />} />
            <Route path='resources/landings' element={<Landings />} />
            <Route path='resources/pwa' element={<Pwas />} />
            <Route path='resources/pwa/:id' element={<Pwa />} />
            <Route path='resources/pwa/:pwaId/push/:id' element={<PagePush />} />

            <Route path='resources/telegram/accounts' element={<TgAccountsWrapper />} />

            <Route path='sales/ltc/teams/:id' element={<TeamLTC />} />
            <Route path='sales/rd/teams/:id' element={<TeamRD />} />
            <Route path='sales/ltc/teams' element={<TeamsLTC />} />
            <Route path='sales/rd/teams' element={<TeamsRD />} />
            <Route path='sales/ltc/managers' element={<ManagersLTC />} />
            <Route path='sales/rd/managers' element={<ManagersRD />} />
            <Route path='sales/ltc/managers/:id' element={<ManagerLTC />} />
            <Route path='sales/rd/managers/:id' element={<ManagerRD />} />
            <Route path='sales/ltc/top/managers' element={<TOPLTC />} />
            <Route path='sales/ltc/top/teams' element={<TOPLTCTeam />} />
            <Route path='sales/rd/top/managers' element={<TOPRD />} />
            <Route path='sales/ltc/kpi' element={<KpiLtc />} />
            <Route path='sales/rd/kpi' element={<KpiRd />} />
            <Route path='sales/rd/analysis' element={<Analysis />} />
            <Route path='sales/rd/soft' element={<AnalysisSoft />} />
            <Route path='sales/ltc/analysis' element={<AnalysisLtc />} />
            <Route path='sales/rd/leads' element={<LeadsRD />} />

            <Route path='pwa' element={<PwaList />} />
            <Route path='pwa/constructor' element={<PwasConstructor />} />
            <Route path='pwa/constructor/:pwaId' element={<PwasConstructor />} />

            <Route path='/deveror' element={<Deveror />} />
          </>
        )}
        <Route path='/deveror' element={<Deveror />} />
        <Route path='/devwarning' element={<Deveror2 />} />
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  );
};

export default observer(PrivateRoutes);
