import {useState} from 'react';
import AddAdvertiser from '../../../advertisers/advertisers/AddAdvertiser';
import {CreateAppModal} from '../../../../../_metronic/partials';
import { Button } from '../../../../shared/Buttons';

export const AddAdvertiserModal = ({getData}) => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [save, setSave] = useState(false);

  return (
    <>
 
        <Button
          action={() => {
            setShowCreateAppModal(true);
            setSave(false);
          }}
        >
          <>
            <i className='ki-duotone ki-plus fs-2'></i>Добавить
          </>
        </Button>
      
      <CreateAppModal
        customWidth='mw-550px'
        show={showCreateAppModal}
        handleClose={() => {
          if (save) {
            setShowCreateAppModal(false);
          } else {
            setWarning(true);
          }
        }}
        title={'Добавление рекламодателя'}
      >
        <AddAdvertiser getData={getData} show={setShowCreateAppModal} warning={warning} setWarning={setWarning} />
      </CreateAppModal>
    </>
  );
};
