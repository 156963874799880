export const base_config_app = {
  appIcon: null,
  appName: '',
  developer: '',
  appSize: 0,
  slides: [],
  ratings: {a1: 0, a2: 0, a3: 0, a4: 0, a5: 0},
  ratingsCount: 0,
  comments: [],
  reviews: '',
  aboutThisApp: '',
  rtl: false,
  buttonColor: '#0b57cf',
  buttons: {
    install: 'Install',
    installing: 'Installing',
    download: 'Download',
    cancel: 'Cancel',
    open: 'Open',
    delete: 'Delete',
  },
  texts: {
    dataSafety:
      'To control safety, you need to know how developers collect and share your data. Security and privacy methods may vary depending on how you use the app, your region, and your age. The information below is provided by developers and may change in the future.',
    ratings:
      'Ratings and reviews have been verified. They were made by users with the same type of device as yours.',
  },
  titles: {
    aboutThisApp: 'About this App',
    dataSafety: 'Data safety',
    ratings: 'Rating and reviews',
    appSupport: 'App support',
    games: 'Similar games',
  },
  inscriptions: {
    contains_1: 'Contains ads',
    contains_2: 'In-app purchases',
    reviews: 'reviews',
    ageRating: 'Rated for 18+',
    downloads: 'Downloads',
    countDownloads: '1000',
    phone: 'Phone',
    dataSafety_title_1: 'Data is not shared with third parties',
    dataSafety_text_1: 'Learn more about how developers declare sharing data',
    dataSafety_title_2: 'Data is not collected',
    dataSafety_text_2: 'Learn more about how developers declare collecting data',
    seeDetails: 'See details',
    seeAllReviews: 'See all reviews',
    helpFull: 'Was this review helpful?',
    peopleHelpFull: 'people found this review helpful',
    yes: 'Yes',
    no: 'No',
  },
};

export const googleplay_app = {
  appIcon: null,
  appName: '',
  developer: '',
  appSize: 0,
  slides: [],
  ratings: {a1: 0, a2: 0, a3: 0, a4: 0, a5: 0},
  ratingsCount: 0,
  comments: [],
  reviews: '',
  aboutThisApp: '',
  rtl: false,
  buttonColor: '#0b57cf',
  buttons: {
    install: 'Install',
    installing: 'Installing',
    download: 'Download',
    cancel: 'Cancel',
    open: 'Open',
    delete: 'Delete',
  },
  texts: {
    dataSafety: '',
    ratings: '',
  },
  titles: {
    aboutThisApp: '',
    dataSafety: '',
    ratings: '',
    appSupport: '',
    games: '',
  },
  inscriptions: {
    contains_1: '',
    contains_2: '',
    reviews: '',
    ageRating: '',
    downloads: '',
    countDownloads: '',
    phone: '',
    dataSafety_title_1: '',
    dataSafety_text_1: '',
    dataSafety_title_2: '',
    dataSafety_text_2: '',
    seeDetails: '',
    seeAllReviews: '',
    helpFull: '',
    peopleHelpFull: '',
    yes: '',
    no: '',
  },
};

export const base_config_site = {
  appIcon: null,
  appName: '',
  developer: '',
  appSize: 0,
  slides: [],
  ratings: {a1: 0, a2: 0, a3: 0, a4: 0, a5: 0},
  ratingsCount: 0,
  comments: [],
  video: '',
  reviews: '',
  aboutThisApp: '',
  rtl: false,
  buttons: {
    install: 'Install',
    installing: 'Installing',
    download: 'Download',
    cancel: 'Cancel',
    open: 'Open',
    delete: 'Delete',
    trailer: 'Trailer',
  },
  texts: {
    dataSafety:
      'To control safety, you need to know how developers collect and share your data. Security and privacy methods may vary depending on how you use the app, your region, and your age. The information below is provided by developers and may change in the future.',
    ratings:
      'Ratings and reviews have been verified. They were made by users with the same type of device as yours.',
  },
  titles: {
    aboutThisApp: 'About this App',
    dataSafety: 'Data safety',
    ratings: 'Rating and reviews',
    appSupport: 'App support',
    games: 'Similar games',
  },
  inscriptions: {
    contains_1: 'Contains ads',
    contains_2: 'In-app purchases',
    reviews: 'reviews',
    ageRating: 'Rated for 18+',
    downloads: 'Downloads',
    countDownloads: '1000',
    phone: 'Phone',
    dataSafety_title_1: 'Data is not shared with third parties',
    dataSafety_text_1: 'Learn more about how developers declare sharing data',
    dataSafety_title_2: 'Data is not collected',
    dataSafety_text_2: 'Learn more about how developers declare collecting data',
    dataSafety_title_3: 'Learn more about how developers declare collecting data',
    dataSafety_title_4: 'Learn more about how developers declare collecting data',
    seeDetails: 'See details',
    seeAllReviews: 'See all reviews',
    helpFull: 'Did you find this helpful?',
    yes: 'Yes',
    no: 'No',
    share: 'Share',
    addList: 'Add to wishlist',
  },
};
export const googleplay_site = {
  appIcon: null,
  appName: '',
  developer: '',
  appSize: 0,
  slides: [],
  ratings: {a1: 0, a2: 0, a3: 0, a4: 0, a5: 0},
  ratingsCount: 0,
  comments: [],
  video: '',
  reviews: '',
  aboutThisApp: '',
  rtl: false,
  buttons: {
    install: '',
    installing: '',
    download: '',
    cancel: '',
    open: '',
    delete: '',
    trailer: '',
  },
  texts: {
    dataSafety: '',
    ratings: '',
  },
  titles: {
    aboutThisApp: '',
    dataSafety: '',
    ratings: '',
    appSupport: '',
    games: '',
  },
  inscriptions: {
    contains_1: '',
    contains_2: '',
    reviews: '',
    ageRating: '',
    downloads: '',
    countDownloads: '',
    phone: '',
    dataSafety_title_1: '',
    dataSafety_text_1: '',
    dataSafety_title_2: '',
    dataSafety_text_2: '',
    dataSafety_title_3: '',
    dataSafety_title_4: '',
    seeDetails: '',
    seeAllReviews: '',
    helpFull: '',
    yes: '',
    no: '',
    share: '',
    addList: '',
  },
};

export const lucky = {
  appIcon: null,
  slides: [],
  ios: false,
  android: false,
  inscriptions: {
    title: '',
    waiting_text: '',
    button_text: '',
    patent_text: '',
    patent_years: '',
    greeting: '',
    deposit_text: '',
    button_2_text: '',
  },
};

export const base_lucky = {
  appIcon: null,
  slides: [],
  ios: false,
  android: false,
  inscriptions: {
    title: 'Sign up now and give your deposit a boost of up to x6',
    waiting_text: 'Waiting for the bet',
    button_text: 'Rate',
    patent_text: 'All rights reserved and protected by the law',
    patent_years: '2024 1win',
    greeting: 'Congratulations, you won!',
    deposit_text: 'X4 deposit increase',
    button_2_text: 'Claim the bonus',
  },
};

export const bloggers = {
  appIcon: null,
  appName: '',
  slides: [],
  inscriptions: {
    menu_1: '',
    menu_2: '',
    menu_3: '',
    title: '',
    deposit_title: '',
    currency: '',
    deposit: '',
    profit: '',
    howInstall_title: '',
    howInstall_subtitle: '',
    howInstall_button: '',
    howInstall_onlineSupport: '',
    howInstall_time: '',
    howInstall_multiple: '',
    howInstall_type: '',
    howInstall_rating: '',
    howInstall_userRating: '',
    howInstall_bonus: '',
    howInstall_welcomeBonus: '',
    comments_title: '',
    video_title: '',
    footer_title: '',
    promocode: '',
    play: '',
    copy: '',
    patent: '',
    link_1: '',
    link_2: '',
    link_3: '',
  },
  instagram: {
    avatar: '',
    name: '',
    country: '',
    promocode: '',
  },
  instructionAndroid: ['', '', ''],
  instructionIOS: ['', '', ''],
  comments: [],
  videoDescription: [],
};

export const base_bloggers = {
  appIcon: null,
  appName: '',
  slides: [],
  inscriptions: {
    menu_1: 'Reviews',
    menu_2: 'Training',
    menu_3: 'Contact us',
    title: 'Recommendations from bloggers',
    deposit_title: "Make a deposit and we'll multiply it by 5",
    currency: '₹',
    deposit: 'Deposited',
    profit: 'Profit',
    howInstall_title: 'Over 12 million users trust our application',
    howInstall_subtitle: 'Follow these easy steps to install the app and start making money now',
    howInstall_button: 'Get started now',
    howInstall_onlineSupport: '24/7',
    howInstall_time: 'hours online support',
    howInstall_multiple: 'Multiple',
    howInstall_type: 'sports',
    howInstall_rating: '4.78 out of 5',
    howInstall_userRating: 'user rating',
    howInstall_bonus: '₹50,000.00',
    howInstall_welcomeBonus: 'welcome bonus',
    comments_title: 'You can take our word for it',
    video_title: 'Our app is ready to use',
    footer_title: 'Any other questions?',
    promocode: 'Promo code',
    play: 'Play',
    copy: 'Copy',
    patent: '2024 1win',
    link_1: '',
    link_2: '',
    link_3: '',
  },
  instagram: {
    avatar: '',
    name: '',
    country: '',
    promocode: '',
  },
  instructionAndroid: ['', '', ''],
  instructionIOS: ['', '', ''],
  comments: [],
  videoDescription: [],
};
