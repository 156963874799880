/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState, useRef} from 'react';

const Lucky = ({data, currentLang, id}) => {
  const iframeRef = useRef(null);
  const [firstRendering, setFirstRendering] = useState(false);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        {...data[currentLang], id, currentLang},
        'https://pwa.panelhunt.io/lucky-jet/'
        // '*'
      );
    }

    if (!firstRendering) {
      setTimeout(() => {
        if (iframeRef.current) {
          iframeRef.current.contentWindow.postMessage(
            {...data[currentLang], id, currentLang},
            'https://pwa.panelhunt.io/lucky-jet/'
            // '*'
          );
        }
      }, 1000);
      setFirstRendering(true);
    }
  }, [data, currentLang, id]);

  return (
    <>
      <iframe
        ref={iframeRef}
        src='https://pwa.panelhunt.io/lucky-jet/'
        // src='http://localhost:3000/lucky-jet/'
        title='Child iFrame'
        style={{width: '100%', height: '100%', borderRadius: '30px'}}
      ></iframe>
    </>
  );
};

export default Lucky;
