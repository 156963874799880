import classNames from 'classnames';

const Navigation = ({links = [], page, setPage, action = null}) => {
  return (
    <>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold flex-nowrap overflow-x-auto overflow-y-hidden'>
        {links.map((el, i) => {
          return (
            <li
              className='nav-item mt-2 text-nowrap'
              style={{cursor: 'pointer', marginBottom: '0px'}}
              onClick={() => {
                setPage(el.link);
                !!action && action(el);
              }}
              key={el.link}
            >
              <span
                className={classNames(
                  'nav-link text-active-primary ms-0 me-10 py-5',
                  page === `${el.link}` && 'active'
                )}
              >
                {el.title}
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Navigation;
