/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useStore} from '../../../../store/store';
import moment from 'moment';
import {Button} from '../../../shared/Buttons';
import InputSearch from '../../../shared/InputSearch';
import {Selector} from '../../../shared/Selectors'
import useDebounce from '../../../hooks/useDebounce';

import imagePlug from '../../../../_metronic/assets/media/user.png';
import Card from '../../../shared/Card';
import PageWrapper from '../../../modules/PageWrapper';
import PaginationBe from '../../../shared/PaginationBe';

const OffersWrapper = () => {
  return (
    <PageWrapper
      breadcrumbs={[
        {title: 'PanelHunt', path: ''},
        {isSeparator: true},
        {title: 'Офферы', path: 'advertisers/offers', isActive: true},
      ]}
      title={'Офферы'}
    >
      <Offers />
    </PageWrapper>
  );
};

const Offers = observer(({isVerify, setPreloader}) => {
  const store = useStore();

  const [offers, setOffers] = useState([]);
  const [searchOffers, setSearchOffers] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [advertisers, setAdvertisers] = useState([]);
  const [advertisersList, setAdvertisersList] = useState([]);
  const [choosedAdvertiserId, setChoosedAdvertiserId] = useState('');
  const [isInitialDateReceived, setIsInitialDateReceived] = useState('');

  const debouncedSearchValue = useDebounce(searchValue, 400);

  useEffect(() => {
    isInitialDateReceived && isVerify && getOffers();

  }, [isInitialDateReceived, isVerify, perPage, page, debouncedSearchValue, choosedAdvertiserId]);

  useEffect(() => {
    isVerify && getOffersWithAdvertisers();
    setIsInitialDateReceived(true);
  }, [isVerify]);

  const getOffersWithAdvertisers = async () => {
    const [offersListPage, getAdvertisersList] = await Promise.all([
      store.getOffersListPage(perPage, page, debouncedSearchValue, choosedAdvertiserId),
      store.getAdvertisersList(),
    ]);

    setAdvertisers([
      {value: '', label: 'Все'},
      ...getAdvertisersList.map(a => ({value: a.id, label: a.name}))
    ])

    const newOffers = offersListPage.rows.map((el) => {
      const findItem = getAdvertisersList.find((item) => item.id === el.advertiser_id);
      return {...el, avatar: findItem?.avatar};
    });

    setAdvertisersList(getAdvertisersList)
    setSearchOffers([...newOffers]);
    setOffers([...newOffers]);
    setPreloader(false);
    setTotal(offersListPage.total)
  };

  const getOffers = async () => {
    const offersListPage = await store.getOffersListPage(perPage, page, searchValue, choosedAdvertiserId)

    const newOffers = offersListPage.rows.map((el) => {
      const findItem = advertisersList.find((item) => item.id === el.advertiser_id);
      return {...el, avatar: findItem?.avatar};
    });

    setSearchOffers([...newOffers]);
    setOffers([...newOffers]);
    setPreloader(false);
    setTotal(offersListPage.total)
  }

  return (
    <>
      <Card className='card'>
        <div className='card-header border-0 pt-6'>
          <div className='card-title table-filters-block'>
            <InputSearch
              title={'Поиск...'}
              array={offers}
              newArray={setSearchOffers}
              searchParameters={['id', 'name']}
              onChangeValue={setSearchValue}
              isBeSearch
            />
            <Selector styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '44px',
                }),
              }} onChange={setChoosedAdvertiserId} options={advertisers} />
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              {store.roles.includes(13) || store.roles.includes(32) ? (
                <></>
              ) : (
                <Link to={'/advertisers/offers/add'}>
                  <Button>
                    <>
                      <i className='ki-duotone ki-plus fs-2'></i>Добавить
                    </>
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
        <Table
          total={total}
          offers={offers}
          getOffers={getOffers}
          searchOffers={searchOffers}
          page={page}
          setPage={setPage}
          perPage={perPage}
          changePerPage={setPerPage}
          onPaginationChange={setPage}
        />
      </Card>
    </>
  );
});

const Table = ({offers, getOffers, searchOffers, total, page, setPage, onPaginationChange, changePerPage, perPage}) => {
  return (
    <div className='card-body py-4'>
      <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5'>
            <thead>
              <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>оффер</th>
                <th className='min-w-125px'>ставка</th>
                <th className='min-w-125px'>бейслайн</th>
                <th className='min-w-125px'>создан</th>
                <th className='text-end min-w-100px'>Действие</th>
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-semibold'>
              {searchOffers.map((el, i) => {
                return <Offer key={el.id} data={el} getOffers={getOffers} />;
              })}
            </tbody>
          </table>
          {searchOffers.length > 0 && (
            <PaginationBe
              arrLength={total}
              page={page}
              limit={perPage}
              perPage={perPage}
              changePerPage={changePerPage}
              setPage={setPage}
              action={(currentPage) => {
                onPaginationChange(currentPage)
              }}
              menuPlacement='top'
              options={[
                {label: 4, value: 4},
                {label: 8, value: 8},
                {label: 16, value: 16},
                {label: 24, value: 24},
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const Offer = observer(({data}) => {
  const store = useStore();
  return (
    <tr>
      <td className='d-flex align-items-center'>
        <div className='d-flex align-items-center'>
          <div className='me-5 position-relative'>
            <div className='symbol symbol-35px symbol-circle'>
              <img
                src={data.avatar ? `${process.env.REACT_APP_API_HOST}/${data.avatar}` : imagePlug}
                alt=''
              />
            </div>
          </div>
          <div className='d-flex flex-column justify-content-center'>
            <span className='fs-6 text-gray-800 text-hover-primary'>{data.name}</span>
            <div className='fw-semibold text-gray-400'>#{data.id}</div>
          </div>
        </div>
      </td>
      <td>
        <div className='badge badge-light-success'>{data.bid}$</div>
      </td>
      <td>
        <div className='badge badge-light fw-bold'>{data.baseline}$</div>
      </td>
      <td>{moment(data.created_at * 1000).format('DD.MM.YYYY, HH:mm')}</td>
      <td className='text-end'>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <Link to={`/advertisers/offers/${data.id}`}>
            <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className='ki-duotone ki-switch fs-2'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </span>
          </Link>
          {store.roles.includes(13) || store.roles.includes(32) ? (
            <></>
          ) : (
            <Link to={`/advertisers/offers/updateOffer/${data.id}`}>
              <span className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                <i className='ki-duotone ki-pencil fs-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </span>
            </Link>
          )}
        </div>
      </td>
    </tr>
  );
});

export default observer(OffersWrapper);
