import React, {useState, useEffect} from 'react';
import css from '../../../styles/PwaConstructor.module.scss';
import classNames from 'classnames';
import BlockTitles from './templates/BlockTitles';
import BlockLine from './templates/BlockLine';
import {SelectorWithSearch} from '../../shared/Selectors';

const DomainForm = ({
  data,
  setData,
  domains,
  saveDomain,
  errors,
  subDomains,
  validSubDomain,
  setValidSubDomain,
  isUpdate,
}) => {
  const validationSubDomain = (e) => {
    const {value} = e.target;
    const find = subDomains.findIndex(
      (item) =>
        `${item.name}` ===
          `${value}.${domains.find((item) => item.value === data.domain_id).label}` &&
        data.subdomain_id !== item.id
    );
    find !== -1 && setValidSubDomain(false);
    find === -1 && setValidSubDomain(true);
    setData({...data, subdomain: value.replace(/[^a-z-0-9]/g, '')});
  };
  // "pwa.raulnk.com"
  return (
    <div className={css.nameForm}>
      <BlockTitles title={'Домен'} />
      <BlockLine />

      <div style={{marginTop: '20px', display: 'flex', flexDirection: 'column'}}>
        <span className={css.subTitle}>
          Выберите домен который вам понравился и придумайте субдомен
        </span>
        <span className={css.text}>
          Все домены уже настроены и функционируют. Дополнительных настроек не требуется
        </span>
        <label htmlFor='' style={{width: '100%', marginBottom: '16px'}}>
          <SelectorWithSearch
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: '45px',
                border: errors.includes('domain_id') && '2px solid #F8285A !important',
                boxShadow: errors.includes('domain_id') && 'none',
              }),
            }}
            isDisabled={isUpdate}
            placeholder='Выберите домен'
            options={domains}
            value={domains.find((item) => item.value === data.domain_id)}
            setValue={(e) => setData({...data, domain_id: e.value})}
            actions={() => {}}
          />
        </label>
        <label htmlFor='' className={css.inputField}>
          <span>
            Subdomain{' '}
            {!validSubDomain && <span style={{color: '#F8285A', margin: '0'}}>уже существует</span>}
          </span>
          <input
            style={
              !validSubDomain || errors.includes('subdomain')
                ? {border: '2px solid #F8285A', outline: 'none'}
                : {}
            }
            type='text'
            disabled={isUpdate}
            value={data.subdomain || ''}
            onChange={validationSubDomain}
          />
        </label>
      </div>
    </div>
  );
};

export default DomainForm;
