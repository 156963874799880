import React, {useState, useEffect, useRef} from 'react';
import css from './styles.module.scss';
import {useStore} from '../../../store/store';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {useThemeMode} from '../../../_metronic/partials';
import InputSearch from '../InputSearch';

const FilterWithGroupWithoutSelectedOptions = ({
  classButton,
  placeholder = 'Поиск...',
  title,
  index = 0,
  options = [],
  selectedOptions = [],
  onChange,
  apply,
}) => {
  const store = useStore();
  const theme = useThemeMode();
  const [mode, setMode] = useState('');
  const containerRef = useRef(null);

  const [checkedSelector, setCheckedSelector] = useState(false);
  const [searchOptions, setSearchOptions] = useState([]);

  const [optionsLength, setOptionLength] = useState(0);

  const handleBlur = (event) => {
    // Перевіряємо, чи залишається фокус всередині контейнера
    if (containerRef.current && !containerRef.current.contains(event.relatedTarget)) {
      setCheckedSelector(false);
    }
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setMode('dark');
      } else {
        setMode('light');
      }
    } else {
      setMode(theme.mode);
    }
  }, [theme]);

  useEffect(() => {
    setOptionLength(
      options
        .flatMap((obj) => obj.options)
        .filter((opt, index, self) => index === self.findIndex((o) => o.value === opt.value))
    );
  }, [options]);

  return (
    <>
      <div
        className={css.container}
        style={{
          '--bg': mode === 'dark' ? 'rgba(27, 29, 35, 1)' : '#FFFFFF',
          '--itemHover': mode === 'dark' ? '#4d5863' : '#f4f6f9',
          '--buttonHover': mode === 'dark' ? 'rgba(258, 258, 258, 0.05)' : 'rgba(0, 0, 0, 0.05)',
          '--borderColor': mode === 'dark' ? 'hsl(0, 0%, 20%)' : 'hsl(0, 0%, 80%)',
          '--iconAddFilter':
            mode === 'dark'
              ? 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI5IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMTAgOSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTEwIC42MjVjMCAuMzQ2LS4yOC42MjUtLjYyNS42MjVILjYyNWEuNjI0LjYyNCAwIDEgMSAwLTEuMjVoOC43NWMuMzQ2IDAgLjYyNS4yOC42MjUuNjI1Wk02LjA0MiA3LjVIMy45NThhLjYyNC42MjQgMCAxIDAgMCAxLjI1aDIuMDg0YS42MjQuNjI0IDAgMSAwIDAtMS4yNVptMS42NjYtMy43NUgyLjI5MmEuNjI0LjYyNCAwIDEgMCAwIDEuMjVoNS40MTZhLjYyNC42MjQgMCAxIDAgMC0xLjI1WiIvPjwvc3ZnPg==")'
              : 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI5IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMTAgOSI+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTEwIC42MjVjMCAuMzQ2LS4yOC42MjUtLjYyNS42MjVILjYyNWEuNjI0LjYyNCAwIDEgMSAwLTEuMjVoOC43NWMuMzQ2IDAgLjYyNS4yOC42MjUuNjI1Wk02LjA0MiA3LjVIMy45NThhLjYyNC42MjQgMCAxIDAgMCAxLjI1aDIuMDg0YS42MjQuNjI0IDAgMSAwIDAtMS4yNVptMS42NjYtMy43NUgyLjI5MmEuNjI0LjYyNCAwIDEgMCAwIDEuMjVoNS40MTZhLjYyNC42MjQgMCAxIDAgMC0xLjI1WiIvPjwvc3ZnPg==")',
          '--iconAddGroup':
            mode === 'dark'
              ? 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjkiIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA5IDkiPjxnIGNsaXAtcGF0aD0idXJsKCNhKSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTguNDM4IDMuOTM4SDUuMDYyVi41NjJhLjU2My41NjMgMCAwIDAtMS4xMjUgMHYzLjM3NUguNTYzYS41NjMuNTYzIDAgMCAwIDAgMS4xMjZoMy4zNzV2My4zNzVhLjU2My41NjMgMCAwIDAgMS4xMjQgMFY1LjA2MmgzLjM3NWEuNTYzLjU2MyAwIDAgMCAwLTEuMTI1WiIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDBoOXY5SDB6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+")'
              : 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjkiIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA5IDkiPjxnIGNsaXAtcGF0aD0idXJsKCNhKSI+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTguNDM4IDMuOTM4SDUuMDYyVi41NjJhLjU2My41NjMgMCAwIDAtMS4xMjUgMHYzLjM3NUguNTYzYS41NjMuNTYzIDAgMCAwIDAgMS4xMjZoMy4zNzV2My4zNzVhLjU2My41NjMgMCAwIDAgMS4xMjQgMFY1LjA2MmgzLjM3NWEuNTYzLjU2MyAwIDAgMCAwLTEuMTI1WiIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDBoOXY5SDB6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+")',
        }}
        ref={containerRef}
        tabIndex={index}
        onBlur={handleBlur}
      >
        <label className={classNames(css[`${classButton}`])} htmlFor={`checkboxG-${index}`}>
          <span className={css[`${classButton}Title`]}>{title}</span>
          <input
            checked={checkedSelector}
            type='checkbox'
            name=''
            id={`checkboxG-${index}`}
            onChange={(e) => setCheckedSelector(e.target.checked)}
          />
          <span className={css.hoverEffect}></span>
        </label>
        <div className={css.dropdown}>
          <section className={css.listWrapper}>
            <div className={css.searchWrapper}>
              <InputSearch
                style={
                  mode === 'dark'
                    ? {border: '1px solid hsl(0, 0%, 20%)', height: '36px', fontSize: '13px'}
                    : {border: '1px solid hsl(0, 0%, 80%)', height: '36px', fontSize: '13dgsdpx'}
                }
                title={placeholder}
                array={optionsLength}
                newArray={(e) => {
                  if (e.length === optionsLength.length) {
                    setSearchOptions([]);
                  } else if (e.length === 0) {
                    setSearchOptions([]);
                  } else {
                    setSearchOptions(e);
                  }
                }}
                searchParameters={['label']}
              />
            </div>
            <ul className={css.list}>
              {searchOptions.length === 0 &&
                options
                  .filter(
                    (opt) => !selectedOptions?.some((selected) => selected.value === opt.value)
                  )
                  .map((opt, i) => (
                    <React.Fragment key={opt.label}>
                      {!!opt.options && opt.options.length > 0 && (
                        <li className={classNames(css.item, css.group)}>
                          <span className={classNames(css.label, 'text-gray-700')}>
                            {opt.label}
                          </span>
                          <ul className={classNames(css.groupOptions)}>
                            {opt?.options?.map((subOpt) => {
                              return (
                                <li
                                  className={css.item}
                                  key={subOpt.value}
                                  onClick={() => {
                                    setCheckedSelector(false);
                                    apply(subOpt);
                                    store.setFocusFilter(subOpt.value);
                                  }}
                                >
                                  <span>{subOpt.label}</span>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
              {searchOptions.length > 0 &&
                searchOptions
                  .filter(
                    (opt) => !selectedOptions?.some((selected) => selected.value === opt.value)
                  )
                  .map((opt, i) => (
                    <React.Fragment key={opt.value}>
                      <li
                        className={classNames(css.item)}
                        onClick={() => {
                          setCheckedSelector(false);
                          apply(opt);
                        }}
                      >
                        <span>{opt.label}</span>
                      </li>
                    </React.Fragment>
                  ))}
            </ul>
          </section>
        </div>
      </div>
    </>
  );
};

export default observer(FilterWithGroupWithoutSelectedOptions);
