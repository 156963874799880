/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, forwardRef} from 'react';
import css from '../../../../../styles/PwaConstructor.module.scss';
import classNames from 'classnames';
import imagePlug from '../../../../../_metronic/assets/media/user.png';
import BlockLine from '../BlockLine';
import BlockTitles from '../BlockTitles';
import {base_bloggers, bloggers} from '../objectsTemplates';

const Form = ({themeMode, config, setConfig, currentLang, post, id, token, createPwa, setId}) => {
  const [timeVersion, setTimeVersion] = useState(0);

  const deleteImage = async (id, name, dir) => {
    fetch('https://api.raulnk.com/uploads', {
      method: 'DELETE',
      body: JSON.stringify({
        id: +id,
        lang: currentLang,
        name,
        dir,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleIcon = async (e) => {
    let newId = null;
    if (!id) {
      newId = await createPwa();
      setId(newId);
    }
    const renamedFile = new File([e.target.files[0]], 'icon.png', {type: e.target.files[0].type});

    if (!!config[currentLang]?.appIcon) {
      await deleteImage(id || newId, config[currentLang]?.appIcon, 'images/icons');
    }

    const formData = new FormData();
    formData.append('file', renamedFile); // Додаємо файл у форму
    formData.append('id', id || newId);
    formData.append('lang', currentLang);
    formData.append('name', 'icon.png');
    formData.append('dir', 'images/icons');

    fetch('https://api.raulnk.com/uploads', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        setConfig({
          ...config,
          [currentLang]: {
            ...config[currentLang],
            appIcon: 'icon.png',
          },
        });
        setTimeVersion(new Date().getTime());
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleInstagramAvatar = async (e) => {
    let newId = null;
    if (!id) {
      newId = await createPwa();
      setId(newId);
    }
    const renamedFile = new File([e.target.files[0]], 'avatar.png', {type: e.target.files[0].type});

    if (!!config[currentLang]?.instagram?.avatar) {
      await deleteImage(
        id || newId,
        config[currentLang]?.instagram?.avatar,
        'images/instagram/avatar'
      );
    }

    const formData = new FormData();
    formData.append('file', renamedFile); // Додаємо файл у форму
    formData.append('id', id || newId);
    formData.append('lang', currentLang);
    formData.append('name', 'avatar.png');
    formData.append('dir', 'images/instagram/avatar');

    fetch('https://api.raulnk.com/uploads', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setConfig({
          ...config,
          [currentLang]: {
            ...config[currentLang],
            instagram: {
              ...config[currentLang].instagram,
              avatar: 'avatar.png',
            },
          },
        });
        setTimeVersion(new Date().getTime());
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleInstruction = async (e, index, name) => {
    let newId = null;
    if (!id) {
      newId = await createPwa();
      setId(newId);
    }

    const file = e.target.files[0];

    if (!!config[currentLang]?.[`instruction${name}`][index]) {
      await deleteImage(
        id || newId,
        config[currentLang]?.[`instruction${name}`][index],
        `images/instructions${name}`
      );
    }

    const newInst = [...config[currentLang][`instruction${name}`]];
    newInst[index] = `slide-${name}-${index}.png`;

    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('id', id || newId);
    formData.append('lang', currentLang);
    formData.append('name', `slide-${name}-${index}.png`);
    formData.append('dir', `images/instructions${name}`);

    fetch('https://api.raulnk.com/uploads', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);

        setConfig({
          ...config,
          [currentLang]: {...config[currentLang], [`instruction${name}`]: newInst},
        });
        setTimeVersion(new Date().getTime());
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const changeComments = (e, index) => {
    const {name, value} = e.target;
    setConfig((prevConfig) => {
      const updatedComments = [...prevConfig[currentLang].comments];
      updatedComments[index] = {...updatedComments[index], [name]: value};
      return {
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          comments: updatedComments,
        },
      };
    });
  };

  const deleteComment = async (index, name) => {
    await deleteImage(id, `${name}`, 'images/users');

    setConfig((prevConfig) => {
      const updatedComments = [...prevConfig[currentLang].comments];
      updatedComments.splice(index, 1);
      return {
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          comments: updatedComments,
        },
      };
    });
  };

  const handleAvatar = async (e, index, name) => {
    let newId = null;
    if (!id) {
      newId = await createPwa();
      setId(newId);
    }

    if (!!config[currentLang]?.comments[index]?.avatar) {
      await deleteImage(id || newId, config[currentLang]?.comments[index].avatar, 'images/users');
    }

    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Додаємо файл у форму
    formData.append('id', id || newId);
    formData.append('lang', currentLang);
    formData.append('name', !name ? `${index}-avatar.png` : name);
    formData.append('dir', 'images/users');

    fetch('https://api.raulnk.com/uploads', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setConfig((prevConfig) => {
          const updatedComments = [...prevConfig[currentLang].comments];
          updatedComments[index] = {
            ...updatedComments[index],
            avatar: !name ? `${index}-avatar.png` : name,
          };
          return {
            ...prevConfig,
            [currentLang]: {
              ...prevConfig[currentLang],
              comments: updatedComments,
            },
          };
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const hideComment = (index) => {
    setConfig((prevConfig) => {
      const updatedComments = [...prevConfig[currentLang].comments];
      updatedComments[index] = {...updatedComments[index], hide: !updatedComments[index].hide};
      return {
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          comments: updatedComments,
        },
      };
    });
  };

  const addReviews = () => {
    setConfig({
      ...config,
      [currentLang]: {
        ...config[currentLang],
        comments: [
          ...(config[currentLang]?.comments || []),
          {
            avatar: '',
            author: '',
            comment: '',
            type: 'review',
            hide: false,
          },
        ],
      },
    });
  };

  //videoDescription////////////////////////////

  const addVideoDescription = () => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [currentLang]: {
        ...prevConfig[currentLang],
        videoDescription: [
          ...(prevConfig[currentLang]?.videoDescription || []),
          {
            avatar: '',
            title: '',
            list: [],
            videoName: '',
            type: 'video',
            hide: false,
          },
        ],
      },
    }));
  };

  const changeVideoDescription = (e, index) => {
    const {name, value} = e.target;
    setConfig((prevConfig) => {
      const newVideoDescription = [...prevConfig[currentLang].videoDescription];
      newVideoDescription[index] = {...newVideoDescription[index], [name]: value};
      return {
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          videoDescription: newVideoDescription,
        },
      };
    });
  };

  const deleteVideoDescription = async (index, name) => {
    await deleteImage(id, `${name}`, 'images/videoDescription');

    setConfig((prevConfig) => {
      const updatedVideoDescription = [...prevConfig[currentLang].videoDescription];
      updatedVideoDescription.splice(index, 1);
      return {
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          videoDescription: updatedVideoDescription,
        },
      };
    });
  };

  const handleVideoDescription = async (e, index, name) => {
    let newId = null;
    if (!id) {
      newId = await createPwa();
      setId(newId);
    }

    const file = e.target.files[0];

    if (!!config[currentLang]?.videoDescription[index]?.videoName) {
      await deleteImage(
        id || newId,
        config[currentLang]?.videoDescription[index].videoName,
        'videos/videoDescription'
      );
    }

    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('id', id || newId);
    formData.append('lang', currentLang);
    formData.append('name', !name ? `${index}-video.mp4` : name);
    formData.append('dir', 'videos/videoDescription');

    fetch('https://api.raulnk.com/uploads', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);

        setConfig((prevConfig) => {
          const updatedVideoDescription = [...prevConfig[currentLang].videoDescription];
          updatedVideoDescription[index] = {
            ...updatedVideoDescription[index],
            videoName: !name ? `${index}-video.mp4` : name,
          };
          return {
            ...prevConfig,
            [currentLang]: {
              ...prevConfig[currentLang],
              videoDescription: updatedVideoDescription,
            },
          };
        });
        setTimeVersion(new Date().getTime());
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleAvatarVideoDescription = async (e, index, name) => {
    let newId = null;
    if (!id) {
      newId = await createPwa();
      setId(newId);
    }

    const file = e.target.files[0];

    if (!!config[currentLang]?.videoDescription[index]?.avatar) {
      await deleteImage(
        id || newId,
        config[currentLang]?.videoDescription[index].avatar,
        'images/videoDescription'
      );
    }

    const formData = new FormData();
    formData.append('file', e.target.files[0]); // Додаємо файл у форму
    formData.append('id', id || newId);
    formData.append('lang', currentLang);
    formData.append('name', `${index}-avatar.png`);
    formData.append('dir', 'images/videoDescription');

    fetch('https://api.raulnk.com/uploads', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);

        setConfig((prevConfig) => {
          const updatedVideoDescription = [...prevConfig[currentLang].videoDescription];
          updatedVideoDescription[index] = {
            ...updatedVideoDescription[index],
            avatar: `${index}-avatar.png`,
          };

          return {
            ...prevConfig,
            [currentLang]: {
              ...prevConfig[currentLang],
              videoDescription: updatedVideoDescription,
            },
          };
        });
        setTimeVersion(new Date().getTime());
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const hideVideoDescription = (index) => {
    setConfig((prevConfig) => {
      const newVideoDescription = [...prevConfig[currentLang].videoDescription];
      newVideoDescription[index] = {
        ...newVideoDescription[index],
        hide: !newVideoDescription[index].hide,
      };
      return {
        ...prevConfig,
        [currentLang]: {
          ...prevConfig[currentLang],
          videoDescription: newVideoDescription,
        },
      };
    });
  };

  const blockHeader = [
    {placeholders: ['Пункт меню 1'], keys: ['menu_1']},
    {placeholders: ['Пункт меню 2'], keys: ['menu_2']},
    {placeholders: ['Пункт меню 3'], keys: ['menu_3']},
    {placeholders: ['Заголовок'], keys: ['title']},
  ];

  const blockDeposit = [
    {placeholders: ['Валюта'], keys: ['currency']},
    {placeholders: ['Заголовок'], keys: ['deposit_title']},
    {placeholders: ['Deposit'], keys: ['deposit']},
    {placeholders: ['Profit'], keys: ['profit']},
  ];

  const blockHowInstall = [
    {placeholders: ['Заголовок'], keys: ['howInstall_title']},
    {placeholders: ['Подзаголопок'], keys: ['howInstall_subtitle']},
    {placeholders: ["Button 'Get Started'"], keys: ['howInstall_button']},
    {
      placeholders: ['Online support', 'Time'],
      keys: ['howInstall_onlineSupport', 'howInstall_time'],
    },
    {placeholders: ['Multiple', 'Type'], keys: ['howInstall_multiple', 'howInstall_type']},
    {placeholders: ['Rating', 'User rating'], keys: ['howInstall_rating', 'howInstall_userRating']},
    {
      placeholders: ['Bonus', 'Welcome bonus'],
      keys: ['howInstall_bonus', 'howInstall_welcomeBonus'],
    },
  ];

  const blockComments = [{placeholders: ['Заголовок'], keys: ['comments_title']}];

  const blockVideo = [{placeholders: ['Заголовок'], keys: ['video_title']}];

  const blockFooter = [
    {placeholders: ['Заголовок'], keys: ['footer_title']},
    {placeholders: ['Патент'], keys: ['patent']},
    {placeholders: ['Link 1'], keys: ['link_1']},
    {placeholders: ['Link 2'], keys: ['link_2']},
    {placeholders: ['Link 3'], keys: ['link_3']},
  ];

  const blockInstagram = [
    {placeholders: ['Promo code'], keys: ['promocode']},
    {placeholders: ['Copy', 'Play'], keys: ['copy', 'play']},
  ];

  const handleInputChange = (key, value) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [currentLang]: {
        ...prevConfig[currentLang],
        inscriptions: {
          ...prevConfig[currentLang].inscriptions,
          [key]: key === 'countDownloads' ? value.replace(/\D/g, '') : value,
        },
      },
    }));
  };

  return (
    <>
      <BlockTitles
        title={'Приложение'}
        description={'На данном этапе настраивается внешний вид PWA и текстовое содержание.'}
      />
      <BlockLine />
      <div className={css.blockConfig}>
        <span>
          Использовать базовую конфигурацию (если вы выберете этот вариант, контент на этой вкладке
          будет перезаписан):
        </span>
        <input
          checked={config[currentLang]?.base || false}
          type='checkbox'
          onChange={(e) => {
            if (!!e.target.checked) {
              setConfig({
                ...config,
                [currentLang]: {
                  ...config[currentLang],
                  inscriptions: base_bloggers.inscriptions,
                  base: e.target.checked,
                },
              });
            } else {
              setConfig({
                ...config,
                [currentLang]: {
                  ...config[currentLang],
                  inscriptions: bloggers.inscriptions,
                  base: e.target.checked,
                },
              });
            }
          }}
        />
      </div>
      <BlockLine />
      <div className={css.blockHeader}>
        <span className={css.text}>Иконка</span>
        <div
          className={css.icon}
          style={{
            backgroundImage: !config[currentLang]?.appIcon
              ? `url(${imagePlug})`
              : `url(https://api.raulnk.com/${id}/images/icons/${currentLang}/${config[currentLang]?.appIcon}?v=${timeVersion})`,
          }}
        >
          <label htmlFor='appIcon'>
            <div className={classNames(css.delete)}></div>

            <input
              type='file'
              accept='.png, .jpg, .jpeg'
              name=''
              id='appIcon'
              onChange={handleIcon}
            />
          </label>
        </div>
      </div>
      <div className={css.inputs}>
        <label htmlFor='' className={css.inputField}>
          <span>Название приложения</span>
          <input
            type='text'
            value={config[currentLang]?.appName || ''}
            onChange={(e) =>
              setConfig({
                ...config,
                [currentLang]: {...config[currentLang], appName: e.target.value},
              })
            }
          />
        </label>
      </div>
      <BlockLine />
      <BlockTitles
        title={'Instagram'}
        description={'Заполните инстаграм блогера, добавте промокод'}
      />
      <div className={css.blockInstagram}>
        <div
          className={css.avatar}
          style={{
            backgroundImage: !config[currentLang]?.instagram?.avatar
              ? `url(${imagePlug})`
              : `url(https://api.raulnk.com/${id}/images/instagram/avatar/${currentLang}/${config[currentLang]?.instagram?.avatar}?v=${timeVersion})`,
          }}
        >
          <label htmlFor='instagramAvatar'>
            <div className={classNames(css.delete)}></div>

            <input
              type='file'
              accept='.png, .jpg, .jpeg'
              id='instagramAvatar'
              onChange={handleInstagramAvatar}
            />
          </label>
        </div>
        <div
          style={{
            width: '100%',
          }}
        >
          <label
            htmlFor=''
            className={css.inputField}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: '16px',
            }}
          >
            <input
              type='text'
              placeholder='Имя'
              value={config[currentLang]?.instagram?.name || ''}
              onChange={(e) =>
                setConfig({
                  ...config,
                  [currentLang]: {
                    ...config[currentLang],
                    instagram: {...config[currentLang]?.instagram, name: e.target.value},
                  },
                })
              }
            />
            <input
              type='text'
              placeholder='Страна'
              value={config[currentLang]?.instagram?.country || ''}
              onChange={(e) =>
                setConfig({
                  ...config,
                  [currentLang]: {
                    ...config[currentLang],
                    instagram: {...config[currentLang]?.instagram, country: e.target.value},
                  },
                })
              }
            />
            <input
              type='text'
              placeholder='Промокод'
              value={config[currentLang]?.instagram?.promocode || ''}
              onChange={(e) =>
                setConfig({
                  ...config,
                  [currentLang]: {
                    ...config[currentLang],
                    instagram: {...config[currentLang]?.instagram, promocode: e.target.value},
                  },
                })
              }
            />
          </label>
        </div>
      </div>
      <BlockLine />
      <BlockTitles title={'Слайды "Как установить приложение"'} />
      <div className={css.blockInstruction}>
        <BlockTitles description={'Инструкция Android'} />
        <div className={css.cards}>
          {config[currentLang]?.instructionAndroid?.map((item, index) => {
            return (
              <div
                className={css.card}
                key={index}
                style={{
                  backgroundImage: !item
                    ? `url(${imagePlug})`
                    : `url(https://api.raulnk.com/${id}/images/instructionsAndroid/${currentLang}/${item}?v=${timeVersion})`,
                }}
                htmlFor={`android-${index}`}
              >
                <label className={classNames(css.delete)}>
                  <input
                    type='file'
                    name=''
                    id={`android-${index}`}
                    onChange={(e) => handleInstruction(e, index, 'Android')}
                  />
                </label>
              </div>
            );
          })}
        </div>
        <BlockTitles description={'Инструкция IOS'} />
        <div className={css.cards}>
          {config[currentLang]?.instructionIOS?.map((item, index) => {
            return (
              <div
                className={css.card}
                key={index}
                style={{
                  backgroundImage: !item
                    ? `url(${imagePlug})`
                    : `url(https://api.raulnk.com/${id}/images/instructionsIOS/${currentLang}/${item}?v=${timeVersion})`,
                }}
              >
                <label htmlFor={`ios-${index}`} className={classNames(css.delete)}>
                  <input
                    type='file'
                    name=''
                    id={`ios-${index}`}
                    onChange={(e) => handleInstruction(e, index, 'IOS')}
                  />
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <BlockLine />
      <BlockTitles title={'Комментарии'} description={'Добавте коментарии пользователей'} />
      <div className={css.blockComments}>
        {config[currentLang]?.comments?.map((item, index) => {
          return (
            <div
              className={classNames(css.comment, item.hide && css._hide)}
              key={`comment-${index}`}
            >
              <div className={css.header} style={{justifyContent: 'space-between'}}>
                <label
                  className={css.avatar}
                  htmlFor={`commentAvatar-${index}`}
                  style={
                    item.avatar
                      ? {
                          backgroundImage: `url(https://api.raulnk.com/${id}/images/users/${currentLang}/${item.avatar}?v=${timeVersion})`,
                        }
                      : {}
                  }
                >
                  <input
                    type='file'
                    accept='.png, .jpg, .jpeg'
                    name=''
                    id={`commentAvatar-${index}`}
                    onChange={(e) => handleAvatar(e, index, item.avatar)}
                  />
                </label>
                <div className={css.actions}>
                  <div
                    className={classNames(
                      css.hide,
                      themeMode === 'dark' && css._dark,
                      item.hide && css._hide
                    )}
                    onClick={() => hideComment(index)}
                  ></div>
                  <div className={css.delete} onClick={(e) => deleteComment(index, item.avatar)} />
                </div>
              </div>
              <div className={css.body}>
                <label htmlFor='' className={css.inputField}>
                  <span>Автор комментария</span>
                  <input
                    type='text'
                    name='author'
                    value={item.author || ''}
                    onChange={(e) => changeComments(e, index)}
                  />
                </label>
                <label htmlFor='' className={css.inputField}>
                  <textarea
                    name='comment'
                    id=''
                    placeholder='Текст коментария'
                    value={item.comment || ''}
                    onChange={(e) => changeComments(e, index)}
                  ></textarea>
                </label>
              </div>
            </div>
          );
        })}
      </div>
      <button className={css.addComment} onClick={addReviews}>
        Добавить комментарий +
      </button>
      <BlockLine />
      <BlockTitles title={'Видео описание'} description={'Добавте видео описание'} />
      <div className={css.blockComments}>
        {config[currentLang]?.videoDescription?.map((item, index) => {
          return (
            <div
              className={classNames(css.comment, item.hide && css._hide)}
              key={`comment-${index}`}
            >
              <div className={css.header} style={{justifyContent: 'space-between'}}>
                <div className={css.videoBlock}>
                  <label
                    className={css.videoAvatar}
                    htmlFor={`videoAvatar-${index}`}
                    style={
                      item.avatar
                        ? {
                            backgroundImage: `url(https://api.raulnk.com/${id}/images/videoDescription/${currentLang}/${item.avatar}?v=${timeVersion})`,
                          }
                        : {}
                    }
                  >
                    <input
                      type='file'
                      accept='.png, .jpg, .jpeg'
                      name=''
                      id={`videoAvatar-${index}`}
                      onChange={(e) => handleAvatarVideoDescription(e, index, item.avatar)}
                    />
                  </label>
                  <label
                    className={css.video}
                    htmlFor={`video-${index}`}
                    style={
                      item.videoName
                        ? {
                            backgroundImage: `url(https://api.raulnk.com/${id}/images/videoDescription/${currentLang}/${item.avatar}?v=${timeVersion})`,
                          }
                        : {}
                    }
                  >
                    <input
                      type='file'
                      accept='.mp4 .webm'
                      name=''
                      id={`video-${index}`}
                      onChange={(e) => handleVideoDescription(e, index, item.videoName)}
                    />
                  </label>
                </div>
                <div className={css.actions}>
                  <div
                    className={classNames(
                      css.hide,
                      themeMode === 'dark' && css._dark,
                      item.hide && css._hide
                    )}
                    onClick={() => hideVideoDescription(index)}
                  ></div>
                  <div
                    className={css.delete}
                    onClick={(e) => deleteVideoDescription(index, item.avatar)}
                  />
                </div>
              </div>
              <div className={css.body}>
                <label htmlFor='' className={css.inputField}>
                  <span>Заголовок</span>
                  <input
                    type='text'
                    name='title'
                    value={item.title || ''}
                    onChange={(e) => changeVideoDescription(e, index)}
                  />
                </label>
                <div>
                  <label htmlFor='' className={css.inputField}>
                    <span>Описание</span>
                    <input
                      type='text'
                      placeholder={``}
                      value={item?.list[0] || ''}
                      onChange={(e) => {
                        const newConfig = {...config};
                        newConfig[currentLang].videoDescription[index].list = [];
                        newConfig[currentLang].videoDescription[index].list[0] = e.target.value;
                        setConfig(newConfig);
                      }}
                    />
                  </label>
                  {item?.list?.map((el, i) => {
                    return (
                      <label htmlFor='' className={css.inputField}>
                        <input
                          type='text'
                          value={item?.list[i + 1] || ''}
                          onChange={(e) => {
                            const newConfig = {...config};
                            newConfig[currentLang].videoDescription[index].list[i + 1] =
                              e.target.value;
                            setConfig(newConfig);
                          }}
                        />
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <button className={css.addComment} onClick={addVideoDescription}>
        Добавить видео описание +
      </button>
      <BlockLine />
      <BlockTitles
        title={'Локализация приложения'}
        description={
          'В этом блоке вы можете заполнить перевод оставшихся элементов интерфейса на странице, а также их метрики.'
        }
      />
      <div className={css.blockLocalization}>
        <span className={css.subTitle2}>Header страницы</span>
        {blockHeader.map((field, index) => (
          <label key={index} htmlFor='' className={css.inputField}>
            <div className={css.doubleFields}>
              {field.placeholders.map((placeholder, i) => (
                <input
                  key={i}
                  type='text'
                  placeholder={placeholder}
                  value={config[currentLang]?.inscriptions?.[field.keys[i]] || ''}
                  onChange={(e) => handleInputChange(field.keys[i], e.target.value)}
                />
              ))}
            </div>
          </label>
        ))}
        <span className={css.subTitle2}>Блок депозит</span>
        {blockDeposit.map((field, index) => (
          <label key={index} htmlFor='' className={css.inputField}>
            <div className={css.doubleFields}>
              {field.placeholders.map((placeholder, i) => (
                <input
                  key={i}
                  type='text'
                  placeholder={placeholder}
                  value={config[currentLang]?.inscriptions?.[field.keys[i]] || ''}
                  onChange={(e) => handleInputChange(field.keys[i], e.target.value)}
                />
              ))}
            </div>
          </label>
        ))}
        <span className={css.subTitle2}>Блок 'Как установить'</span>
        {blockHowInstall.map((field, index) => (
          <label key={index} htmlFor='' className={css.inputField}>
            <div className={css.doubleFields}>
              {field.placeholders.map((placeholder, i) => (
                <input
                  key={i}
                  type='text'
                  placeholder={placeholder}
                  value={config[currentLang]?.inscriptions?.[field.keys[i]] || ''}
                  onChange={(e) => handleInputChange(field.keys[i], e.target.value)}
                />
              ))}
            </div>
          </label>
        ))}
        <span className={css.subTitle2}>Блок Коментарии</span>
        {blockComments.map((field, index) => (
          <label key={index} htmlFor='' className={css.inputField}>
            <div className={css.doubleFields}>
              {field.placeholders.map((placeholder, i) => (
                <input
                  key={i}
                  type='text'
                  placeholder={placeholder}
                  value={config[currentLang]?.inscriptions?.[field.keys[i]] || ''}
                  onChange={(e) => handleInputChange(field.keys[i], e.target.value)}
                />
              ))}
            </div>
          </label>
        ))}
        <span className={css.subTitle2}>Блок Видео</span>
        {blockVideo.map((field, index) => (
          <label key={index} htmlFor='' className={css.inputField}>
            <div className={css.doubleFields}>
              {field.placeholders.map((placeholder, i) => (
                <input
                  key={i}
                  type='text'
                  placeholder={placeholder}
                  value={config[currentLang]?.inscriptions?.[field.keys[i]] || ''}
                  onChange={(e) => handleInputChange(field.keys[i], e.target.value)}
                />
              ))}
            </div>
          </label>
        ))}
        <span className={css.subTitle2}>Блок Footer</span>
        {blockFooter.map((field, index) => (
          <label key={index} htmlFor='' className={css.inputField}>
            <div className={css.doubleFields}>
              {field.placeholders.map((placeholder, i) => (
                <input
                  key={i}
                  type='text'
                  placeholder={placeholder}
                  value={config[currentLang]?.inscriptions?.[field.keys[i]] || ''}
                  onChange={(e) => handleInputChange(field.keys[i], e.target.value)}
                />
              ))}
            </div>
          </label>
        ))}
        <span className={css.subTitle2}>Блок Instagram</span>
        {blockInstagram.map((field, index) => (
          <label key={index} htmlFor='' className={css.inputField}>
            <div className={css.doubleFields}>
              {field.placeholders.map((placeholder, i) => (
                <input
                  key={i}
                  type='text'
                  placeholder={placeholder}
                  value={config[currentLang]?.inscriptions?.[field.keys[i]] || ''}
                  onChange={(e) => handleInputChange(field.keys[i], e.target.value)}
                />
              ))}
            </div>
          </label>
        ))}
      </div>
    </>
  );
};

export default Form;
