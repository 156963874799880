/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {useThemeMode} from '../../../../../../../_metronic/partials';

const Search = ({
  title = '',
  setRows = () => {},
  rows = [],
  searchParameters = ['traffic'],
  actions = () => {},
  onChangeValue = (value) => {},
  style = {},
  setColumns,
  columns,
}) => {
  const theme = useThemeMode();
  const [themeMode, setThemeMode] = useState('');

  const [string, setString] = useState('');
  const [firstRendering, setFirstRendering] = useState(true);

  useEffect(() => {
    if (!!firstRendering) return setFirstRendering(false);
    if (!string) {
      setRows(rows);
      return;
    }

    const val = string.trim().toLowerCase();

    const sortedArray = rows
      .filter((item) => {
        const matchCount = searchParameters.reduce((count, parameter) => {
          const value = String(item[parameter]).toLowerCase();
          return count + (value.includes(val) ? 1 : 0);
        }, 0);
        return matchCount > 0;
      })
      .sort((a, b) => {
        const countA = searchParameters.reduce((count, parameter) => {
          const value = String(a[parameter]).toLowerCase();
          return count + (value.includes(val) ? 1 : 0);
        }, 0);

        const countB = searchParameters.reduce((count, parameter) => {
          const value = String(b[parameter]).toLowerCase();
          return count + (value.includes(val) ? 1 : 0);
        }, 0);

        return countB - countA;
      });

    // searchChildren(sortedArray, rows);
    setRows(
      sortedArray.map((item) => {
        return {...item, isOpen: false};
      })
    );

    if (sortedArray.length === 0) return;
    const keys = sortedArray.map((key) => Object.keys(key));
    const uniqueValues = [...new Set(keys.flat())];

    const filteredColumns = columns.filter((col) => {
      if (uniqueValues.includes(col.column.name)) {
        return true;
      } else {
        return false;
      }
    });
    setColumns(filteredColumns);
  }, [string]);

  // const searchChildren = (sortedArr, mainArray) => {
  //   let children = [...mainArray].filter((item) => !!item.parentId);
  //   const resultArr = [...sortedArr];

  //   const proccess = () => {
  //     sortedArr.forEach((item, index) => {
  //       const newChildren = children.filter((ch) => ch.parentId === `${item.id}_${item.name}`);
  //       if (newChildren) {
  //         const findIndex = resultArr.findIndex(par => `${par.id}_${par.name}` === `${item.id}_${item.name}`)
  //         resultArr.splice(findIndex, 0, newChildren);
  //         children = children.filter((ch) => ch.parentId !== `${item.id}_${item.name}`);
  //       }
  //     });

  //     if (children.length > 0) {
  //       proccess();
  //     }
  //   };

  //   if (children.length > 0) {
  //     proccess();
  //   } else {
  //     console.log(resultArr);
  //   }
  // };

  const handleChange = (e) => {
    const {value} = e.target;
    setString(value);
    onChangeValue(value);
    actions();
  };

  useEffect(() => {
    if (theme.mode === 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setThemeMode('dark');
      } else {
        setThemeMode('light');
      }
    } else {
      setThemeMode(theme.mode);
    }
  }, [theme]);

  return (
    <div className='d-flex align-items-center position-relative mb-5 mb-sm-5 mb-md-0'>
      <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-5'>
        <span className='path1'></span>
        <span className='path2'></span>
      </i>
      <input
        type='text'
        className='form-control form-control-solid w-100 mw-250px ps-13'
        placeholder={title}
        value={string || ''}
        onChange={handleChange}
        style={{
          backgroundColor: themeMode === 'dark' ? 'rgba(15, 16, 21, 1)' : 'rgba(245, 246, 247, 1)',
          ...style,
        }}
      />
    </div>
  );
};

export default Search;
